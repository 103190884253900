import React, { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';

import { getActivityStartDateFields } from './utils';
  
import { ChevronLeft, ChevronRight } from '@/assets';
import { ComponentHeader } from '@modules/ContactComponents';
import TextWithTooltip from '@components/TextWithTooltip';

import {
  ActivityDate,
  ActivityName,
  Button,
  CalendarActivityWrapper,
  Content,
  Counter,
  MarkedTextChunk,
  NextActivity,
  TextChunk,
} from './styles';

import { CalendarActivityProps } from './types';

// When the number of displayed activities comes to an end,
// you need to get a new list of activities from the server
const ACTIVITY_NUMBER_FROM_THE_END = 20;

export const CalendarActivity = observer(({
  activity,
  getMoreActivity,
  onViewAllClick,
}: CalendarActivityProps) => {

  const [ activityNumber, setActivityNumber ] = useState<number>(0);
  const numberOfActivity = activity.length;

  const formattedActivityDate = useMemo(() => {
    if(!isEmpty(activity)) {
      const {
        activityDate,
        numberOfDaysBeforeActivity,
        typeName
      } = getActivityStartDateFields(`${activity[activityNumber].startDate} ${activity[activityNumber].startTime}`);
      
      return `${activityDate} (+${numberOfDaysBeforeActivity} ${typeName})`;  
    }
  }, [activity, activityNumber]);

  const onArrowClick = (direction: number) => {
    if(activityNumber === numberOfActivity - ACTIVITY_NUMBER_FROM_THE_END && direction > 0) {
      getMoreActivity();
    }
    setActivityNumber((prev) => prev + direction);
  };
  
  return(
    <CalendarActivityWrapper>
      <ComponentHeader
        title={ 'Next Calendar Activity' }
        onViewAllClick={ onViewAllClick }
      >
        { activity && <Counter>{ activity.length }</Counter> }
      </ComponentHeader>
      <NextActivity>
        { !isEmpty(activity) ? 
          <>
            <Button onClick={ () => onArrowClick(-1) } isShow={ Boolean(activityNumber) }>
              <ChevronLeft/>
            </Button>
            <Content>
              <ActivityName>
                <TextWithTooltip text={ activity[activityNumber].name }/>
              </ActivityName>
              <ActivityDate>
                { formattedActivityDate }
              </ActivityDate>
            </Content>
            <Button onClick={ () => onArrowClick(1) } isShow={ Boolean(activityNumber < numberOfActivity - 1) }>
              <ChevronRight/>
            </Button>
          </> :
          <Content>
            <TextChunk>NO CALENDAR ACTIVITIES </TextChunk>
            <MarkedTextChunk>TO VIEW</MarkedTextChunk>
          </Content>
        }
      </NextActivity>
    </CalendarActivityWrapper>
  );
});
