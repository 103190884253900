import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';

import { Store } from '@store';
import CommonTableStore from '@services/store/commonTableStore';
import { getActivitiesList, updateActivityFlags } from '@services/api/calendar/calendar';
import {
  deleteActivityToTrash,
  getActivityCreationSettings,
  saveActivity,
  updateActivity
} from '@services/api/addAndEditActivity/addAndEditActivity';

import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { getMultipleSortParams, getFilterParams, getGlobalFlagged } from '@/shared/utils/filterUtils';
import { getAllStatusFilter, getInitStatusFilter, getRange } from './utils';
import { itemsNormalizer } from './normalizers';
import {
  activityCreationSettingsNormalizer
} from '@/modules/ActivityPopup/store/normalizer/activityCreationSettingsNormalizer';

import {
  ActivityTypeFilter,
  CalendarPeriod,
  ContactActivity,
  ContactActivityFiltersNames,
  FilterDataConverter,
  Filters,
  GridResponse
} from './types';
import { ActivityCreationSettings, ActivityPopupSettingsResponse } from '@/modules/ActivityPopup/store/types';
import { ContactItem } from '@/shared/types/contact';
import { IdType, ItemWithId } from '@/shared/types/commonTypes';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NOTIFICATION_TYPES } from '@constants/notifications';

export class ContactDetailsActivitiesStore {
  activityPopupSettings: ActivityCreationSettings = {} as ActivityCreationSettings;
  asyncRequestExecutor: AsyncRequestExecutor;
  contact: ContactItem | null = null;
  coreStore: Store;
  end: string = '';
  filterData: FilterDataConverter = {} as FilterDataConverter;
  filters: Filters = {} as Filters;
  isPageActive: boolean = false;
  notificationHelper: NotificationHelper;
  onFilterReaction: IReactionDisposer;
  onPeriodChangeReaction: IReactionDisposer;
  onTypeChangeReaction: IReactionDisposer;
  period: CalendarPeriod = CalendarPeriod.all;
  previousActivityToggleState: boolean = false;
  start: string = '';
  table: CommonTableStore<ContactActivity>;
  typeFilter: ActivityTypeFilter | null = null;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow,
      getActivities: flow,
      getSetting: flow,
      updateFlags: flow,
      saveOrUpdateActivity: flow.bound,
      saveOrUpdateActivityWithoutLoad: flow.bound,
      deleteActivity: flow.bound,
      getActivitiesAndUpdateActualPage: flow.bound,
    });
    this.coreStore = coreStore;
    this.table = new CommonTableStore<ContactActivity>({
      onGlobalFlaggedChangeReactionCallback: () => this.getActivities(this.contact?.id),
      onPageChangeReactionCallback: () => this.getActivities(this.contact?.id),
      onSortReactionCallback: () => this.getActivities(this.contact?.id),
    });

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.activity
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.onFilterReaction = this.createOnFilterReaction();
    this.onPeriodChangeReaction = this.createOnPeriodChangeReaction();
    this.onTypeChangeReaction = this.createOnTypeFilterChangeReaction();
  }

  *saveOrUpdateActivity(data: any){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield this.saveOrUpdateActivityWithoutLoad(data);

      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveOrUpdateActivityWithoutLoad(data: any) { // TODO type
    if(data?.id) {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => updateActivity(data),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success })
      });
    } else {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveActivity(data),
        onError: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.success })
      });
    }
  }

  *deleteActivity(data: any){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteActivityToTrash(data),
        onError: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  createOnFilterReaction() {
    return reaction(
      () => this.filters,
      () => {
        debounce(() => {
          this.table.setCurrentPageWithoutReaction(1);
          this.getActivities(this.contact?.id);
        }, 1500)();
      }
    );
  }

  createOnPeriodChangeReaction() {
    return reaction(
      () => this.period,
      () => {
        this.table.setCurrentPageWithoutReaction(1);
        this.getActivities(this.contact?.id);
      },
    );
  }

  createOnTypeFilterChangeReaction(){
    return reaction(
      () => this.typeFilter,
      () => {
        if(this.isPageActive) {
          this.table.setCurrentPageWithoutReaction(1);
          this.getActivities(this.contact?.id);  
        }
      }
    );
  }

  async *init(contactId: IdType){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    this.isPageActive = true;

    try {
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData: ContactItem = yield await contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }
      this.setPeriod(CalendarPeriod.all);

      yield this.getSetting();

      this.onFilterReaction();
      this.setFilters({
        [ContactActivityFiltersNames.StatusId]:
        getInitStatusFilter(this.activityPopupSettings.statuses)
      });
      this.onFilterReaction = this.createOnFilterReaction();

      yield this.getActivities(contactId);

    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getSetting() {
    if (isEmpty(this.activityPopupSettings)) {
      try {
        const activityPopupSettingsResp: ActivityPopupSettingsResponse = yield getActivityCreationSettings();
        this.activityPopupSettings = activityCreationSettingsNormalizer(activityPopupSettingsResp.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  *getActivities(contactId?: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    try {
      const start = async () => {
        this.table.clearItems(true);

        const currentContactId = contactId ? contactId : contactDetailsStore.currentContact!.id;
        contactDetailsStore.toggleLoadState(true);


        const response: GridResponse = await getActivitiesList({
          primaryContactId: currentContactId,
          start: this.start,
          end: this.end,
          page: this.table.currentPage,
          ...getFilterParams(this.filters),
          ...getFilterParams(this.typeFilter),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...getGlobalFlagged(this.table.globalFlagged),
        });

        const normalized = itemsNormalizer(response.data.data.data, currentContactId);

        this.table.setPaginationData(response.data.data);
        this.table.checkAndSetIfPageOutOfRange();
        this.table.items = normalized.items;
        this.filterData = normalized.filterData;
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *updateFlags(arrayOfIds: Array<ItemWithId>, state: boolean) {
    try {
      const params = getDataForBookmark(arrayOfIds, state);
      yield updateActivityFlags(params);

      arrayOfIds.forEach((item) => {
        this.table.updateItemById(item.id, { flagged: state });
      });
      if(this.table.globalFlagged) {
        this.getActivities(this.contact?.id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getActivitiesAndUpdateActualPage() {
    try {
      await this.getActivities();
      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setPeriod(period: CalendarPeriod) {
    this.period = period;
    const { start, end } = getRange(period);
    this.start = start;
    this.end = end;
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setPreviousActivityToggleState(state: boolean) {
    this.previousActivityToggleState = state;
  }

  setAllStatusFilter() {
    this.setFilters({
      [ContactActivityFiltersNames.StatusId]: getAllStatusFilter(this.activityPopupSettings.statuses)
    });
  }

  setInitStatusFilter() {
    this.setFilters({
      [ContactActivityFiltersNames.StatusId]: getInitStatusFilter(this.activityPopupSettings.statuses)
    });
  }

  setTypeFilter(newType: ActivityTypeFilter) {
    this.typeFilter = newType;
  }

  reset() {
    this.onFilterReaction();

    this.contact = null;
    this.filterData = {} as FilterDataConverter;
    this.filters = {};
    this.setInitStatusFilter();
    this.table.resetTable();
    this.previousActivityToggleState = false;
    this.isPageActive = false;
    this.typeFilter = null;

    this.onFilterReaction = this.createOnFilterReaction();
  }
}
