import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { getContactName } from '@/shared/utils/getContactName';

import { ActivityPopup } from '@modules/ActivityPopup';
import { AddActivity, AddTask } from '@/assets';
import { ContactDetails, LinkedHousehold, MainInformation } from '@modules/ContactComponents';
import { convertSecondStringToMinutes, getTimeDataBasedOnView } from '@modules/ActivityPopup/utils';
import { TodoPopup } from '@modules/TodoPopup';
import BookmarkIcon from '@components/BookmarkIcon';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import TextWithTooltip from '@components/TextWithTooltip';
import Tooltip from '@components/Tooltip';

import { ContactInformationWrapper, HeaderWrapper, GroupIcons, ContactDetailsHeader } from './styles';
import { LayoutWrapper, MainContactInformationLayoutWrapper } from '../styles';

import {
  BUSINESS_PROFILE_LINKS,
  CONTACT_DETAILS_TAB,
  HOUSEHOLD_PROFILE_LINKS,
  INDIVIDUAL_PROFILE_LINKS
} from '@constants/routes/contacts';
import { CONTACT_TYPES } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import { IdType } from '@/shared/types/commonTypes';

const BOOKMARK_BOX_PROPS = {
  height: '18px',
  marginRight: '10px'
};

export const ContactInformation = observer(() => {
  const contactDetailsOverview = useStore().ContactDetailsOverview;
  const {
    adviserData,
    contact,
    contactSpouse,
    saveActivity,
    saveTodo,
    updateFlags,
    updateRating,
  } = contactDetailsOverview;

  const modalStore = useStore().ModalStore;
  const { settings: { activityDefaultTime } } = useStore().SettingsStore;

  const onAddActivityClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CALENDAR_ACTIVITY,
      modalProps: {
        // @ts-ignore TODO after fix calendar type
        initData: {
          ...getTimeDataBasedOnView({
            startDateObj: new Date(),
            duration: convertSecondStringToMinutes(activityDefaultTime),
          }),
          primaryContact: contact as any,
        },
        onConfirm: saveActivity,
        onDelete: () => {},
      },
      component: ActivityPopup
    });
  }, [modalStore, contact]);

  const onAddActivityClickWrapper = useCallback(() => onAddActivityClick(), [onAddActivityClick]);

  const onAddTaskClick = useCallback((id?: IdType, isCloneTask?: boolean, previousCopyData?: any) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        isCloneTask,
        previousCopyData,
        initLinkedContact: {
          //@ts-ignore TODO: unify Linked Item data
          contactData: contact,
          id: contact!.id
        },
        saveCallback: saveTodo,
        deleteCallback: () => {},
      },
      component: TodoPopup
    });
  }, [ modalStore, contact ]);

  const onAddTaskClickWrapper = useCallback(() => onAddTaskClick(), [onAddTaskClick]);

  const goToLinkedContactOverview = useCallback((id: IdType, type: CONTACT_TYPES) => {
    routerStore.moveToContact({
      id,
      contactType: type,
      tab: CONTACT_DETAILS_TAB.overview,
    });
  }, []);

  const onBookmarkClick = useCallback((state: boolean) => {
    if(contact) {
      updateFlags({ id: contact.id }, state);
    }
  },[ updateFlags, contact ]);

  const onRankChange = useCallback((newRating: number) => {
    if(contact) {
      updateRating(contact.id, newRating);
    }
  }, [updateRating, contact]);

  const routerStore = useStore().RouterStore;

  const goToContactDetails = useCallback(() => {
    if(contact) {
      let subTab = '' as any;
      switch(contact.type) {
      case CONTACT_TYPES.BUSINESS:
        subTab = BUSINESS_PROFILE_LINKS.businessDetails;
        break;
      case CONTACT_TYPES.HOUSEHOLD:
        subTab = HOUSEHOLD_PROFILE_LINKS.householdDetails;
        break;
      default:
        subTab = INDIVIDUAL_PROFILE_LINKS.individualDetails;
        break;
      }
      routerStore.moveToContact({
        id: contact.id,
        contactType: contact.type,
        tab: CONTACT_DETAILS_TAB.profile,
        subTabProps: {
          contactType: contact.type,
          subTab: subTab
        }
      });  
    }
  }, [routerStore, contact]);

  const isBlocked = typeof contactSpouse?.editAllow === 'number' && !contactSpouse?.editAllow;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const onSpouseClick = useCallback(() => {
    if(contactSpouse) {
      if(isBlocked) {
        onOutOfSightClick();
      } else {
        routerStore.moveToContact({
          id: contactSpouse.id,
          contactType: CONTACT_TYPES.INDIVIDUAL,
          tab: CONTACT_DETAILS_TAB.overview,
        });
      }
    }
  }, [contactSpouse, routerStore]);

  const renderHeaderTitle = useMemo(() => {
    return(
      <HeaderWrapper>
        <BookmarkIcon
          isActive={ Boolean(contact?.flagged) }
          onStateChange={ onBookmarkClick }
          boxProps={ BOOKMARK_BOX_PROPS }
        />
        <TextWithTooltip text={ getContactName(contact) }/>
        <GroupIcons>
          <Tooltip title={ 'Add Task' }>
            <AddTask onClick={ onAddTaskClickWrapper } height={ 20 }/>
          </Tooltip>
          <Tooltip title={ 'Add Activity' }>
            <AddActivity onClick={ onAddActivityClickWrapper } height={ 20 }/>
          </Tooltip>
        </GroupIcons>
      </HeaderWrapper>
    );
  }, [contact, contact?.flagged]);

  const contactDetailsHeaderTitle = useMemo(() => {
    return (
      <ContactDetailsHeader>
        {contact?.type} Contact Information
      </ContactDetailsHeader>
    );
  }, [contact]);

  if(!contact) {
    return null;
  }

  return (
    <ContactInformationWrapper>
      <MainContactInformationLayoutWrapper $height={ '49%' }>
        <MainInformation
          adviserData={ adviserData }
          contact={ contact }
          contactSpouse={ contactSpouse }
          isBlocked={ isBlocked }
          isBorderBottom={ false }
          onRankChange={ onRankChange }
          onSpouseClick={ onSpouseClick }
          renderHeaderTitle={ renderHeaderTitle }
          size={ 'small' }
        />
      </MainContactInformationLayoutWrapper>
      <LayoutWrapper $height={ '51%' }>
        { contact.type !== CONTACT_TYPES.BUSINESS &&
          <LinkedHousehold
            currentContact={ contact }
            onClick={ goToLinkedContactOverview }
            onOutOfSightClick={ onOutOfSightClick }
          />
        }
        <ContactDetails
          contact={ contact }
          contactsDetails={ contact.contacts }
          headerTitle={ contactDetailsHeaderTitle }
          onViewAllClick={ goToContactDetails }         
        />
      </LayoutWrapper>
    </ContactInformationWrapper>
  );
});
