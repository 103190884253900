import * as yup from 'yup';

import { CONTACT_TYPE } from '@modules/AddContactModal/data';
import { validationMessages as errors } from '@constants/validationMessages';
import {
  dateOutputValidation,
  simpleEmailPattern,
} from '@constants/validationPatterns';
import { getIsOnlyCountryCode, phoneValidation } from '@modules/HookFormComponents/HFPhoneField/validation';

const commonFirstStep = {
  firstName: yup.string().required(errors.invalidFirstName),
  lastName: yup.string().required(errors.invalidLastName),
  birthdate: yup
    .string()
    .nullable()
    .matches(dateOutputValidation, errors.invalidBirthday)
};

const commonProfileDetails = {
  classification: yup.string().required(errors.invalidCoreClassification),
  officePrimaryAdvisor: yup.string().nullable().required(errors.invalidPrimaryAdvisor)
};

const commonMobilePhone = {
  mobilePhone: phoneValidation
};

const individualTypeSchema = yup.object().shape({
  1: yup.object().shape({
    ...commonFirstStep,
    ...commonProfileDetails,
  }),
  2: yup.object().shape({
    ...commonMobilePhone,
  }),
});

const householdTypeSchema = yup.object().shape({
  1: yup.object().shape({
    main: yup.object().shape(commonFirstStep),
    spouse: yup.object().shape(commonFirstStep),
    ...commonProfileDetails
  }),
  2: yup.object().shape({
    ...commonMobilePhone,
  })
});

const businessTypeSchema = yup.object().shape({
  1: yup.object().shape({
    occupationCompany: yup.string().required(errors.invalidBusinessName),
    birthdate: yup
      .string()
      .nullable()
      .matches(dateOutputValidation, errors.invalidBirthday),
    ...commonProfileDetails
  }),
  2: yup.object().shape({
    ...commonMobilePhone,
  }),
  3: yup.object().shape({
    firstName: yup.string()
      .test('match', errors.invalidFirstName, function (value) {
        const { lastName, phoneNumber, email } = this.parent;
        // TODO by default the phone number must be set NULL if only the country code is entered
        const isOnlyCountryCode = getIsOnlyCountryCode(phoneNumber || '');

        const isAtLeastOneFieldFilled = Boolean(lastName.length || !isOnlyCountryCode || email.length);
        if(!value && isAtLeastOneFieldFilled) {
          return false;
        }
        return true;
      }),
    lastName: yup.string()
      .test('match', errors.invalidLastName, function (value) {
        const { firstName, phoneNumber, email } = this.parent;
        // TODO by default the phone number must be set NULL if only the country code is entered
        const isOnlyCountryCode = getIsOnlyCountryCode(phoneNumber || '');

        const isAtLeastOneFieldFilled = Boolean(firstName.length || !isOnlyCountryCode || email.length);
        if(!value && isAtLeastOneFieldFilled) {
          return false;
        }
        return true;
      }),
    phoneNumber: phoneValidation,
    email: yup.string()
      .matches(simpleEmailPattern, {
        excludeEmptyString: true,
        message: errors.invalidEmail
      })
  })
});

export const getValidationSchema = (type: CONTACT_TYPE) => {
  switch (type) {
  case CONTACT_TYPE.Individual:
    return individualTypeSchema;
  case CONTACT_TYPE.Household:
    return householdTypeSchema;
  case CONTACT_TYPE.Business:
    return businessTypeSchema;
  default:
    return;
  }
};
