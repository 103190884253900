import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '@/shared/services/store/store';

import PageHeaderWrapper from '@components/BasicLayoutComponents/PageHeaderWrapper';
import CustomTabs from '@components/CustomTabs';

import { getUserSettingsTabsByRoles } from '@pages/NewUserSettings/utils';

import { USER_SETTINGS_TABS_CONFIG } from '@pages/NewUserSettings/data';

import { TabWrapper } from './styles';


export const PageHeader = observer(() => {
  const { params: { tab } } = useRouteMatch<{ tab: string }>();


  const { roles } = useStore().SettingsStore;

  const ABLE_USER_SETTINGS_TABS = getUserSettingsTabsByRoles(roles, USER_SETTINGS_TABS_CONFIG);

  return (
    <PageHeaderWrapper>
      <TabWrapper>
        <CustomTabs value={ tab } >
          {
            ABLE_USER_SETTINGS_TABS.map((tab) => (
              <CustomTabs.Tab
                component={ Link }
                icon={ <tab.icon/> }
                key={ tab.value }
                label={ tab.label }
                to={ tab.link }
                value={ tab.value }
              />
            ))
          }
        </CustomTabs>
      </TabWrapper>
    </PageHeaderWrapper>
  );
});
