import styled from 'styled-components';

import { Calendar } from 'react-big-calendar';

import { COLORS } from '@constants/colors';

import { CALENDAR_VIEWS } from '@constants/common';
import { TIME_GUTTER_COLUMN_ELEM_WIDTH } from './data';

export const StyledCalendar = styled(Calendar)`
  color: ${COLORS.primaryText};
  height: 1112px;
  font-weight: 400;
  font-size: 14px;

  /* MONTH SECTION */
  /* reset Month Day Headers Styles */

  & .rbc-month-header > .rbc-header {
    border: none;
    padding: 0;
  }

  & .rbc-month-view {
    border: none;

    /* Change more button position */

    & .rbc-show-more {
      margin-left: auto;
      margin-top: 5px;
    }

    /* Set explicit height for date row */

    & .rbc-row-content {

      & > .rbc-row:first-child {
        height: 40px;
        min-height: 40px;
      }

      & .rbc-row-segment {
        padding: 0;
      }
    }

    /* Reset Borders of Month Day Cell */

    & .rbc-day-bg + .rbc-day-bg {
      border-color: ${COLORS.borders};
    }

    /* Reset Borders of Month Day Cell */

    & .rbc-month-row + .rbc-month-row {
      border-color: ${COLORS.borders};
    }

    /* Reset Month Days Cells out of range background*/

    & .rbc-off-range-bg {
      background: transparent;
    }

    /* Reset Reset Today Cell*/

    & .rbc-today {
      position: relative;
      background-color: transparent;

      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: -1px;
        bottom: 0;
        right: -1px;
        border: 1px solid ${COLORS.orange};
      }
    }

    & .rbc-today:last-child {

      &:after {
        right: 1px;
      }
    }
    
  }

  /* DAY and WEEK SECTIONS */

  & .rbc-time-view {
    /* Reset Current Time Indicator */

    overflow-y: scroll;

    & .rbc-current-time-indicator {
      background-color: ${COLORS.red};
      height: 3px;

      &::after {
        width: 8px;
        height: 8px;
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        border-radius: 50%;
        background-color: ${COLORS.red};
        transform: translate(-4px, -3px);
      }
    }

    /* Prevent scroll missaligned with columns */
    & .rbc-time-header {
      position: sticky;
      top: 0px;
      border-right: 1px solid ${COLORS.borders};
      border-bottom: 1px solid ${COLORS.borders};
      background: ${COLORS.white};
      z-index: 100;
    }
    
    /* Reset Time Column and Time Column Elements */

    & .rbc-time-header-gutter {
      display: flex;
      padding-left: 20px;
      align-items: center;
      min-width: ${TIME_GUTTER_COLUMN_ELEM_WIDTH} !important;
      max-width: ${TIME_GUTTER_COLUMN_ELEM_WIDTH} !important;
    }

    & .rbc-time-gutter {
      width: ${TIME_GUTTER_COLUMN_ELEM_WIDTH};

      & .rbc-time-slot {
        display: flex;
        align-items: center;
        padding-left: 15px;
      }
    }

    & .rbc-timeslot-group {
      min-height: 34px;
      max-height: 34px;
    }
    
    /* Reset Week Date Header Row*/

    & .rbc-time-header-cell {
      min-height: auto;
    }

    /* Reset Week Date Header Cell*/

    & .rbc-time-header-cell {
      & .rbc-header {
        padding: 0;
        border: none;
      }

      & .rbc-button-link {
        width: 100%;

        & > div {
          font-weight: 700;
        }
      }
    }

    /* Reset Borders of Week and Day Cells */

    & .rbc-time-content {
      overflow-y: visible;
      border-top: 1px solid ${COLORS.borders};

      & > * + * > * {
        border-left: 1px solid ${COLORS.borders};
      }
    }

    /* Reset Current Day at Week and Day View*/

    & .rbc-today {
      background-color: ${props => (
    props.$currentView === CALENDAR_VIEWS.week
      ? COLORS.select
      : 'transparent'
  )}
    }
  }

  /* Reset Event Wrapper Styles */

  & .rbc-event {
    height: 22px ;
    padding: 1px 3px;
    box-sizing: border-box;
    overflow: hidden;
    border: none;
    background-color: transparent;

    & .rbc-event-label {
      display: none;
    }

    & .rbc-event-content {
      display: flex;
    }
  }

  /* Pass border radius inherit for all views to EventInnerWrapper */

  & .rbc-event-content,
  & .rbc-addons-dnd-resizable {
    border-radius: inherit;
  }


  // edit resize icons month view
  & .rbc-addons-dnd-resize-ew-anchor {
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);

    &:first-child {
      left: 5px;
    }

    &:last-child {
      right: 5px;
    }
  }

  .rbc-addons-dnd-resize-ew-icon {
    border: none !important;
    width: 6px;
    background-color: transparent;
    background: linear-gradient(90deg, black 0%, black 30%, transparent 30%, transparent 70%, black 70%);
  }

  // edit resize icons day and week view
  & .rbc-time-view {
    & .rbc-addons-dnd-resize-ns-anchor {
      width: auto;
      z-index: 20;
      left: 50%;
      transform: translateX(-50%);

      &:first-child {
        top: 3px;
      }

      &:last-child {
        bottom: 3px;
      }
    }

    & .rbc-addons-dnd-resize-ns-icon {
      border: none !important;
      width: 10px;
      height: 6px;
      background-color: transparent;
      background: linear-gradient(180deg, black 0%, black 30%, transparent 30%, transparent 70%, black 70%);
    }
    
    /* add border for last column before scroll*/
    & .rbc-time-column:last-child {
      border-right: 1px solid ${COLORS.borders};
    }
    
    & .rbc-overflowing{
      margin-right: unset;
    }
  }

  //reset gutter of column
  .rbc-events-container {
    margin-right: 2px;
  }
`;
