import { action, flow, makeObservable } from 'mobx';
import { isEmpty } from 'lodash';

import { Store } from '@store';

import CommonTableStore from '@/shared/services/store/commonTableStore';

import { ENTITY_NAMES, RELATION_TYPES } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import {
  deleteRelatedContact,
  getRelatedContacts,
  saveRelatedContact
} from '@services/api/relatedContacts/relatedContacts';

import { IdType } from '@/shared/types/commonTypes';
import { ContactItem } from '@/shared/types/contact';
import { GridResponse, RelatedItem } from './types';
import {
  RelatedContactFields
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/types';

import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { getParams } from '@services/store/relatedContactsStore/utils';

import {
  RelatedContactSpouseChangeConfirmation
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/components';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import {
  RelatedContactSpouseChangeConfirmationModalProps
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/components/RelatedContactSpouseChangeConfirmation/types';

class RelatedContactsStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  isSpouseExists: boolean = false; 
  notificationHelper: NotificationHelper;
  table: CommonTableStore<RelatedItem>;

  constructor(coreStore: Store) {
    makeObservable(this, {
      getRelatedContacts: flow.bound,
      getRelatedContactsWithLoad: flow.bound,
      init: flow.bound,
      openChangeContactSpouseConfirmationPopup: action.bound,
      removeRelatedContactWithoutUpdatePage: flow.bound,
      save: flow.bound,
      unlinkRelatedContact: flow.bound,
      updateFromGrid: flow.bound,
    });

    this.coreStore = coreStore;
    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.relatedContact
    );

    this.table = new CommonTableStore<RelatedItem>({
      onPageChangeReactionCallback: this.getRelatedContactsWithLoad,
      onSortReactionCallback: this.getRelatedContactsWithLoad
    });
  }

  *getRelatedContacts(contactId: IdType) {
    this.table.clearItems(true);
    const response: GridResponse = yield getRelatedContacts({
      contactId,
      page: this.table.currentPage,
      ...getParams(this),
    });

    this.table.setPaginationData(response.data.data);
    this.table.items = response.data.data.data;
    this.setIsSpouseExists();
  }

  *getRelatedContactsWithLoad() {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => this.getRelatedContacts(contactDetailsStore.currentContact!.id),
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error })
      });
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  async *init(contactId: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData:ContactItem = yield await contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }

      yield this.getRelatedContactsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  async *removeRelatedContactWithoutUpdatePage(id: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield await deleteRelatedContact({ id });
      this.setIsSpouseExists();
    } catch (error) {
      console.error(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *unlinkRelatedContact(id: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteRelatedContact({ id }),
        onSuccess: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.success,
          message: 'The Related Contact has been unlink successfully',
          customAction: 'deleteRelatedContact'
        }),
        onError: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.error,
          message: 'The unlinking of the Related Contact is failed',
          customAction: 'errorDeleteRelatedContact'
        })
      });

      yield this.getRelatedContacts(contactDetailsStore.currentContact!.id);

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.error(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *save(data: RelatedContactFields, onCancel?: () => void) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    const currentContactId = contactDetailsStore.currentContact!.id;
    contactDetailsStore.toggleLoadState(true);

    try {
      const isChangeHouseholdMemberSpouse = data.spouseContacts?.isHousehold &&
        data.spouseContacts?.id === currentContactId;

      const isUpdate = Boolean(data?.id);
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => (
          saveRelatedContact({
            contactId: currentContactId,
            checkSpouse: data.relationType === RELATION_TYPES.SPOUSE || isChangeHouseholdMemberSpouse ? 0 : 1,
            relatedContact: [
              data,
            ]
          })
        ),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isAutoUniqueKey: true,
          isUpdate,
          isError: false
        }),
        onError: () => () => this.notificationHelper.createUpdateNotification({
          isAutoUniqueKey: true,
          isUpdate,
          isError: true
        }),
        continueOnError: false,
      });

      yield this.getRelatedContactsWithLoad();
    } catch (error) {
      console.error(error);
      if(onCancel) {
        onCancel();
      }
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFromGrid(data: RelatedItem, onCancel: () => void){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    const currentContactId = contactDetailsStore.currentContact!.id;
    const isSorted = Object.keys(this.table.multipleSorting).length > 0;

    try {
      if(isSorted) {
        contactDetailsStore.toggleLoadState(true);
      }

      const start = async() => {
        await saveRelatedContact({
          contactId: currentContactId,
          relatedContact: [ data ]
        });
        if(isSorted){
          await this.getRelatedContactsWithLoad();
        } else {
          this.table.updateItemById(data.id as number, data);
        }
      };

      if(!isEmpty(data.clientContact.spouseContacts) && data.relationType === RELATION_TYPES.SPOUSE) {
        this.openChangeContactSpouseConfirmationPopup({
          data: {
            ...data,
            firstName: data.clientContact.firstName,
            lastName: data.clientContact.lastName,
            spouseContacts: data.clientContact.spouseContacts
          },
          currentContact: contactDetailsStore.currentContact,
          onSave: this.save,
          onCancel
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: start,
          onSuccess:() => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.success,
            isAutoUniqueKey: true
          }),
          continueOnError: false
        });
      }
    } catch (error) {
      console.log(error);
      onCancel();
    } finally {
      if(isSorted) {
        contactDetailsStore.toggleLoadState(false);
      }
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      this.setIsSpouseExists();
    }
  }

  openChangeContactSpouseConfirmationPopup({
    data, currentContact, onSave, onCancel
  }: RelatedContactSpouseChangeConfirmationModalProps) {
    this.coreStore.ModalStore.openModal({
      modalType: MODAL_TYPE.CONFIRM_RELATED_CONTACT_SPOUSE_CHANGE,
      modalProps: {
        onCancel,
        onSave,
        data,
        currentContact
      },
      component: RelatedContactSpouseChangeConfirmation
    });
  }

  setIsSpouseExists() {
    const spouseIndex = this.table.items.findIndex(item => item.relationType === RELATION_TYPES.SPOUSE);
    this.isSpouseExists = typeof spouseIndex === 'number' && spouseIndex >= 0;
  }

  reset() {
    this.table.resetTable();
  }
}

export default RelatedContactsStore;
