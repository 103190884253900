import React from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFUserSingleSelect from '@/modules/HookFormComponents/HFUserSingleSelect';

import { ServicingDetailsProps } from './types';

import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';
import { validationMessages } from '@constants/validationMessages';

import {
  LeftColumnWrapper,
  RightColumnWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';


export const ServicingDetails = observer(({
  clientCreationDateName,
  officeLocationName,
  primaryAdviserName,
  primaryCsrName,
  secondaryAdviserName,
  secondaryCsrName,
}: ServicingDetailsProps) => {
  const { control } = useFormContext();
  const { initAdvisors } = useStore().ContactDetailsProfileStore;

  return (
    <FormBlock
      isBorderBottom={ false }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Servicing Details' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Primary Advisor' }
          >
            <HFUserSingleSelect
              ControllerProps={ {
                rules: { required: validationMessages.required },
              } }
              control={ control }
              initLinkedContact={ initAdvisors?.primary }
              name={ primaryAdviserName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Lead Planner' }
          >
            <HFUserSingleSelect
              control={ control }
              initLinkedContact={ initAdvisors?.secondary }
              name={ secondaryAdviserName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Primary CSR' }
          >
            <HFUserSingleSelect
              control={ control }
              initLinkedContact={ initAdvisors?.primaryCsr }
              name={ primaryCsrName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Secondary CSR' }
          >
            <HFUserSingleSelect
              control={ control }
              initLinkedContact={ initAdvisors?.secondaryCsr }
              name={ secondaryCsrName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          label={ 'Office Custom Location' }
        >
          <HFTextInput
            inputProps={ {
              placeholder: 'Enter Office Custom Location',
            } }
            control={ control }
            name={ officeLocationName }
            showErrorText={ true }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Client Creation Date' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }
              control={ control }
              isDateWithTime={ true }
              name={ clientCreationDateName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});
