import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ContentWrapper, MainWrapper } from '@pages/NewUserSettings/components/Layout';
import { NotificationBar } from '@components/NotificationBar';
import RouteGuard from '@modules/RouteGuard';
import {
  ComplianceInformationEmailSignature,
  GeneralInformation,
  Headshots
} from '@/pages/NewUserSettings/components/ProfileInformation';

import { useStore } from '@/shared/services/store/store';
import { useDidUpdate } from '@/shared/customHooks';
import { getValidationScheme } from '@pages/NewUserSettings/components/Tabs/validationScheme';
import { getMyProfileDefaultValues } from './utils';

import { EmailStatuses } from '@constants/common';
import { openDetectLinkTransitionPopup } from '@/shared/utils/modalHelpers';
import { ProfileFormInputNames, UserProfileFormInputNames } from '@/pages/NewUserSettings/data';

import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';


export const MyProfile = observer(() => {
  const [ isShowSaveBar, setShowSaveBar] = useState(false);

  const authStore = useStore().AuthStore;
  const settingsStore = useStore().SettingsStore;
  const userSettingsStore = useStore().UserSettingsStore;
  const routerStore = useStore().RouterStore;
  const { profile } = userSettingsStore;

  const memoizedDefaultValues = useMemo(() => {
    if(!profile) {
      return {};
    }
    return getMyProfileDefaultValues(profile);
  }, [profile]);

  const methods: UseFormReturn<UserProfileType> = useForm<UserProfileType>({
    mode: 'onSubmit',
    defaultValues: memoizedDefaultValues,
    resolver: yupResolver(getValidationScheme(UserProfileFormInputNames)),
  });

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
    setError,
    setValue
  } = methods;

  const allFields = useWatch({ control });

  const onSaveClick = useCallback(async (data: UserProfileType) => {
    if(dirtyFields.email) {
      setValue(ProfileFormInputNames.emailVerified, EmailStatuses.Unverified);
    }
    setShowSaveBar(false);
    const response = await userSettingsStore.saveUserProfile(data, setError);
    if (response) {
      authStore.setUserData({
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        headshot: data.headshot,
      });
      settingsStore.setProfile(data);
    }
  }, [userSettingsStore, authStore, settingsStore, dirtyFields, setValue]);

  const onCancelClick = () => {
    setShowSaveBar(false);
    reset(memoizedDefaultValues);
  };

  useDidUpdate(() => {
    if(isDirty) {
      setShowSaveBar(true);
    }
  }, [ allFields ]);

  useEffect(() => {
    userSettingsStore.getProfile();
    return () => userSettingsStore.reset();
  }, [ userSettingsStore ]);

  useEffect(() => {
    reset(memoizedDefaultValues);
  }, [profile, memoizedDefaultValues, reset]);

  return (
    <FormProvider { ...methods }>
      <ContentWrapper>
        <RouteGuard
          triggerModalRender={ openDetectLinkTransitionPopup }
          when={ isDirty }
        />
        <MainWrapper>
          <Headshots
            inputNames={ ProfileFormInputNames }
            title={ 'Personal Information' }
          />

          <GeneralInformation
            inputNames={ ProfileFormInputNames }
            profileData={ profile ?? {} }
          />

          <ComplianceInformationEmailSignature
            inputNames={ ProfileFormInputNames }
          />

          <NotificationBar
            isOpen={ isShowSaveBar }
            onCancelButtonClick={ onCancelClick }
            onConfirmButtonClick={ handleSubmit(onSaveClick) }
          />
        </MainWrapper>
      </ContentWrapper>
    </FormProvider>
  );
});
