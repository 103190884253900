import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';

import {
  CityWrapper,
  EmailWrapper,
  LeftColumnWrapper,
  RightColumnWrapper,
  TitleAndStateWrapper,
  VerifiedWrapper,
  ZipWrapper
} from '@pages/NewUserSettings/components/Layout';
import VerifiedEmailButton from '../VerifiedEmailButton';

import { useStore } from '@services/store/store';
import { EmailStatuses, UserStatuses } from '@constants/common';

import { LABELS_WIDTH } from '@pages/NewUserSettings/data';

import { PersonalInformationFormProps } from '@pages/NewUserSettings/types';


export const GeneralInformation = observer(({
  title,
  profileData,
  inputNames
}: PersonalInformationFormProps) => {

  const userSettingsStore = useStore().UserSettingsStore;
  const { states } = useStore().SettingsStore;

  const { control } = useFormContext();

  const emailInputValue = useWatch({
    control,
    name: inputNames.email
  });

  const onVerifyButtonClick = useCallback(() => {
    userSettingsStore.getVerifiedEmail(profileData.id);
  }, [ userSettingsStore, profileData?.id ]);

  const memoVerifiedBlock = useMemo(() => {
    if (profileData?.email === emailInputValue) {
      return (
        profileData.emailVerified && 
        <VerifiedEmailButton
          disabled={ profileData.status === UserStatuses.Inactive }
          emailStatus={ profileData.emailVerified }
          onClick={ onVerifyButtonClick }
        />
      );
    } else {
      return (
        <VerifiedEmailButton
          disabled={ true }
          emailStatus={ EmailStatuses.Unverified }
        />
      );
    }
  }, [ emailInputValue, profileData?.emailVerified, profileData?.email, profileData?.status, onVerifyButtonClick ]);

  return (
    <FormBlock marginBottom={ 20 }>
      { title && <FormBlock.Header title={ title }/> }
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            isRequired={ true }
            label={ 'First Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter First Name'
              } }
              control={ control }
              name={ inputNames.firstName }    
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            isRequired={ true }
            label={ 'Last Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Last Name'
              } }
              control={ control }
              name={ inputNames.lastName }       
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <TitleAndStateWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            label={ 'Title' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 100,
                placeholder: 'Enter Title'
              } }
              control={ control }
              name={ inputNames.title }
            />
          </FormBlock.FormField>
        </TitleAndStateWrapper>

        <EmailWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            isRequired={ true }
            label={ 'Email' }
          >
            <HFTextInput
              inputProps={ {
                placeholder: 'Enter Email Address'
              } }
              control={ control }
              name={ inputNames.email }       
            />
          </FormBlock.FormField>
        </EmailWrapper>

        <VerifiedWrapper>
          <FormBlock.FormField
            label={ '' }
          >
            { memoVerifiedBlock }
          </FormBlock.FormField>
        </VerifiedWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            label={ 'Website' }
          >
            <HFTextInput
              inputProps={ {
                placeholder: 'Enter Website Address'
              } }
              control={ control }
              name={ inputNames.website }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            label={ 'Phone Number' }
          >
            <HFPhoneField
              control={ control }
              countryListWidth={ 290 }
              name={ inputNames.phoneNumber }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            label={ 'Fax Number ' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 20,
                placeholder: 'Enter Fax Number'
              } }
              control={ control }
              name={ inputNames.faxNumber }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            label={ 'Business Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 100,
                placeholder: 'Enter Business Name'
              } }
              control={ control }
              name={ inputNames.businessName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
          label={ 'Address Line 1 ' }
        >
          <HFTextInput
            inputProps={ {
              maxLength: 50,
              placeholder: 'Enter Address'
            } }
            control={ control }
            name={ inputNames.addressLine }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
          label={ 'Address Line 2 ' }
        >
          <HFTextInput
            inputProps={ {
              maxLength: 50,
              placeholder: 'Enter Address'
            } }
            control={ control }
            name={ inputNames.addressLine2 }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <TitleAndStateWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            label={ 'State' }
          >
            <HFSelect
              MenuListProps={ {
                style: {
                  maxHeight: '265px'
                }
              } }
              control={ control }
              name={ inputNames.state }
              options={ states || [] }
            />
          </FormBlock.FormField>
        </TitleAndStateWrapper>
        
        <CityWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            label={ 'City Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter City'
              } }
              control={ control }
              name={ inputNames.city }
            />
          </FormBlock.FormField>
        </CityWrapper>

        <ZipWrapper>
          <FormBlock.FormField
            $labelWidth={ 38 }
            label={ 'ZIP' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 9,
                placeholder: 'Enter Zip'
              } }
              control={ control }
              name={ inputNames.zip }
            />
          </FormBlock.FormField>
        </ZipWrapper>
      </FormBlock.RowWrapper>

    </FormBlock>
  );
});
