import styled, { css } from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

import { COLORS } from '@constants/colors';
import { TABLE_CELL_COMMON_STYLE } from '@constants/style';

import { CellProps } from './types';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const StyledTableCell = styled(TableCell)<CellProps>`
  ${TABLE_CELL_COMMON_STYLE};
  
  ${({ $isClickable }) => $isClickable && css`
    &:hover {
      & ${Wrapper} > div > div > div:last-child{
        text-decoration: underline;
      }
      color: ${COLORS.orange};

      & svg path {
        fill: ${ COLORS.orange };
      }
    }
  `}
`;

