import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import CoreClassificationSelectOption from '@components/CoreClassificationSelectOption';
import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFRank from '@modules/HookFormComponents/HFRank';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFUserSingleSelect from '@modules/HookFormComponents/HFUserSingleSelect';

import { RenderItemArg } from '@components/CustomSelect/types';
import { ContactInformationProps } from './types';

import { LeftWrapper, RightWrapper } from './styles';

import { LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH } from './data';
import {
  FIELD_NAMES
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/SplitToIndividuals/data';

import {
  getFieldName
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/SplitToIndividuals/utils';

const PRIMARY_ADVISOR_LABEL_WIDTH = {
  labelWidth: LEFT_LABEL_WIDTH - 20
};

export const ContactInformation = ({
  classificationOptions,
  currentStep,
  genderOptions,
  primaryAdviser,
  sourceCategoryOptions,
  sourceOptions,
  statusOptions,
}: ContactInformationProps) => {
  const { control } = useFormContext();

  const renderCoreClassification = useCallback((arg: RenderItemArg) => {
    if(!Array.isArray(arg)){
      return (
        <CoreClassificationSelectOption label={ arg.label } value={ arg.value } />
      );
    }
  }, []);


  return (
    <>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <HFHiddenInput
          control={ control }
          defaultValue={ null }
          name={ getFieldName(currentStep, FIELD_NAMES.id) }
        />
        <FormBlock.Header title={ 'Personal Information' }/>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_LABEL_WIDTH }
              isRequired={ true }
              label={ 'First Name' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter First Name',
                } }
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.firstName) }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_LABEL_WIDTH }
              isRequired={ true }
              label={ 'Last Name' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter Last Name',
                } }
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.lastName) }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_LABEL_WIDTH }
              label={ 'Nickname' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter Nick Name',
                } }
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.nickname) }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_LABEL_WIDTH }
              label={ 'Birth Date' }
            >
              <HFDatePicker
                DatePickerProps={ {
                  disableFuture: true
                } }
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.birthdate) }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_LABEL_WIDTH }
              label={ 'Gender' }
            >
              <HFSelect
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.gender) }
                options={ genderOptions }
                resettable={ true }
              />
            </FormBlock.FormField>
          </LeftWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
      <FormBlock
        isBorderBottom={ false }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Profile Details' }/>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_LABEL_WIDTH }
              isRequired={ true }
              label={ 'Core Classification' }
            >
              <HFSelect
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.classification) }
                options={ classificationOptions }
                renderItem={ renderCoreClassification }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_LABEL_WIDTH }
              label={ 'Rank' }
            >
              <HFRank
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.rating) }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_LABEL_WIDTH }
              isRequired={ true }
              label={ 'Primary Advisor' }
            >
              <HFUserSingleSelect
                UserItemProps={ PRIMARY_ADVISOR_LABEL_WIDTH }
                control={ control }
                initLinkedContact={ primaryAdviser }
                name={ getFieldName(currentStep, FIELD_NAMES.officePrimaryAdvisor) }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_LABEL_WIDTH }
              isRequired={ true }
              label={ 'Status' }
            >
              <HFSelect
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.status) }
                options={ statusOptions }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_LABEL_WIDTH }
              label={ 'Source' }
            >
              <HFSelect
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.sourceId) }
                options={ sourceOptions }
                resettable={ true }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_LABEL_WIDTH }
              label={ 'Source Category' }
            >
              <HFSelect
                control={ control }
                name={ getFieldName(currentStep, FIELD_NAMES.sourceCategory) }
                options={ sourceCategoryOptions }
                resettable={ true }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
};
