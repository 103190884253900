import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const CurrentTags = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const CurrentTagsPlusItem = styled.div`
  ${({ $editIndex }) => $editIndex && (($editIndex + 1) % 2 === 0) && css`
    margin-top: 10px;
  `}
`;

export const CurrentTagsTagItem = styled.div`
  margin: 5px 10px 5px 0;
`;


export const ButtonColumn = styled.div`
  width: 100px;
  flex-shrink: 0;

  display: flex;
  align-self: flex-end;
  margin-left: auto;
  
  & > * {
    ${({ $tagsLength }) => $tagsLength > 2 && css`
      position: relative;
      top: 6px
    `}
  }
`;

export const PredefinedTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
  
    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      height: 1px;
      background-color: ${COLORS.dividingLines};
    }
  
  & > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
