import React, { useCallback, useEffect } from 'react';

import { useStore } from '@store';

import { PlusWithCircle } from '@/assets';
import BlockButton from '@components/BlockButton';
import ContentCenter from '@components/ContentCenter';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import NoDataItemsView from '@modules/NoDataItemsView';
import Preloader from '@components/Preloader';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import Table from '@components/Table';

import { EmployeeDeleteConfirmation, EmployeeRow, EmployeeAddEditPopup } from './components';

import {
  ADD_BUTTON_STYLE,
  COLUMNS,
  LINK_NEW_CUSTOM_FIELD,
} from './data';

import {
  TabContentWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import { BusinessEmployeesProps } from './types';
import { MODAL_TYPE } from '@constants/modalTypes';
import { CONTACT_DETAILS_TAB, INDIVIDUAL_PROFILE_LINKS } from '@constants/routes/contacts';
import { EmployeeItem } from '@/shared/types/employee';

export const BusinessEmployees = ({
  contactId
}: BusinessEmployeesProps) => {
  const { isLoad } = useStore().ContactDetailsStore;
  const employeeStore = useStore().EmployeeStore;
  const { table } = employeeStore;
  const modalStore = useStore().ModalStore;
  const routerStore = useStore().RouterStore;

  const openAddEditPopup = useCallback((item?: EmployeeItem) => {
    modalStore.openModal({
      component: EmployeeAddEditPopup,
      modalType: MODAL_TYPE.ADD_EMPLOYEE,
      modalProps: {
        dataToEdit: item
      }
    });
  },[modalStore]);

  const onAdd = useCallback(() => openAddEditPopup() ,[openAddEditPopup]);

  const onDelete = useCallback((item: EmployeeItem) => {
    modalStore.openModal({
      component: EmployeeDeleteConfirmation,
      modalType: MODAL_TYPE.DELETE_EMPLOYEE_CONFIRMATION,
      modalProps: {
        id: item.id
      }
    });
  },[modalStore]);

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const onEdit = useCallback((item: EmployeeItem) => openAddEditPopup(item),[]);

  const onView = useCallback((item: EmployeeItem) => {
    routerStore.moveToContact({
      id: item.contact.id,
      contactType: item.contact.type,
      tab: CONTACT_DETAILS_TAB.profile,
      subTabProps: {
        contactType: item.contact.type,
        subTab: INDIVIDUAL_PROFILE_LINKS.individualDetails
      }
    });
  },[routerStore]);

  useEffect(() => {
    employeeStore.init(contactId);
    return () => employeeStore.reset();
    // eslint-disable-next-line
  },[employeeStore]);

  return (
    <TabContentWrapper isFluid={ true }>
      {
        isLoad &&
        <Preloader/>
      }
      <TabContentWrapper.Item $withPadding={ false }>
        <PreTableWrapper>
          <BlockButton
            onClick={ onAdd }
            startIcon={ <PlusWithCircle/> }
            style={ ADD_BUTTON_STYLE }
          >
            { LINK_NEW_CUSTOM_FIELD }
          </BlockButton>
        </PreTableWrapper>
        <Table>
          <Table.Container triggerScrollToTop={ table.currentPage }>
            <Table.Header
              columns={ COLUMNS }
              onSortChangeCb={ table.setMultipleSorting }
              sortingState={ table.multipleSorting }
            />
            <Table.Body>
              {
                table.items.length > 0 &&
                table.items.map(employee => (
                  <EmployeeRow
                    data={ employee }
                    key={ employee.id }
                    onDelete={ onDelete }
                    onEdit={ onEdit }
                    onOutOfSightClick={ onOutOfSightClick }
                    onView={ onView }
                  />
                ))
              }
            </Table.Body>
            {
              !isLoad && table.items.length === 0 &&
              <ContentCenter>
                <NoDataItemsView
                  addActionButtonClickHandler={ onAdd }
                  addActionButtonLabel={ LINK_NEW_CUSTOM_FIELD }
                  withAction={ true }
                />
              </ContentCenter>
            }
          </Table.Container>
          <Table.Footer
            currentPage={ table.currentPage }
            onPageChangeCb={ table.setCurrentPage }
            pagesCount={ table.totalPages }
          >
            <Table.FooterCountInfo
              from={ table.itemsFrom }
              to={ table.itemsTo }
              total={ table.totalItems }
            />
          </Table.Footer>
        </Table>
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
};
