import { AtSign, Compass, MapPin, Phone, Facebook, Twitter, Linkedin } from 'react-feather';

import { CONTACTS_LOCATION_IDS, FIELD_IDS } from '@constants/contactsData';

import { Contact, Locations } from '@/shared/types/contactsData';
import { ContactDetailsDataMap } from './types';

export const isLocation = (contact: Contact): contact is Locations => {
  return CONTACTS_LOCATION_IDS.includes(contact.fieldId);
};

const CONTACT_DETAILS_LABELS = {
  mobile: 'Mobile',
  work: 'Work',
  home: 'Home',
  mailing: 'Mailing',
  other: 'Other',
  facebook: 'Facebook',
  twitter: 'Twitter',
  linkedin: 'LinkedIn',
  preference: 'Contact Prefer.'
};

export const CONTACTS_DETAILS_DATA_MAP: ContactDetailsDataMap = {
  [FIELD_IDS.WORK_LOCATION_ID]: {
    label: CONTACT_DETAILS_LABELS.work,
    icon: MapPin,
  },
  [FIELD_IDS.HOME_LOCATION_ID]: {
    label: CONTACT_DETAILS_LABELS.home,
    icon: MapPin,
  },
  [FIELD_IDS.MAILING_LOCATION_ID]: {
    label: CONTACT_DETAILS_LABELS.mailing,
    icon: MapPin,
  },
  [FIELD_IDS.OTHER_LOCATION_ID]: {
    label: CONTACT_DETAILS_LABELS.other,
    icon: MapPin,
  },
  [FIELD_IDS.MOBILE_PHONE_ID]: {
    label: CONTACT_DETAILS_LABELS.mobile,
    icon: Phone,
  },
  [FIELD_IDS.WORK_PHONE_ID]: {
    label: CONTACT_DETAILS_LABELS.work,
    icon: Phone,
  },
  [FIELD_IDS.HOME_PHONE_ID]: {
    label: CONTACT_DETAILS_LABELS.home,
    icon: Phone,
  },
  [FIELD_IDS.OTHER_PHONE_ID]: {
    label: CONTACT_DETAILS_LABELS.other,
    icon: Phone,
  },
  [FIELD_IDS.FACEBOOK_LINK_ID]: {
    label: CONTACT_DETAILS_LABELS.facebook,
    icon: Facebook,
  },
  [FIELD_IDS.TWITTER_LINK_ID]: {
    label: CONTACT_DETAILS_LABELS.twitter,
    icon: Twitter,
  },
  [FIELD_IDS.LINKEDIN_LINK_ID]: {
    label: CONTACT_DETAILS_LABELS.linkedin,
    icon: Linkedin,
  },
  [FIELD_IDS.WORK_EMAIL_ID]: {
    label: CONTACT_DETAILS_LABELS.work,
    icon: AtSign,
  },
  [FIELD_IDS.HOME_EMAIL_ID]: {
    label: CONTACT_DETAILS_LABELS.home,
    icon: AtSign,
  },
  [FIELD_IDS.OTHER_EMAIL_ID]: {
    label: CONTACT_DETAILS_LABELS.other,
    icon: AtSign,
  },
  [FIELD_IDS.CONTACT_PREFERENCE_ID]: {
    label: CONTACT_DETAILS_LABELS.preference,
    icon: Compass,
  }
};
