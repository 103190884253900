import { ContactNote, ContactNoteData } from '@/shared/types/note';
import { LINKED_TYPES } from '@constants/todosData';
import { NOTES_LINKED_ITEMS_IDS } from '@constants/overview';
import { IdType, ValueOf } from '../types/commonTypes';

export const notesNormalized = (data: ContactNoteData, contact: any): Array<ContactNote> => ([
  ...noteConverter(data.contactNotes, contact),
  ...noteConverter(data.accountPlansNotes, contact, LINKED_TYPES.account),
  ...noteConverter(data.policyNotes, contact, LINKED_TYPES.policy),
  ...noteConverter(data.taskNotes, contact, LINKED_TYPES.task),
  ...noteConverter(data.plansNotes, contact, LINKED_TYPES.plan),
  ...noteConverter(data.opportunityNotes, contact, LINKED_TYPES.opportunity),
  ...noteConverter(data.salesPipeLineNotes, contact, LINKED_TYPES.salesCycle),
]);

const noteConverter = (notes: Array<ContactNote>, contact: any, type?: LINKED_TYPES): Array<ContactNote> => {
  return notes.map((note) => {
    if(type) {
      for (const [key, value] of Object.entries(note)) {
        if(Object.values(NOTES_LINKED_ITEMS_IDS).includes(key as ValueOf<typeof NOTES_LINKED_ITEMS_IDS>) && value) {
          note.linkedItemId = value as IdType;
          note.linkedItem = {
            //@ts-ignore
            linkedContactType: type,
            contactData: contact,
          };
          if(key === NOTES_LINKED_ITEMS_IDS.opportunityId) {
            //@ts-ignore
            note.linkedItem.opportunityStatus = note.opportunityStatus;
          }
          //@ts-ignore
          delete note[key];
        }
      }
      note.type = type;
    }
    return note;
  });
};
