import * as yup from 'yup';

import { dateOutputValidation } from '@constants/validationPatterns';
import { STAGES, TODOS_FIELDS_NAMES } from '@constants/todosData';
import { validationMessages } from '@constants/validationMessages';

import { IdType } from '@/shared/types/commonTypes';

export const validationSchema = yup.object().shape({
  [TODOS_FIELDS_NAMES.name]: yup.string().required(validationMessages.required),
  [TODOS_FIELDS_NAMES.linkedItemId]: yup.string()
    .nullable()
    .when(TODOS_FIELDS_NAMES.isLinkedContact, {
      is: (isLinkedContact: boolean) => isLinkedContact,
      then: schema => schema.required(validationMessages.required)
    }),
  [TODOS_FIELDS_NAMES.priority]: yup.string()
    .nullable()
    .test(
      'priority and stage with forNextMeeting',
      validationMessages.required,
      (value, context) => {
        const stage = context.parent[TODOS_FIELDS_NAMES.stage];
        if(!value && stage === STAGES.forNextMeeting || value && stage !== STAGES.forNextMeeting) {
          return true;
        }
        return false;
      },
    ),
  [TODOS_FIELDS_NAMES.stage]: yup.string().nullable().required(validationMessages.required),
  [TODOS_FIELDS_NAMES.assignId]: yup.string().nullable().required(validationMessages.required),
  [TODOS_FIELDS_NAMES.dueDate]: yup
    .string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate),
  [TODOS_FIELDS_NAMES.frequency]: yup.string()
    .nullable()
    .when(TODOS_FIELDS_NAMES.requirring, {
      is: (requirring: boolean) => requirring,
      then: schema => schema.required(validationMessages.required)
    }),
  [TODOS_FIELDS_NAMES.endDate]: yup.string()
    .nullable()
    .when(TODOS_FIELDS_NAMES.requirring, {
      is: (requirring: boolean) => requirring,
      then: schema => schema
        .required(validationMessages.required)
        .matches(dateOutputValidation, validationMessages.invalidDate)
    }),
  [TODOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor]: yup.boolean()
    .nullable()
    .when(TODOS_FIELDS_NAMES.assignedBy, {
      is: (assignedBy: IdType) => assignedBy,
      otherwise: schema => schema.transform(() => false)
    }),
});
