import { LINKED_ITEMS_TYPES_SCHEME } from '@/shared/constants/todosData';
import {
  ACCOUNT_CONFIG,
  CONTACT_CONFIG,
  DEFAULT_CONFIG,
  OPPORTUNITY_CONFIG,
  PLAN_CONFIG,
  SALES_CYCLE_CONFIG,
  TASK_CONFIG
} from './data';

export const getColumn = (group: string) => {
  switch(group) {
  case LINKED_ITEMS_TYPES_SCHEME.taskData.type:
    return TASK_CONFIG;    
  case LINKED_ITEMS_TYPES_SCHEME.contactData.type:
    return CONTACT_CONFIG;
  case LINKED_ITEMS_TYPES_SCHEME.opportunityData.type:
    return OPPORTUNITY_CONFIG;
  case LINKED_ITEMS_TYPES_SCHEME.salesPipeLineData.type:
    return SALES_CYCLE_CONFIG;
  case LINKED_ITEMS_TYPES_SCHEME.accountData.type:
  case LINKED_ITEMS_TYPES_SCHEME.policyData.type:
    return ACCOUNT_CONFIG;
  case LINKED_ITEMS_TYPES_SCHEME.planData.type:
    return PLAN_CONFIG;
  default:
    return DEFAULT_CONFIG;
  }
};
