import { action, makeAutoObservable } from 'mobx';

import { Store } from '../store';

import { LinkedContact } from '@/shared/types/linkedContact';

class AppHeaderStore {
  contact: LinkedContact | null = null;
  coreStore: Store;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      setContact: action.bound
    });
    this.coreStore = coreStore;
  }

  setContact(contact: LinkedContact | null) {
    this.contact = contact;
  }
}

export default AppHeaderStore;
