import React, { useMemo, memo } from 'react';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';

import { Notes } from '@/assets';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import RowFilter from '@components/RowFilter';
import TagButton, { TAG_BUTTON_TYPES } from '@components/TagButton';
import TextWithTooltip from '@components/TextWithTooltip';

import TruncatedText from '@components/TruncatedText';

import { COLORS } from '@constants/colors';
import { TIME_FORMAT } from '@constants/dateFormats';

import { parseFormatedUTCDateString } from '@/shared/utils/parseFormatedUTCDateString';

import {
  ActionButtonsWrapper,
  Buttons,
  HeaderIconWrapper,
  InfoRow,
  NoteContent,
  NoteHeader,
  NoteType,
  Tags,
  TitleAndButtonsWrapper,
  Wrapper
} from './styles';

// TODO Ts migrate
export const NoteItem = memo(({
  isAbleToEdit = true,
  item,
  onEditClick,
  onDeleteClick,
}) => {
  const {
    title,
    created,
    clientData,
    tags = [],
    noteText,
  } = item;

  const creationDataText = useMemo(() => {
    if (!created) {
      return '';
    }
    const date = parseFormatedUTCDateString(created);
    const dateString = format(date, 'LL/dd/yyyy');
    const timeString = format(date, TIME_FORMAT);
    return `${ dateString } at ${ timeString }`;
  }, [created]);

  const clearText = (text) => {
    const sanitizedData = DOMPurify.sanitize(text);

    const sanDataWithBgColorAfterSearch = sanitizedData.
      replace('\t', '')
      .replace(/\n/g, '')
      .replace(/\s{2,}/g, ' ')
      .replace(/<[^>]*>/gi, ' ')
      .replace(/&nbsp;/g, '')
      .replaceAll(/markedTextStart/g, '<span style="background-color: #e7f2f9;">')
      .replaceAll(/markedTextEnd/g, '</span>');

    return <span>{ReactHtmlParser(sanDataWithBgColorAfterSearch)}</span>;
  };

  const creatorText = `by ${ clientData?.firstName } ${ clientData?.lastName }`;

  return (
    <Wrapper>
      <NoteHeader>
        <HeaderIconWrapper>
          <Notes/>
        </HeaderIconWrapper>
        <TitleAndButtonsWrapper>
          <TextWithTooltip
            $fontColor={ COLORS.header }
            $fontSize={ 16 }
            $maxWidth={ 150 }
            text={ clearText(title) }
          />
          { isAbleToEdit && 
            <Buttons>
              <ActionButtonsWrapper>
                <CustomIconButton
                  onClick={ onEditClick }
                  type={ ICON_BUTTON_TYPES.edit }
                />
              </ActionButtonsWrapper>
              <ActionButtonsWrapper>
                <CustomIconButton
                  onClick={ onDeleteClick }
                  type={ ICON_BUTTON_TYPES.close }
                />
              </ActionButtonsWrapper>
            </Buttons>
          }
        </TitleAndButtonsWrapper>
        <Tags>
          {
            tags?.length >= 2 &&
            <RowFilter
              items={ tags }
              limit={ 1 }
              renderItem={ ({
                content,
                isShowMore,
                onClick,
                isUnlimited,
                indexForKey,
              }) => (
                <TagButton
                  $viewContentMaxWidth={ isUnlimited ? '50px' : null }
                  content={ isShowMore ? content : content?.value }
                  key={ indexForKey }
                  onClickCb={ onClick }
                  style={ !isUnlimited? {}: { marginRight: '10px' } }
                  type={ isShowMore ? TAG_BUTTON_TYPES.button: TAG_BUTTON_TYPES.view }
                />
              ) }
            />
          }
          {
            tags?.length === 1 &&
            <TagButton
              content={ tags[0].value }
              type={ TAG_BUTTON_TYPES.view }
            />
          }
        </Tags>
        { item.type && <NoteType>{ item.type }</NoteType> }
      </NoteHeader>
      <NoteContent>
        {
          noteText &&
          <TruncatedText
            symbolLimit={ 100 }
            text={ clearText(noteText) }
          />
        }
        <InfoRow>
          <TextWithTooltip
            $fontColor={ COLORS.grey }
            $fontSize={ 14 }
            $maxWidth={ 250 }
            text={ creatorText }
          />
          <TextWithTooltip
            $fontColor={ COLORS.grey }
            $fontSize={ 14 }
            $maxWidth={ 250 }
            text={ creationDataText }
          />
        </InfoRow>
      </NoteContent>
    </Wrapper>
  );
});

NoteItem.displayName = 'NoteItem';
