import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import FilterButton from '@components/FilterButton';
import PageHeaderWrapper from '@components/BasicLayoutComponents/PageHeaderWrapper';

import { FiltersBtnWrapper } from './styles';

export const PageHeader = observer(({ isFiltersBtnActive, onFiltersClick }) => {
  const calendarStore = useStore().CalendarStore;
  const { selectedFiltersCount, onClear } = calendarStore;


  return (
    <PageHeaderWrapper>
      <FiltersBtnWrapper>
        <FilterButton
          badgeClickHandler={ onClear }
          badgeCount={ selectedFiltersCount }
          buttonCLickHandler={ onFiltersClick }
          enableFilterMode={ !isFiltersBtnActive }
        />
      </FiltersBtnWrapper>
    </PageHeaderWrapper>
  );
});

PageHeader.propTypes = {
  isFiltersBtnActive: PropTypes.bool,
  onFiltersClick: PropTypes.func,
};
