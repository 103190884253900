export const DESCRIPTION_INPUT_PROPS = {
  style: {
    margin: '-9px -5px'
  },
  placeholder: 'Enter Description',
  maxLength: 250,
};

export const TASK_NAME_INPUT_PROPS = {
  placeholder: 'Enter Task Name',
  maxLength: 150,
};

export const DESCRIPTION_TEXT_FIELD__PROPS = {
  multiline: true,
  rows: 3
};
