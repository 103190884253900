import { TODOS_FIELDS_NAMES } from '@constants/todosData';

export const NOTES_AND_HISTORY_INPUT_NAMES = {
  notes: TODOS_FIELDS_NAMES.taskNotes,
  noteType: TODOS_FIELDS_NAMES.noteType,
  title: TODOS_FIELDS_NAMES.noteTitle,
  noteContactId: TODOS_FIELDS_NAMES.noteContactId,
  noteId: TODOS_FIELDS_NAMES.noteId,
  noteText: TODOS_FIELDS_NAMES.noteText,
  tags: TODOS_FIELDS_NAMES.noteTags,
  tagId: TODOS_FIELDS_NAMES.noteTagId,
  tagValue: TODOS_FIELDS_NAMES.noteTagValue,
};

export const LABEL_WIDTH = {
  common: 123,
  rightColumn: 112,
  taskForAssigner: 240
};
