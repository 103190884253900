export const COLORS = {
  background: '#F8F9FD',
  bgActions: '#FFEDD8',
  blue: '#1179C3',
  borders: '#E1E3EC',
  categoryAccountReview: '#F2B61A',
  categoryAccountReviewSecondary: '#FFF5DE',
  categoryAccountReviewThird: '#FFAE00',
  categoryEvent: '#1179C3',
  categoryEventSecondary: '#E7F2F9',
  categoryEventThird: '#1179C3',
  categoryGeneral: '#4B43BC',
  categoryGeneralSecondary: '#EBECFA',
  categoryGeneralThird: '#5A66D4',
  categoryMeeting: '#D45AA0',
  categoryMeetingSecondary: '#FFEBEB',
  categoryMeetingThird: '#D45AA0',
  categorySalesAppointments: '#1DBD37',
  categorySalesAppointmentsSecondary: '#E8F8EB',
  categorySalesAppointmentsThird: '#4AB26E',
  dividingLines: '#F2F3F8',
  gradientChart: '#AE9EFF7A',
  greenBg: '#E8F8EB',
  green: '#1DBD37',
  greyLight: '#ECEDF3',
  grey: '#C6C7CF',
  header: '#3A3A46',
  helpText: '#838390',
  imageUploaderHover: '#C0C0CB80',
  interestTagHoverBg: '#cbedf5',
  interestTagText: '#2594AE',
  interestTag: '#E0F5FA',
  lightBlue: '#E7F2F9',
  lightGreen: '#96D171',
  light: '#FDFDFE',
  logoOrange: '#F99501',
  menuBckSecond: '#222C3A',
  menuBck: '#263140',
  menuItemHover: '#323C4B',
  menuCollapseBck: '#2F3C4E',
  menuLines: '#172130',
  menuSelect: '#1A2534',
  orange: '#EA8213',
  orangeBgc: '#E07809',
  pink: '#D45AA0',
  primaryText: '#4E4E61',
  purpleSecond: '#9B93EF',
  purple: '#4B43BC',
  redBg: '#FFEBEB',
  redLight: '#F17A7A',
  redSecond: '#D13030',
  red: '#E24141',
  search: '#C6C7CF',
  select: '#FFF9F1',
  silver: '#757575',
  systems: '#9D9DB2',
  tagBorder: '#BDBBE1',
  tagText: '#736FAB',
  tagCustomBg: '#D9D6F6',
  tag: '#E6E3FF',
  white: '#ffffff',
  yellow: '#F2B61A',
  info: '#7D77D5',
  notificationWithAction: '#0A0A0A',
  notificationGreenBg: '#F5FFF6',
  disabled: '#9e9e9e',
  followingUp: '#00C7D3'
};
