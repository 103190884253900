import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';

import format from 'date-fns/format';

import { useStore } from '@store';

import { useResizeObserver } from '@customHooks';

import { OutOfSightActivity } from '@components/OutOfSight';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import TextEllipsis from '@components/TextEllipsis';

import { CALENDAR_TOOLTIP_DYNAMIC_PROP } from '@pages/NewCalendar/data';
import { COLORS_MAP_BY_EVENT_TYPE, EVENT_TYPES } from '@constants/colorsByEventTypes';
import { MODAL_TYPE } from '@constants/modalTypes';
import { TIME_FORMAT } from '@constants/dateFormats';

import { TimeBlockIcon } from '@/assets';

import {
  EventInnerWrapper,
  StyledTooltip
} from '..';

import {
  EVENT_VIEW_TYPE,
  ICON_MAX_VISIBILITY_WIDTH,
  MAX_VISIBILITY_HEIGHT,
  TEXT_MAX_VISIBILITY_WIDTH,
} from './data';
import { getEventViewType } from './utils';

import {
  AllDayEventTextWrapper,
  AllDayEventWrapper,
  ColumnEventDivider,
  ColumnEventLabel,
  ColumnEventTime,
  ColumnEventWrapper,
  CommonEventWrapper,
  HighLineEventTime,
  HighLineEventTitle,
  HighLineEventWrapper,
  IconWrapper,
  LineEventWrapper,
  RefWrapper,
} from './styles';

const LINE_HEIGHT = 16;
const COEFFICIENT = 30;

export const DayAndWeekEventWrapper = ({ event }) => {
  const {
    title,
    start,
    end,
    resource: {
      editAllow,
      isAllDayGrid,
      statusLabel,
      type,
    }
  } = event;

  const ref = useRef(null);

  const [ isShowText, setIsShowText ] = useState(true);
  const [ isShowEye, setIsShowEye ] = useState(true);

  const [ viewType, setViewType ] = useState(null);
  const [ lineClamp, setLineClamp ] = useState(null);
  const { dividerColor } = COLORS_MAP_BY_EVENT_TYPE[type] || {};

  const isWithStatus =  statusLabel && type !== EVENT_TYPES.timeBlock;

  const content = useMemo(() => {
    if(type === EVENT_TYPES.holiday){
      return title;
    }

    return isAllDayGrid
      ? `${isWithStatus ? `${statusLabel} `: '' }${format(start, TIME_FORMAT)} - ${title}`
      : `
        ${isWithStatus ? `[${statusLabel}] `: '' }
        ${format(start, TIME_FORMAT)} - ${format(end, TIME_FORMAT)} - ${title}
      `;
  }, [end, isAllDayGrid, start, title]);

  const titleWithStatus = `${isWithStatus ? `[${statusLabel}] `: '' }${title}`;

  const timeBlockIcon = useMemo(() => {
    if(type === EVENT_TYPES.timeBlock){
      return (
        <IconWrapper>
          <TimeBlockIcon/>
        </IconWrapper>
      );
    } else {
      return null;
    }
  }, [type]);

  const onResize = (data) => {
    if(isAllDayGrid) {
      return;
    }
    const { contentRect: {
      width,
      height,
    } } = data;
    const lineClamp = Math.floor((height - COEFFICIENT) / LINE_HEIGHT);

    setLineClamp(lineClamp);
    setViewType(getEventViewType(width,height));
  };

  useResizeObserver({
    elementRef: ref,
    onResizeCb:onResize,
  });

  const isBlocked = typeof editAllow === 'number' && !editAllow && type !== EVENT_TYPES.holiday;

  const modalStore = useStore().ModalStore;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  useEffect(() => {
    if(ref && ref.current) {
      const height = ref.current.getBoundingClientRect().height;
      const width = ref.current.getBoundingClientRect().width;
      if(width < TEXT_MAX_VISIBILITY_WIDTH || height < MAX_VISIBILITY_HEIGHT) {
        setIsShowText(false);
      }
      if(width < ICON_MAX_VISIBILITY_WIDTH || height < MAX_VISIBILITY_HEIGHT) {
        setIsShowEye(false);
      }
    }
  }, [ref]);
  
  return (
    <EventInnerWrapper type={ type }>
      <StyledTooltip
        arrow
        displayOnOverFlow={ viewType !== EVENT_VIEW_TYPE.empty }
        title={ content }
      >
        {/* this ref wrapper used for tooltip */}
        <RefWrapper { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }>
          {
            isAllDayGrid &&
            <AllDayEventWrapper>
              { timeBlockIcon }
              <AllDayEventTextWrapper { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }>
                { content }
              </AllDayEventTextWrapper>
            </AllDayEventWrapper>
          }

          {
            !isAllDayGrid  &&
            <CommonEventWrapper ref={ ref }>
              {
                viewType === EVENT_VIEW_TYPE.column &&
                <ColumnEventWrapper >
                  <TextEllipsis { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }>
                    { timeBlockIcon }
                    { `${format(start, TIME_FORMAT)} - ${format(end, TIME_FORMAT)}` }
                  </TextEllipsis>
                  <ColumnEventDivider $color={ dividerColor }/>
                  <ColumnEventLabel
                    $lineClamp={ lineClamp }
                    { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }
                  >
                    { titleWithStatus }
                  </ColumnEventLabel>
                </ColumnEventWrapper>
              }

              {
                viewType === EVENT_VIEW_TYPE.line &&
                <LineEventWrapper { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }>
                  { titleWithStatus }
                </LineEventWrapper>
              }

              {
                viewType === EVENT_VIEW_TYPE.highLine &&
                <HighLineEventWrapper>
                  <HighLineEventTime>
                    { timeBlockIcon }
                    { `${format(start, TIME_FORMAT)} - ${format(end, TIME_FORMAT)}` }
                  </HighLineEventTime>
                  <ColumnEventDivider $color={ dividerColor }/>
                  <HighLineEventTitle { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }>
                    {titleWithStatus}
                  </HighLineEventTitle>
                </HighLineEventWrapper>
              }
            </CommonEventWrapper>
          }
        </RefWrapper>
      </StyledTooltip>
      {
        isBlocked &&
        <OutOfSightActivity
          onClick={ onOutOfSightClick }
          $marginLeft
          isShowText={ isShowText }
          isShowEye={ isShowEye }
        />
      }
    </EventInnerWrapper>
  );
};
