import React, { useCallback, useMemo, memo } from 'react';
import { useDrag } from 'react-dnd';

import Avatar from '@components/Avatar';
import BookmarkIcon  from '@components/BookmarkIcon';
import SalesCycleDotsMenu from '@modules/SalesPipline/SalesCycleDotsMenu';
import TextWithTooltip from '@components/TextWithTooltip';

import {
  ActionsWrapper,
  AvatarWrapper,
  InfoFirstRow,
  InfoSecondRow,
  InfoWrapper,
  Wrapper
} from './styles';

import { OutOfSightCommon } from '@components/OutOfSight';
import { SALES_CYCLE_KANBAN_DND_TYPE, SALES_CYCLE_STATUS_COLOR_MAP } from '@constants/salesActiveCycle';
import { USER_ICON_SIZES } from '@components/UserIcon';

import { getAvatarData } from '@/shared/utils/getAvatarData';
import { getContactName } from '@/shared/utils/getContactName';
import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';
import { getDateContent } from '@/shared/utils/getDateContent';

import { SaleCycleKanbanTicketProps } from './types';
import { SalesKanbanDragDataType } from '@/shared/types/salesActiveCycle';


const PLACEHOLDER = '-';

export const SalesActiveCycleKanbanTicket = memo(({
  item,
  onDelete,
  onEdit,
  onBookmark,
  onOutOfSightClick
}: SaleCycleKanbanTicketProps) => {
  const isBlocked = typeof item?.contactData?.editAllow === 'number' && !item?.contactData?.editAllow;

  const [, dragRef] = useDrag<SalesKanbanDragDataType>({
    type: SALES_CYCLE_KANBAN_DND_TYPE,
    item: {
      id: item.id,
      prevStage: item.stage,
      isBlocked: isBlocked
    }
  });

  const avatarData = useMemo(() => getAvatarData(item.contactData), [item.contactData]);

  const name = useMemo(() => getContactName(item.contactData), [item.contactData]);
  const amountConverted = useMemo(() => formatNumberToAmount(item?.amount), [item?.amount]);
  const convertedDate = useMemo(() =>  {
    return getDateContent({
      dateString: item?.cycleOpenDate
    }) || PLACEHOLDER;
  }, [item?.cycleOpenDate]);

  const meetingStatus = item?.meetingStatus || PLACEHOLDER;

  const onBookmarkClick = useCallback(() => {
    onBookmark(item.id, item.stage);
  }, [item.id, item.stage, onBookmark]);

  return (
    <Wrapper
      $indicatorColor={ SALES_CYCLE_STATUS_COLOR_MAP[item.salesCycleStatus] }
      ref={ dragRef }
    >
      <AvatarWrapper>
        <Avatar
          $size={ USER_ICON_SIZES.large }
          $subType={ true }
          mainImageData={ avatarData.mainImageData }
          subImageData={ avatarData?.subImageData }
        />
      </AvatarWrapper>
      <InfoWrapper>
        <InfoFirstRow>
          <TextWithTooltip
            $fontSize={ 14 }
            text={ name }
          />
          <TextWithTooltip
            $fontSize={ 12 }
            text={ amountConverted }
          />
        </InfoFirstRow>
        <InfoSecondRow>
          <TextWithTooltip
            $fontSize={ 12 }
            text={ meetingStatus === PLACEHOLDER ? 'Status: -' : meetingStatus }
          />
          <TextWithTooltip
            $fontSize={ 10 }
            text={ convertedDate === PLACEHOLDER ? 'Date: -'  : convertedDate }
          />
        </InfoSecondRow>
      </InfoWrapper>
      <ActionsWrapper>
        <BookmarkIcon
          isActive={ Boolean(item.flagged) }
          onStateChange={ onBookmarkClick }
        />

        <SalesCycleDotsMenu
          $marginTop={ '5px' }
          id={ item.id }
          onDelete={ onDelete }
          onEdit={ onEdit }
        />
      </ActionsWrapper>
      { isBlocked && <OutOfSightCommon onClick={ onOutOfSightClick }/> }
    </Wrapper>);
});

SalesActiveCycleKanbanTicket.displayName = 'SalesActiveCycleKanbanTicket';
