import { getUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { TODOS_FILTER_NAMES } from '@constants/todosData';

import { Client, IdType } from '@/shared/types/commonTypes';
import { TodoData, FilterData, FilterDataResponse } from './types';
import { TodoItem } from '@/shared/types/todos';

export const normalizer = (
  response: TodoData,
  contactId: IdType,
): {
  filterData: FilterData,
  items: Array<TodoItem>,
} => {
  const { filterData, ...todosItems } = response;

  const items = Object.values(todosItems) as Array<TodoItem>;

  return {
    filterData: filterDataConverter(filterData, contactId),
    items,
  };
};

const filterDataConverter = (
  filterData: FilterDataResponse, contactId: IdType
): FilterData => {
  if (!filterData) {
    return {} as FilterData;
  }

  return {
    [TODOS_FILTER_NAMES.users]: usersFilterData(filterData, contactId),
    [TODOS_FILTER_NAMES.category]: optionsFromValue(filterData.category),
    [TODOS_FILTER_NAMES.stage]: filterData.stage,
  };
};

const usersFilterData = (filterData: FilterDataResponse, currentUserId: IdType) => {
  return filterData.clients.map((userObj: Client) => (
    getUserOption({
      userObj,
      currentUserId,
    })
  ));
};