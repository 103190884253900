import React, { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { ChevronDown, ChevronUp } from 'react-feather';

import { useToggle } from '@customHooks';

import BlockButton from '@components/BlockButton';
import ControlledHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import Counter, { VARIANTS } from '@components/Counter';
import FetchAutoComplete from '@modules/FetchAutoComplete';
import FormBlock from '@components/FormBlock';
import SelectItemWithCheckbox from '@components/SelectItemWithCheckbox';
import UserItem from '@components/NewUserItem';

import { CounterWrapper, UserList } from './styles';

import {
  ContactVisibilityItemProps
} from '@pages/NewUserSettings/components/ProfileInformation/ContactVisibilityPermissions/types';
import { ClientWithCalendarData } from '@/shared/types/commonTypes';


export const ContactVisibilityItem = <T, I>({
  $labelWidth,
  FetchProps,
  control,
  data,
  errorMessage,
  isRequired = false,
  label,
  name,
  placeholder,
  setData,
  showError,
  unHideItemsLength = 3,
  userListStyle = {},
  userItemLabelWidth = 115,
}: ContactVisibilityItemProps<T, I>) => {

  const [isRolledUp, toggleIsRolledUp] = useToggle(false);
  const buttonClickHandler = useCallback(() => { toggleIsRolledUp(); }, [toggleIsRolledUp]);

  const keyName = `${name}Id`;

  const { fields, append, remove } = useFieldArray({
    name,
    control,
    keyName,
  });

  const visibilityControlButtonLabel = useMemo(() => isRolledUp ? 'Hide' : 'See More', [isRolledUp]);

  const dataToRender = isRolledUp ? data : data?.slice(0, unHideItemsLength);

  const deleteItem = useCallback(({ index, id }: Record<string, number>) => {
    if(fields.length - 1 === unHideItemsLength) {
      toggleIsRolledUp(false);
    }
    remove(index);
    const newClientList = data.filter((item) => item.id !== id);
    setData(newClientList);
  }, [ fields.length, remove, setData, toggleIsRolledUp, unHideItemsLength]);

  const onAutoCompleteItemClick = useCallback((newData: ClientWithCalendarData) => () => {
    const selectedIndex = fields.findIndex(item => item.id.toString() === newData.id.toString());

    if(selectedIndex >= 0) {
      return deleteItem({
        index: selectedIndex,
        id: newData?.id as number
      });
    }
    append({ id: newData.id });
    const newClientList = [...data, newData];
    setData(newClientList);
  },[ fields, append, deleteItem, setData ]);

  const onDelete = useCallback((index: number, id: number) => () => {
    deleteItem({ index, id });
  },[ deleteItem ]);

  return (
    <FormBlock.FormField
      $labelWidth={ $labelWidth }
      isRequired={ isRequired }
      label={ label }
      renderLabelContent={ () => (
        <CounterWrapper>
          <Counter variant={ VARIANTS.orange }>{fields?.length || 0}</Counter>
        </CounterWrapper>
      ) }
    >
      <FetchAutoComplete<I>
        AutocompleteProps={ {
          disableCloseOnSelect: true,
          ListboxProps: {
            style: {
              maxHeight: '200px'
            },
          },
          getOptionLabel: (option: any) => option.fullName || '',
          renderOption: (option: any) => {
            const index = data.findIndex((item) => item.id === option.id);
            return (
              <SelectItemWithCheckbox
                checked={ index >= 0 }
                onClick={ onAutoCompleteItemClick(option) }
              >
                <UserItem
                  labelWidth={ userItemLabelWidth }
                  user={ option }
                />
              </SelectItemWithCheckbox>
            );
          },
        } }
        FetchProps={ FetchProps }
        TextFieldProps={ {
          error: Boolean(errorMessage),
          helperText: errorMessage || ''
        } }
        placeholder={ placeholder }
        showError={ showError }
        withoutQueryMode={ undefined }
      />

      { data.length > 0 &&
        <UserList style={ userListStyle }>
          {
            fields.map((field, index) => (
              <ControlledHiddenInput
                control={ control }
                defaultValue={ field.id }
                key={ field.id }
                name={ `${name}.${index}.id` }
              />
            ))
          }
          {
            dataToRender.map((userRenderData: ClientWithCalendarData, index: number) => (
              <UserItem
                key={ userRenderData?.id }
                labelWidth={ 145 }
                onClose={ onDelete(index, userRenderData?.id as number) }
                user={ userRenderData }
              />
            ))
          }
          {
            data.length > unHideItemsLength &&
            <BlockButton
              $isNoneFill={ true }
              endIcon={ isRolledUp ? <ChevronUp/> : <ChevronDown/> }
              onClick={ buttonClickHandler }
              style={ {
                marginRight: 'auto',
              } }
            >
              { visibilityControlButtonLabel }
            </BlockButton>
          }
        </UserList>
      }

    </FormBlock.FormField>
  );
};
