import { isEmpty } from 'lodash';
import { startOfYear, endOfYear, getYear, startOfQuarter, endOfQuarter, format } from 'date-fns';

import { PERIOD_FILTER_NAMES, QUARTERS_SCHEME } from '@constants/dashboard';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import { ActivityTaskResponseData, AdvisorFilter, Dataset, Period } from '@/shared/types/dashboard';
import { Adviser } from '@/shared/types/contactOptionsTypes';
import { IdType } from '@/shared/types/commonTypes';

export const getSummary = (data: Array<Dataset>): number => {
  if(!isEmpty(data)) {
    return data.reduce((acc: number, current: Dataset) => acc + Number(current.dataBalance.value), 0);
  }
  return 0;
};

export const getPeriodFilters = (period: Period, year: Date, quarter: number): {
  startDate: string,
  endDate: string
} => {
  let startDate = startOfYear(year);
  let endDate = endOfYear(year);

  if(period === PERIOD_FILTER_NAMES.quarter) {
    startDate = startOfQuarter(new Date(getYear(year), QUARTERS_SCHEME[quarter].firstMonthNumber));
    endDate = endOfQuarter(new Date(getYear(year), QUARTERS_SCHEME[quarter].endMonthNumber));
  }

  return {
    startDate: format(startDate, YEAR_MONTH_DAY),
    endDate: format(endDate, YEAR_MONTH_DAY),
  };
};

export const dashboardDataNormalizer = (data: ActivityTaskResponseData) => ({
  activityCount: data.activityCount,
  taskCount: data.taskCount,
  primaryAdvisers: data.primaryAdvisers.map((adviser: Adviser) => ({
    label: adviser.fullName,
    value: adviser.id,
  }))
});

export const getAdviserItemsParams = (data?: Array<IdType>) => {
  if(Array.isArray(data)) {
    return data.reduce((acc: AdvisorFilter, id: IdType, index: number) => {
      acc[`adviserId[${index}]`] = id;
      return acc;
    }, {});
  } else {
    return {
      'adviserId[0]': data,
    };
  }
};

