
const createItemWithValueAndLabel = item => ({
  value: item,
  label: item,
});

// add Me here
const userConverter = user => ({
  value: `${user.id}`,
  label: `${user.firstName} ${user.lastName}`
});

export const filterOptionsNormalizer = (data) => ({
  category: data?.category?.map(createItemWithValueAndLabel) || [],
  classification: data?.coreClassification?.map(createItemWithValueAndLabel) || [],
  clients: data?.clients?.map(userConverter) || [],
});
