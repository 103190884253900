import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';

import { Store } from '@store';

import { deleteTodo, getPopupSettings, saveTodo, updateTodo } from '@services/api/addAndEditTask/addAndEditTask';
import { getTodos, massFlagUpdate, updateFromGrid } from '@services/api/todos/todos';
import CommonTableStore from '@services/store/commonTableStore';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { getFilterParams, getFiltersCount, getGlobalFlagged, getMultipleSortParams } from '@/shared/utils/filterUtils';
import { isNeedToUpdatePage } from '@services/store/todosStore/utils';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { todoPopupSettingsNormalizer } from '@/shared/utils/toDosNormalizers';
import { todosNormalizer } from '@services/store/todosStore/normalizers/todosNormalizer';

import {
  INIT_CLIENT_SPECIFIC_FILTERS_STATE,
  INIT_OTHER_FILTERS_STATE,
  INIT_OVERDUE_FILTER_STATE,
  INIT_STAGE_FILTER_STATE,
  TODOS_FILTER_NAMES,
} from '@constants/todosData';
import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { TO_DO_TABS } from '@constants/routes/toDos';

import {
  BackendTodoFormFields,
  DeleteTodoParams,
  GridResponse,
  OverdueFilter,
  TodoGridFormField,
  TodoItem,
  TodoPopupSettings,
  TodoPopupSettingsResponse
} from '@/shared/types/todos';
import { CategoryFilter, Filters, FiltersData } from '@services/store/todosStore/types';
import { ItemWithId } from '@/shared/types/commonTypes';


class ToDosStore {
  categoryFilter: CategoryFilter | null = null;
  coreStore: Store;
  currentTab: string = TO_DO_TABS.clientSpecificTasks;
  filters: Filters = this.currentTab === TO_DO_TABS.clientSpecificTasks ?
    INIT_CLIENT_SPECIFIC_FILTERS_STATE : 
    INIT_OTHER_FILTERS_STATE;
  filtersData: FiltersData = {} as FiltersData;
  isFiltersOpen: boolean = false;
  isPageActive: boolean = false;
  isTodoLoads = false;
  overdueFilter: OverdueFilter = INIT_OVERDUE_FILTER_STATE as OverdueFilter;
  previousCompleteToggleState: boolean = false;
  table: CommonTableStore<TodoItem>;
  todoPopupSettings: TodoPopupSettings = {} as TodoPopupSettings;

  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  onCategoryFilterChangeReaction: IReactionDisposer;
  onCurrentTabChangeReaction: IReactionDisposer;
  onFiltersChangeReaction: IReactionDisposer;
  onOverdueFilterChangeReaction: IReactionDisposer;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      asyncRequestExecutor: false,
      bookmark: flow.bound,
      getTasks: flow.bound,
      getTasksWithLoad: flow.bound,
      getToDos: flow.bound,
      getToDosPopupSettings: flow.bound,
      init: flow.bound,
      onFiltersChange: action.bound,
      onRemove: flow.bound,
      onSave: flow.bound,
      resetFilters: action.bound,
      resetStore: action.bound,
      setCategoryFilter: action.bound,
      setCurrentTab: action.bound,
      setFilters: action.bound,
      setOverdueFilter: action.bound,
      setPreviousCompleteToggleState: action.bound,
      updateFromGrid: flow.bound,
    });

    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.task
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.table = new CommonTableStore<TodoItem>({
      onGlobalFlaggedChangeReactionCallback: this.getTasksWithLoad,
      onSortReactionCallback: this.getTasksWithLoad,
      onPageChangeReactionCallback:  this.getTasksWithLoad
    });

    this.onCategoryFilterChangeReaction = this.createOnCategoryFilterChangeReaction();
    this.onCurrentTabChangeReaction = this.createOnCurrentTabChangeReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onOverdueFilterChangeReaction = this.createOnOverdueFilterChangeReaction();
  }

  get selectedFiltersCount() {
    const preparedFilters = Object.entries(this.filters).reduce((acc: any, item) => {
      const [key, value] = item as [string, Array<any>];

      if(key === TODOS_FILTER_NAMES.type) {
        acc[key] = [];
        return acc;
      }
      if(key === TODOS_FILTER_NAMES.stage){
        const isCurrentStageEqualInit = value.every((item) => INIT_STAGE_FILTER_STATE.includes(item));
        acc[key] = isCurrentStageEqualInit
          ? []
          : value;

        return acc;
      }
      acc[key] = value;
      return acc;
    }, {});
    return getFiltersCount(preparedFilters);
  }

  *init() {
    this.isPageActive = true;
    yield this.getToDosPopupSettings();
    yield this.getTasksWithLoad();
  }

  *getToDosPopupSettings() {
    if(isEmpty(this.todoPopupSettings)) {
      this.isTodoLoads = true;
      try {
        const todoPopupSettingsResp: TodoPopupSettingsResponse = yield getPopupSettings();
        this.todoPopupSettings = todoPopupSettingsNormalizer(todoPopupSettingsResp.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.isTodoLoads = false;
      }
    }
  }

  *getTasksWithLoad() {
    if(!this.isPageActive){
      return;
    }

    try {
      this.isTodoLoads = true;
      yield this.getTasks();
    } catch (error) {
      console.log(error);
    } finally {
      this.isTodoLoads = false;
    }
  }

  *getTasks() {
    if(!this.isPageActive){
      return;
    }
    this.table.clearItems(true);
    yield this.getToDos();
  }

  *getToDos() {
    try {
      const start = async () => {
        const toDosResp: GridResponse = await getTodos({
          page: this.table.currentPage,
          ...getFilterParams(this.filters),
          ...getFilterParams(this.overdueFilter.params ?? null),
          ...getFilterParams(this.categoryFilter),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...getGlobalFlagged(this.table.globalFlagged),
        });
        const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
        const { filtersData, todos } = todosNormalizer(toDosResp.data.data, currentUserId);
        
        this.table.items = todos;
        this.table.setPaginationData(toDosResp.data.data);
        this.table.checkAndSetIfPageOutOfRange();
        this.filtersData = filtersData as FiltersData;
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onRemove(params: DeleteTodoParams) {
    try {
      this.isTodoLoads = true;
      //@ts-ignore
      const restTasksAfterDelete = difference(this.table.selectedIDs, params);
      const countOfEntities = params.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteTodo(params),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          countOfEntities,
          isAutoUniqueKey: true
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          countOfEntities,
          isAutoUniqueKey: true
        })
      });

      yield this.getTasksWithLoad();
      this.table.checkAndSetIfPageOutOfRange();
      this.table.selectedIDs = restTasksAfterDelete;
    } catch (error) {
      console.log(error);
    } finally {
      this.isTodoLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onSave(data: BackendTodoFormFields) {
    try {
      this.isTodoLoads = true;
      if(data.id) {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateTodo(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: true,
            uniqueKey: data.id
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: true,
            uniqueKey: data.id
          }),
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveTodo(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: false,
            uniqueKey: data.id
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: false,
            uniqueKey: data.id
          }),
        });
      }
      yield this.getTasksWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.isTodoLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFromGrid(data: TodoGridFormField){
    const isNeedToReloadPage = isNeedToUpdatePage({
      updateData: data,
      tableItems: this.table.items,
      sortingState: this.table.multipleSorting,
      overDueFilter: this.overdueFilter,
      previousCompleteToggleState: this.previousCompleteToggleState,
      filtersState: this.filters
    });

    if(isNeedToReloadPage){
      this.isTodoLoads = true;
    }

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => updateFromGrid(data),
        onError: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.error,
          uniqueKey: data.id
        }),
        onSuccess: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.success,
          uniqueKey: data.id
        }),
      });

      if(isNeedToReloadPage){
        yield this.getTasks();

        this.table.checkAndSetIfPageOutOfRange();
      } else {
        this.table.updateItemById(data.id, data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isTodoLoads = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *bookmark(data: Array<ItemWithId>) {
    try {
      yield massFlagUpdate(data);
      data.forEach((item) => {
        this.table.updateItemById(item.id, { flagged: Boolean(item.flagged) });
      });
      if(this.table.globalFlagged) {
        this.getTasksWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  createOnCategoryFilterChangeReaction(){
    return reaction(
      () => this.categoryFilter,
      () => {
        if(this.isPageActive) {
          this.onFiltersChange();
        }
      }
    );
  }

  createOnCurrentTabChangeReaction(){
    return reaction(
      () => this.currentTab,
      () => {
        this.onFiltersChangeReaction();

        this.filters = this.currentTab === TO_DO_TABS.clientSpecificTasks ?
          INIT_CLIENT_SPECIFIC_FILTERS_STATE : 
          INIT_OTHER_FILTERS_STATE;

        this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
      }
    );
  }

  createOnFiltersChangeReaction(){
    return reaction(
      () => this.filters,
      () => {
        if(this.isPageActive){
          debounce(() => {
            this.onFiltersChange();
          }, 1500)();
        }
      }
    );
  }

  createOnOverdueFilterChangeReaction(){
    return reaction(
      () => this.overdueFilter.type,
      () => {
        if(this.isPageActive) {
          this.onFiltersChange();
        }
      }
    );
  }

  onFiltersChange() {
    this.table.setCurrentPageWithoutReaction(1);
    this.getTasksWithLoad();
  }

  setPreviousCompleteToggleState(state: boolean){
    this.previousCompleteToggleState = state;
  }

  setCategoryFilter(newCategory: CategoryFilter | null) {
    this.categoryFilter = newCategory;
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setOverdueFilter(type: string, params?: {[x: string]: string | boolean}) {
    this.overdueFilter = {
      type,
      params,
    };
  }

  setCurrentTab = (newTab: string) => {
    this.currentTab = newTab;
  };

  toggleFiltersIsOpen = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };

  resetFilters() {
    this.filters = this.currentTab === TO_DO_TABS.clientSpecificTasks ?
      INIT_CLIENT_SPECIFIC_FILTERS_STATE : 
      INIT_OTHER_FILTERS_STATE;
    this.previousCompleteToggleState = false;
  }

  resetStore() {
    this.isTodoLoads = true;
    this.todoPopupSettings = {} as TodoPopupSettings;

    this.isPageActive = false;

    this.categoryFilter = null;
    this.filtersData = {} as FiltersData;
    this.overdueFilter = INIT_OVERDUE_FILTER_STATE;
    this.isFiltersOpen = false;
    this.resetFilters();

    this.table.resetTable();
  }
}

export default ToDosStore;
