import React, { useCallback } from 'react';
import format from 'date-fns/format';

import { useStore } from '@store';

import { OutOfSightActivity } from '@components/OutOfSight';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import TextEllipsis from '@components/TextEllipsis';

import { TimeBlockIcon } from '@/assets';

import { CALENDAR_TOOLTIP_DYNAMIC_PROP } from '@pages/NewCalendar/data';
import { EVENT_TYPES } from '@constants/colorsByEventTypes';
import { MODAL_TYPE } from '@constants/modalTypes';
import { TIME_FORMAT } from '@constants/dateFormats';

import {
  EventInnerWrapper,
  StyledTooltip
} from '..';

import { IconWrapper, Wrapper } from './styles';


export const MonthEventWrapper = ({ event }) => {
  const {
    title,
    start,
    resource: {
      editAllow,
      statusLabel,
      type,
    }
  } = event;
  const isWithStatus =  statusLabel && type !== EVENT_TYPES.timeBlock;
  const content = type === EVENT_TYPES.holiday
    ? title
    :`${isWithStatus ? `[${statusLabel}] `: ''}${format(start, TIME_FORMAT)} - ${title}`;

  const isBlocked = typeof editAllow === 'number' && !editAllow && type !== EVENT_TYPES.holiday;

  const modalStore = useStore().ModalStore;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);
  
  return (
    <EventInnerWrapper type={ type }>
      <StyledTooltip
        arrow
        displayOnOverFlow={ true }
        title={ content }
      >
        <Wrapper>
          {
            type === EVENT_TYPES.timeBlock &&
            <IconWrapper>
              <TimeBlockIcon/>
            </IconWrapper>
          }
          <TextEllipsis { ...CALENDAR_TOOLTIP_DYNAMIC_PROP }>
            { content }
          </TextEllipsis>
        </Wrapper>
      </StyledTooltip>
      {
        isBlocked &&
        <OutOfSightActivity onClick={ onOutOfSightClick } $marginLeft/>
      }
    </EventInnerWrapper>
  );
};
