import { isBefore, parse, startOfToday } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

const NEXT_APPOINTMENT_DATE_LABEL = 'Next Appt Date';
const LAST_APPOINTMENT_DATE_LABEL = 'Last Appt Date';

export const getAppointmentDateLabel = (nextAppointmentDate: string | null) => {
  if(nextAppointmentDate) {
    const isBeforeToday = isBefore(parse(nextAppointmentDate, YEAR_MONTH_DAY, new Date()), startOfToday());
    return isBeforeToday ? LAST_APPOINTMENT_DATE_LABEL : NEXT_APPOINTMENT_DATE_LABEL;
  }
  return NEXT_APPOINTMENT_DATE_LABEL;
};
