import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { debounce, isEmpty } from 'lodash';

import { Store } from '@store';
import CommonTableStore from '@services/store/commonTableStore';

import { getFilterParams, getFiltersCount, getGlobalFlagged, getMultipleSortParams, } from '@/shared/utils/filterUtils';
import { getDeleteOrRestoreItemsParams } from '@/shared/utils/getDeleteOrRestoreItemsParams';

import { ENTITY_NAMES } from '@constants/common';
import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';

import {
  deleteAccountAndPlans,
  getAccountAndPlans,
  saveAccountOrPoliceOrPlan,
  updateAccountAndPlans,
  updateFlags
} from '@services/api/accountAndPlans/accountAndPlans';
import { accountAndPlansNormalizer } from './normalizers/accountAndPlansNormalizer';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { filterContactDataItemsByPermission } from '@/shared/utils/filterContactDataItemsByPermission';

import {
  AccountAndPlanItemShort,
  AccountAndPlansResponseType,
  Filters,
  FiltersData,
  TypeFilter
} from '@/shared/types/accountsAndPlan';
import { IdType, ItemWithId } from '@/shared/types/commonTypes';
import { SubmitData } from '@modules/AccountsAndPlansPopup/types';
import { NOTIFICATION_TYPES } from '@constants/notifications';


class AccountsAndPlansStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  filters: Filters | {} = {};
  filtersData: FiltersData | {} = {};
  isLoad: boolean = true;
  isPageActive = false;
  notificationHelper: NotificationHelper;
  onFiltersChangeReaction: IReactionDisposer;
  onTypeFilterReaction: IReactionDisposer;
  table: CommonTableStore<AccountAndPlanItemShort>;
  typeFilter: TypeFilter = null;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getAccountAndPlansItems: flow.bound,
      getAccountAndPlansItemsWithLoad: flow.bound,
      initPage: flow.bound,
      removeAccountsAndPlans: flow.bound,
    });

    this.coreStore = coreStore;

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.account
    );

    this.table = new CommonTableStore<AccountAndPlanItemShort>({
      checkboxItemsProcessor: filterContactDataItemsByPermission,
      onGlobalFlaggedChangeReactionCallback: this.getAccountAndPlansItemsWithLoad,
      onPageChangeReactionCallback: this.getAccountAndPlansItemsWithLoad,
      onSortReactionCallback: this.getAccountAndPlansItemsWithLoad,
    });

    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onTypeFilterReaction = this.createOnTypeFilterReaction();
  }

  get selectedFiltersCount() {
    return getFiltersCount(this.filters);
  }

  *getAccountAndPlansItems() {
    if (!this.isPageActive) {
      return;
    }

    this.table.clearItems(true);
    const response: AccountAndPlansResponseType = yield getAccountAndPlans({
      page: this.table.currentPage,
      ...getFilterParams(this.filters),
      ...getFilterParams(this.typeFilter),
      ...getMultipleSortParams(this.table.multipleSorting),
      ...getGlobalFlagged(this.table.globalFlagged),
    });
    this.table.setPaginationData(response.data.data);
    this.table.checkAndSetIfPageOutOfRange();

    const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
    const { accountAndPlansItems, filtersData } = accountAndPlansNormalizer(
      response,
      currentUserId
    );
    this.table.items = accountAndPlansItems;

    if (isEmpty(this.filtersData)) {
      this.filtersData = filtersData;
    }
  }

  *getAccountAndPlansItemsWithLoad() {
    if (!this.isPageActive) {
      return;
    }

    this.setIsLoad(true);
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => this.getAccountAndPlansItems(),
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoad(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *initPage() {
    this.isPageActive = true;
    yield this.getAccountAndPlansItemsWithLoad();
  }

  *removeAccountsAndPlans(arrayOfIds: Array<IdType>) {
    this.setIsLoad(true);
    try {
      const params = getDeleteOrRestoreItemsParams(arrayOfIds);

      const countOfEntities = arrayOfIds.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteAccountAndPlans(params),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          otherEntityName: ENTITY_NAMES.accountAndPlans,
          countOfEntities,
        }),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          otherEntityName: ENTITY_NAMES.accountAndPlans,
          countOfEntities
        }),
      });

      yield this.getAccountAndPlansItems();

      this.table.checkAndSetIfPageOutOfRange();
      this.table.refreshSelectedIds(arrayOfIds);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoad(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveAccountsAndPlansBy(data: SubmitData) {
    this.setIsLoad(true);
    try {
      const otherEntityName = this.getEntityName(data.accountType);

      if(data.id){
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateAccountAndPlans(data),
          onError: () => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.error,
            otherEntityName
          }),
          onSuccess: () => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.success,
            otherEntityName
          })
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveAccountOrPoliceOrPlan(data),
          onError: () => this.notificationHelper.create({
            status: NOTIFICATION_TYPES.error,
            otherEntityName
          }),
          onSuccess: () => this.notificationHelper.create({
            status: NOTIFICATION_TYPES.success,
            otherEntityName
          })
        });
      }
      yield this.getAccountAndPlansItemsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoad(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFlags(arrOfIds: Array<ItemWithId>, state: boolean) {
    try {
      const params = getDataForBookmark(arrOfIds, state);
      yield updateFlags(params);
      arrOfIds.forEach(item => {
        this.table.updateItemById(item.id, { flagged: state });
      });
      if(this.table.globalFlagged) {
        this.getAccountAndPlansItemsWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if (this.isPageActive) {
          this.table.currentPage = 1;
          this.getAccountAndPlansItemsWithLoad();
        }
      }, 1500),
    );
  }

  createOnTypeFilterReaction() {
    return reaction(
      () => this.typeFilter,
      () => {
        if (this.isPageActive) {
          this.table.setCurrentPageWithoutReaction(1);
          this.getAccountAndPlansItemsWithLoad();
        }
      }
    );
  }

  resetFilters() {
    this.filters = {};
    this.typeFilter = null;
  }

  resetState() {
    this.isPageActive = false;
    this.resetFilters();
    this.table.resetTable();
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setIsLoad(state: boolean) {
    this.isLoad = state;
  }

  setTypeFilter(value: TypeFilter) {
    this.typeFilter = value;
  }

  private getEntityName(entity: ACCOUNTS_AND_PLANS_TYPES) {
    switch (entity) {
    case ACCOUNTS_AND_PLANS_TYPES.ACCOUNT:
      return ENTITY_NAMES.account;
    case ACCOUNTS_AND_PLANS_TYPES.POLICY:
      return ENTITY_NAMES.policy;
    case ACCOUNTS_AND_PLANS_TYPES.PLAN:
      return ENTITY_NAMES.plan;
    default:
      console.log('Other variant');
      break;
    }
  }
}

export default AccountsAndPlansStore;
