import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { ComponentHeader } from '@modules/ContactComponents';
import { PendingTasksRow } from './components';
import { TodoPopup } from '@modules/TodoPopup';
import Table from '@components/Table';

import { useStore } from '@store';

import { COMMON_COLUMNS, WITH_COMPLETED_TASKS_COLUMNS } from './data';
import { MODAL_TYPE } from '@constants/modalTypes';

import { ToDosWrapper, TextChunk, MarkedTextChunk, Content } from './styles';

import { IdType } from '@/shared/types/commonTypes';
import { PendingTasksTableProps } from './types';
import { TodoItem } from '@/shared/types/todos';


export const PendingTasks = observer(({
  $tableHeight,
  headerTitle,
  isLoading,
  isShowCompletedTasks = true,
  isShowViewAllButton,
  onDeleteTask,
  onScrollToBottomAction = () => {},
  onUpdateTask,
  onViewAllClick,
  table,
}: PendingTasksTableProps) => {
  const modalStore = useStore().ModalStore;

  const onEditToDo = useCallback((id: IdType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        initLinkedContact: null,
        saveCallback: onUpdateTask,
        deleteCallback: onDeleteTask,
      },
      component: TodoPopup
    });
  }, [ modalStore, onDeleteTask, onUpdateTask ]);

  return (
    <ToDosWrapper>
      <ComponentHeader
        isShowViewAllButton={ isShowViewAllButton }
        onViewAllClick={ onViewAllClick }
        title={ headerTitle }
      />
      <Table>
        <Table.Container $height={ $tableHeight } onScrollToBottomAction={ onScrollToBottomAction }>
          <Table.Header
            columns={ isShowCompletedTasks ? WITH_COMPLETED_TASKS_COLUMNS : COMMON_COLUMNS }
          />
          <Table.Body>
            { !isLoading && table.length > 0 &&
              table.map((item: TodoItem) => (
                <PendingTasksRow
                  data={ item }
                  isShowCompletedTasks={ isShowCompletedTasks }
                  key={ item.id }
                  onEditTodo={ onEditToDo }
                />
              ))
            }
          </Table.Body>
        </Table.Container>
      </Table>
      { table.length === 0 &&
        <Content>
          <TextChunk>NO PENDING ITEMS </TextChunk>
          <MarkedTextChunk>TO VIEW</MarkedTextChunk>
        </Content>
      }
    </ToDosWrapper>
  );
});
