import { format } from 'date-fns';

import { convertToTwelveFormat } from '@/shared/utils/timeConverter';
import { convertUTCFormatToString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { getMeridian } from './utils';
import { getUserOption } from '@/shared/utils/filterUtils';
import { MONTH_DAY_YEAR_BACKSLASH } from '@/shared/constants/dateFormats';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { Client, IdType } from '@/shared/types/commonTypes';
import {
  ContactActivity,
  ContactActivityData,
  ContactActivityFiltersNames,
  FilterData,
  FilterDataConverter
} from './types';

const itemConverter = (item: ContactActivity): ContactActivity => {
  const startDateAndTime = {
    startDate: item.startDate,
    startTime: item.startTime,
  };

  if(!yesNoToBoolean(item.allDay)) {
    const { date, time } = convertUTCFormatToString(item.startDate, item.startTime);
    startDateAndTime.startDate = date;
    startDateAndTime.startTime = time;
  }

  return ({
    ...item,
    recurring: yesNoToBoolean(item.recurring),
    startDate: format(new Date(startDateAndTime.startDate), MONTH_DAY_YEAR_BACKSLASH),
    startTime: `${convertToTwelveFormat(startDateAndTime.startTime, false)} ${getMeridian(startDateAndTime.startTime)}`
  });
};

export const itemsNormalizer = (
  response: ContactActivityData,
  contactId: IdType,
): {
  filterData: FilterDataConverter,
  items: Array<ContactActivity>,
} => {
  const { filterData, ...activitiesItems } = response;

  const items = Object.values(activitiesItems) as Array<ContactActivity>;

  return {
    filterData: filterDataConverter(filterData, contactId),
    items: items.map(itemConverter),
  };
};

const filterDataConverter = (
  filterData: FilterData, contactId: IdType
): FilterDataConverter => {
  if (!filterData) {
    return {} as any;
  }

  return {
    [ContactActivityFiltersNames.Clients]: clientsFilterData(filterData, contactId),
  };
};

const clientsFilterData = (filterData: FilterData, currentUserId: IdType) => {
  return filterData.clients.map((userObj: Client) => (
    getUserOption({
      userObj,
      currentUserId,
    })
  ));
};