import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  LEFT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { MiscInfoProps } from './types';

export const MiscInfo = ({
  petsName,
}: MiscInfoProps) => {
  const { control } = useFormContext();
  
  return (
    <FormBlock
      isBorderBottom={ false }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Misc. Information' }/>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          label={ 'Pets' }>
          <HFTextInput
            inputProps={ {
              maxLength: 50,
              placeholder: 'Enter Pets Information'
            } }
            control={ control }
            name={ petsName }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};
