import React, { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { AddField } from '../AddField';
import { FieldsFactory } from '../FieldsFactory';
import { FieldsFactoryWrapper } from '../FieldsFactoryWrapper';
import FormBlock from '@components/FormBlock';

import { DEFAULT_CONFIG, KEY_NAME, DETAILS_DATA_MAP } from '../../data';
import { FIELD_IDS, GROUP_FIELDS_IDS } from '@constants/contactsData';
import {
  LEFT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { getHouseHoldPrimaryName } from '@/shared/utils/getPrimaryName';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { ForceField, ForceFieldsType, HouseholdContactDetailsWrapperProps } from '../../types';


export const HouseholdContactDetailsWrapper = ({
  config = DEFAULT_CONFIG,
  headOfHouseholdOptions,
  householdMembers,
  labelWidth = LEFT_COLUMN_WIDTH,
  parentNames,
  title = 'Contact Details',
}: HouseholdContactDetailsWrapperProps) => {

  const { control, setValue, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: KEY_NAME,
    name: parentNames[0],
  });

  const onAdd = useCallback((index: number) => {
    append(DETAILS_DATA_MAP[index as FIELD_IDS].defaultValues);
  }, [append]);

  const onDeleteClick = useCallback((value: number) => {
    remove(value);
  }, [remove]);

  const allFields = useWatch({
    control,
    name: parentNames
  });

  const onPrimaryClick = useCallback((parentName: string, index: number) => {
    const field = getValues(`${parentName}.${index}`);
    const nameOfField = getHouseHoldPrimaryName(parentName, index);

    const contactDetailsGroup = GROUP_FIELDS_IDS.filter(group => {
      if(group.find(id => id === field.fieldId)) {
        return group;
      }
    })[0];

    const allGroupedFields = [] as Array<ForceField>;
    allFields.forEach((fields, parentNamesIndex) => {
      const groupField = fields.filter((field: ForceField, index: number) => {
        const isExistField = contactDetailsGroup.includes(field.fieldId);
        if(isExistField) {
          if(parentNamesIndex === 0) {
            field.nameOfField = getHouseHoldPrimaryName(parentNames[parentNamesIndex], index);
          } else {
            field.nameOfField = getHouseHoldPrimaryName(parentNames[parentNamesIndex], index);
          }
        }
        return isExistField;
      });
      allGroupedFields.push(...groupField);
    });
    
    allGroupedFields.forEach((field: ForceField) => {
      if(yesNoToBoolean(field.primaryToHousehold)) {
        setValue(field.nameOfField, booleanToYesNo(false));
      }
    });
    setValue(nameOfField, booleanToYesNo(true), { shouldDirty: true });
  }, [allFields, setValue, getValues]);

  if(householdMembers.length === 0){
    return null;
  }

  return (
    <FormBlock
      isBorderBottom={ true }
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ title }>
        <AddField
          config={ config }
          onOptionClick={ onAdd }
        />
      </FormBlock.Header>
      <FieldsFactory
        fields={ fields as ForceFieldsType }
        headOfHouseholdOptions={ headOfHouseholdOptions }
        householdMembers={ householdMembers }
        isHousehold={ true }
        labelWidth={ labelWidth }
        onDeleteClick={ onDeleteClick }
        onPrimaryClick={ onPrimaryClick }
        parentName={ parentNames[0] }
      />
      <FieldsFactoryWrapper
        headOfHouseholdOptions={ headOfHouseholdOptions }
        householdMembers={ householdMembers }
        onPrimaryClick={ onPrimaryClick }
        parentName={ parentNames[1] }
      />
      <FieldsFactoryWrapper
        headOfHouseholdOptions={ headOfHouseholdOptions }
        householdMembers={ householdMembers }
        onPrimaryClick={ onPrimaryClick }
        parentName={ parentNames[2] }
      />
    </FormBlock>
  );
};
