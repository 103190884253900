import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { ShakeHands } from '@/assets';

import { useStore } from '@store';

import { OutOfSightTD } from '@components/OutOfSight';
import DotsMenu from '@components/DotsMenu';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import LinkedContactCell from '@modules/LinkedContactCell';
import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import SalesPipelineNextApptDateCellContent from '@modules/SalesPipline/SalesPipelineNextApptDateCellContent';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import Tooltip from '@components/Tooltip/Tooltip';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import {
  OPPORTUNITY_FIELD_NAMES,
  OPPORTUNITY_MEETING_STATUS_OPTIONS,
  OPPORTUNITY_SALE_TYPES_OPTIONS,
  OPPORTUNITY_STATUS_OPTIONS,
} from '@constants/salesCycleOpportunity';
import { CLIENT_OPPORTUNITY_DOTS_MENU_TYPES, CLIENT_OPPORTUNITY_DOTS_MENU_OPTIONS } from './data';
import { MODAL_TYPE } from '@constants/modalTypes';

import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';
import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import { ClientOpportunityRowFormState } from '@/shared/types/salesCycleOpportunity';
import { SalesClientOpportunitiesRowProps, SalesClientOpportunityItemDotsMenuTypes } from './types';

import { OnboardingWrapper, SelectsWrapper } from './styles';


export const SalesClientOpportunitiesRow = ({
  item,
  onChange,
  onDoubleClick,
  onRemove,
  onCheckboxClick,
  onBookmark,
  onEdit,
  isChecked
}: SalesClientOpportunitiesRowProps) => {
  const modalStore = useStore().ModalStore;

  const {
    control,
    reset,
    formState,
    getValues
  } = useForm<ClientOpportunityRowFormState>({
    defaultValues: {
      [OPPORTUNITY_FIELD_NAMES.id]: item.id,
      [OPPORTUNITY_FIELD_NAMES.saleType]: item.saleType,
      [OPPORTUNITY_FIELD_NAMES.opportunityStatus]: item.opportunityStatus,
      [OPPORTUNITY_FIELD_NAMES.meetingStatus]: item.meetingStatus,
    }
  });

  const allFields = useWatch({ control });

  const isOnBoarding = yesNoToBoolean(item.onboarding);
  const amountConverted = useMemo(() => formatNumberToAmount(item?.amount), [item?.amount]);

  const onCheckboxClickWrapper = useCallback(() => onCheckboxClick(item),[onCheckboxClick, item]);
  const onRowDoubleClick = useCallback(() => onDoubleClick(item.id), [item.id, onDoubleClick]);
  const onItemBookmarkWrapper = useCallback(() => onBookmark(item.id), [item.id, onBookmark]);

  const onMenuSelect = useCallback((action: SalesClientOpportunityItemDotsMenuTypes) => {
    if(action === CLIENT_OPPORTUNITY_DOTS_MENU_TYPES.edit) {
      onEdit(item.id);
    }

    if(action === CLIENT_OPPORTUNITY_DOTS_MENU_TYPES.remove) {
      onRemove(item.id);
    }
  }, [item.id, onEdit, onRemove]);

  const isBlocked = typeof item?.contactData?.editAllow === 'number' && !item?.contactData?.editAllow;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  useEffect(() => {
    if(Object.keys(formState.dirtyFields).length > 0){
      const values = getValues();
      onChange(values,reset);
    }
  },[formState.dirtyFields, allFields, getValues, item, reset, onChange]);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      onDoubleClick={ onRowDoubleClick }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: Boolean(item.flagged),
          onStateChange: onItemBookmarkWrapper
        } }
        checkboxProps={ {
          checked: isChecked,
          onChange: onCheckboxClickWrapper
        } }
      />
      <LinkedContactCell
        contact={ item.contactData }
        tab={ CONTACT_DETAILS_TAB.profile }
      >
        {
          isOnBoarding &&
          <OnboardingWrapper>
            <Tooltip title={ 'Onboarding' }>
              <ShakeHands/>
            </Tooltip>
          </OnboardingWrapper>
        }
      </LinkedContactCell>
      <HFHiddenInput
        control={ control }
        defaultValue={ item.id }
        name={ OPPORTUNITY_FIELD_NAMES.id }
      />
      <Table.Cell>
        <SelectsWrapper>
          <HFSelect
            control={ control }
            name={ OPPORTUNITY_FIELD_NAMES.saleType }
            options={ OPPORTUNITY_SALE_TYPES_OPTIONS }
            placeholder={ 'Please Select' }
            resettable={ true }
            showError={ false }
          />
        </SelectsWrapper>
      </Table.Cell>
      <Table.Cell>
        <SelectsWrapper>
          <HFSelect
            control={ control }
            name={ OPPORTUNITY_FIELD_NAMES.opportunityStatus }
            options={ OPPORTUNITY_STATUS_OPTIONS }
            placeholder={ 'Please Select' }
            renderItem={ SalesClientOpportunitiesStatusOption }
            resettable={ true }
            showError={ false }
          />
        </SelectsWrapper>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ item.stage }/>
      </Table.Cell>
      <Table.Cell>
        {
          item.nextAppointmentDate &&
          <SalesPipelineNextApptDateCellContent date={ item?.nextAppointmentDate }/>
        }
        {
          !item.nextAppointmentDate &&
          <SelectsWrapper>
            <HFSelect
              control={ control }
              name={ OPPORTUNITY_FIELD_NAMES.meetingStatus }
              options={ OPPORTUNITY_MEETING_STATUS_OPTIONS }
              placeholder={ 'Please Select' }
              resettable={ true }
              showError={ false }
            />
          </SelectsWrapper>
        }
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ amountConverted }/>
      </Table.Cell>
      <Table.Cell>
        {item.tasksCount}
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ item.keyNote || '-' }/>
        <DotsMenu<SalesClientOpportunityItemDotsMenuTypes>
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ CLIENT_OPPORTUNITY_DOTS_MENU_OPTIONS }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
      {
        isBlocked && <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
};
