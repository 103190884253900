import { useState, useEffect, useCallback } from 'react';

import { useDebounceValue } from '@customHooks';

import { UseAutoCompleteFetchProps } from './types';


export const useAutoCompleteFetch = <T,>({
  delay = 0,
  getParams = () => ({}),
  initData = [],
  refreshData = false,
  requestSender,
  responseConverter = (resp) => [resp],
  withoutQueryMode = false,
}: UseAutoCompleteFetchProps<T>) => {

  const [ data, setData ] = useState(initData);
  const [ isLoad, setIsLoad ] = useState(false);
  const [ isError, setIsError] = useState(false);
  const [ loadWithoutQuery, setLoadWithoutQuery] = useState(false);
  const [ query, setQuery ] = useState('');
  const debouncedQuery = useDebounceValue(query, delay);
  const [ isNoResults, setIsNoResults ] = useState(false);
  
  const triggerLoadWithoutQuery = useCallback(() => setLoadWithoutQuery(true), []);

  const sendRequest = useCallback(async () => {
    setIsLoad(true);
    setIsError(false);
    try {
      const lowerCaseQuery = debouncedQuery.toLowerCase();
      const response = await requestSender(getParams(lowerCaseQuery));

      if (!response.data) {
        setIsNoResults(true);
        return setData(initData);
      }

      const convertedResponse = responseConverter(response);
      setIsNoResults(convertedResponse.length === 0);
      setData(convertedResponse);

    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoad(false);
    }
  }, [debouncedQuery, refreshData, loadWithoutQuery]);

  useEffect(() => {
    if(withoutQueryMode){
      return;
    }
    
    if (!debouncedQuery) {
      setIsNoResults(false);
      return setData(initData);
    }
    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ debouncedQuery ]);

  useEffect(() => {
    if(withoutQueryMode && loadWithoutQuery) {
      const sendRequest = async  () => {
        setIsLoad(true);
        setIsError(false);
        try {
          const lowerCaseQuery = debouncedQuery.toLowerCase();
          const response = await requestSender(refreshData ? getParams(lowerCaseQuery) : {});

          if (!response.data) {
            setIsNoResults(true);
            return setData(initData);
          }

          const convertedResponse = responseConverter(response);
          setIsNoResults(convertedResponse.length === 0);
          setData(convertedResponse);

        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoad(false);
          setLoadWithoutQuery(false);
        }
      };
      sendRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ loadWithoutQuery, debouncedQuery ]);

  useEffect(() => {
    if(withoutQueryMode && refreshData) {
      sendRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ debouncedQuery, refreshData ]);

  return {
    data,
    isError,
    isLoad,
    isNoResults,
    setQuery,
    triggerLoadWithoutQuery
  };
};
