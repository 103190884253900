import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useAddContactPopupStore } from '@modules/AddContactModal/addContactStore';

import FormBlock from '@components/FormBlock';
import { AddContact, LinkContact, Notification } from './components';

import { TextWrapper } from './styles';

import { LinkedContact } from '@/shared/types/linkedContact';

export const PrimaryPoint = observer(() => {

  const store = useAddContactPopupStore();

  const [ isLinkContact, setLinkContact ] = useState<LinkedContact | null>(store.linkedContact);
  const [ isNewContact, setNewContact ] = useState<boolean>(false);

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Primary Point-Of-Contact' }/>

      <Notification/>

      <LinkContact
        disable={ isNewContact }
        setLinkContact={ setLinkContact }
      />

      <TextWrapper>or</TextWrapper>

      <AddContact
        disabled={ Boolean(isLinkContact) }
        setNewContact={ setNewContact }
      />
    </FormBlock>
  );
});

