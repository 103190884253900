import React, { useCallback, useEffect, useState } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';
import CustomSelect from '@components/CustomSelect';
import RadioGroup from '@components/RadioGroup';
import Preloader from '@components/Preloader';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip';

import { ControlsWrapper, LoaderWrapper, RadioButtonsWrapper, SelectWrapper } from './styles';

import { CheckFolderResp, ParentId, TreeItem } from '@/shared/types/lists';
import { DeleteFolderPopupProps } from './types';
import { RenderItemArg } from '@components/CustomSelect/types';
import { ValueLabelObj } from '@/shared/types/commonTypes';

import { checkFolder } from '@services/api/lists/lists';

import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';

import { DELETE_FOLDER_RADIO_BUTTONS, TREE_ROOT_MOCK } from '@constants/lists';


const RenderSelectOption = (data: RenderItemArg) => {
  const valueToRender = !Array.isArray(data) ? data?.label : '';
  return (
    <Tooltip
      displayOnlyOnOverflow={ true }
      title={ valueToRender }
    >
      <TextEllipsis
        $maxWidth={ 250 }
      >
        {valueToRender}
      </TextEllipsis>
    </Tooltip>
  );
};

export const DeleteFolderPopup = ({
  closeModal,
  modalProps
}: DeleteFolderPopupProps) => {
  const { item, onSave } = modalProps;
  const [ isLoad, setIsLoad] = useState<boolean>(true);
  const [ isEmpty, setIsEmpty] = useState(false);

  const [ radioValue, setRadioValue] = useState<string>(DELETE_FOLDER_RADIO_BUTTONS[1].value as string);

  const [ target, setTarget] = useState<string>('');
  const [ targetOptions, setTargetOptions] = useState<Array<ValueLabelObj< number | string>>>([]);
  const [ optionsForIdSearch, setOptionsForIdSearch] = useState<Array<ValueLabelObj<null | number | string>>>([]);

  const message =  isEmpty
    ? 'Are you sure you want to remove this folder?'
    : 'This folder contains lists. What would you like to do with the lists in this folder';

  const onSaveClickWrapper = useCallback(() => {
    closeModal();
    if(isEmpty || radioValue === DELETE_FOLDER_RADIO_BUTTONS[1].value) {
      onSave({
        id: item.id,
      });
    } else {
      const indexOfValue = optionsForIdSearch.findIndex(item => item.label === target);
      if(indexOfValue >= 0){
        onSave({
          id: item.id,
          parentId: optionsForIdSearch[indexOfValue].value as ParentId
        });
      }
    }

  }, [isEmpty, item.id, onSave, optionsForIdSearch, radioValue, target]);

  const init = useCallback(async () => {
    try {
      setIsLoad(true);
      const resp: CheckFolderResp = await checkFolder({ id: item.id });

      const items = convertObjectWithNumericKeysToArray<TreeItem>(resp.data.data.tree);

      setOptionsForIdSearch([
        {
          value: TREE_ROOT_MOCK.id,
          label: TREE_ROOT_MOCK.name
        },
        ...items.map(item => ({
          value: item.id,
          label: item.name
        }))
      ]);

      setTargetOptions([
        {
          value: TREE_ROOT_MOCK.name,
          label: TREE_ROOT_MOCK.name
        },
        ...items.map(item => ({
          value: item.name,
          label: item.name
        }))
      ]);

      setIsEmpty(resp.data.data.empty);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  }, [item.id]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <ConfirmationPopup
      isConfirmationDisabled={ !isEmpty && !target && radioValue === DELETE_FOLDER_RADIO_BUTTONS[0].value }
      isOpen={ true }
      message={ isLoad ? '' : message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onSaveClickWrapper }
    >
      {
        isLoad &&
        <LoaderWrapper>
          <Preloader isWithoutFullPageWrapper={ true }/>
        </LoaderWrapper>
      }
      {
        !isLoad && !isEmpty &&
        <ControlsWrapper>
          <RadioButtonsWrapper>
            {/*@ts-ignore*/}
            <RadioGroup
              onChange={ (e: any, value: string) => setRadioValue(value) }
              options={ DELETE_FOLDER_RADIO_BUTTONS }
              value={ radioValue }
            />
          </RadioButtonsWrapper>
          <SelectWrapper>
            <CustomSelect
              onChange={ (event) => {
                setTarget(event.target.value as string);
              } }
              options={ targetOptions }
              renderItem={ RenderSelectOption }
              value={ target }
            />
          </SelectWrapper>
        </ControlsWrapper>
      }
    </ConfirmationPopup>
  );
};

