import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFRadioGroup from '@modules/HookFormComponents/HFRadioGroup';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { LeftColumnWrapper, RightColumnWrapper } from '..';

import { NAME_TO_USE_RADIO_OPTIONS } from './data';

import { useWithNickname } from './utils';

import { LEFT_COLUMN_WIDTH,  RIGHT_COLUMN_WIDTH } from '../../data/data';

import { NameGenderProps } from './types';

export const NameGender = memo(({
  emailNameToUseName,
  firstNameName,
  genderName,
  genderOptions,
  lastNameName,
  maidenNameName,
  mailNameToUseName,
  middleNameName,
  nicknameName,
  prefixName,
  prefixOptions,
  suffixName,
  suffixOptions,
}: NameGenderProps) => {
  const { control } = useFormContext();

  const { disabled: isEmailToUseDisabled } = useWithNickname({
    boundFieldName: emailNameToUseName,
    nickNameFieldName: nicknameName,
    boundFieldResetValue: NAME_TO_USE_RADIO_OPTIONS[0].value
  });

  const { disabled: isMailToUseDisabled } = useWithNickname({
    boundFieldName: mailNameToUseName,
    nickNameFieldName: nicknameName,
    boundFieldResetValue: NAME_TO_USE_RADIO_OPTIONS[0].value
  });

  return (
    <FormBlock
      isBorderBottom
      marginBottom={ 20 }
      paddingBottom={ 20 }
    >
      <FormBlock.Header title={ 'Name / Gender' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'First Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter First Name'
              } }
              control={ control }
              name={ firstNameName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Middle Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Middle Name'
              } }
              control={ control }
              name={ middleNameName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Last Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Last Name'
              } }
              control={ control }
              name={ lastNameName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Maiden Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Maiden Name'
              } }
              control={ control }
              name={ maidenNameName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Nickname' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Nickname'
              } }
              control={ control }
              name={ nicknameName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Suffix' }
          >
            <HFSelect
              control={ control }
              name={ suffixName }
              options={ suffixOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Prefix' }
          >
            <HFSelect
              control={ control }
              name={ prefixName }
              options={ prefixOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Gender' }
          >
            <HFSelect
              control={ control }
              name={ genderName }
              options={ genderOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Email Name To Use ' }
          >
            <HFRadioGroup
              control={ control }
              disabled={ isEmailToUseDisabled }
              name={ emailNameToUseName }
              options={ NAME_TO_USE_RADIO_OPTIONS }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Mail Name To Use' }
          >
            <HFRadioGroup
              control={ control }
              disabled={ isMailToUseDisabled }
              name={ mailNameToUseName }
              options={ NAME_TO_USE_RADIO_OPTIONS }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});

NameGender.displayName = 'NameGender';
