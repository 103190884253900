import * as yup from 'yup';

import { dateOutputValidation } from '@constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';

import { TODOS_FIELDS_NAMES } from '@constants/todosData';

export const validationSchema = yup.object().shape({
  [TODOS_FIELDS_NAMES.dueDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
});