import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useWatch, useFormContext } from 'react-hook-form';

import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';

import { EMAIL_OPTIONS, LEFT_COLUMN_WIDTH, PHONE_OPTIONS } from '@modules/AddContactModal/data';

import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { AddContactProps } from './types';

const fieldsName = ['3.firstName', '3.lastName', '3.phoneNumber', '3.email'];

export const AddContact = observer(({
  disabled,
  setNewContact,
}: AddContactProps) => {

  const { control, clearErrors, setValue, getValues } = useFormContext();

  const fields = useWatch({
    control,
    name: fieldsName
  });

  useEffect(() => {
    const isDirtyFields = fields.some(Boolean);

    // TODO by default the phone number must be set NULL if only the country code is entered
    const isOnlyCountryCode = getIsOnlyCountryCode(getValues('3.phoneNumber'));

    setNewContact(isDirtyFields && !isOnlyCountryCode);
    setValue('3.newContact', isDirtyFields);
  
    if(!isEmpty(fields) && fields.every(field => field?.length === 0)) {
      clearErrors(fieldsName);
    }
  }, [clearErrors, fields, setNewContact]);

  return (
    <>
      <FormBlock.RowWrapper>
        <HFHiddenInput
          control={ control }
          defaultValue={ false }
          name={ '3.newContact' }
        />

        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          childrenWrapperProps={ {
            style: {
              gap: 15
            }
          } }
          isWrap={ false }
          label={ 'Add Contact' }
        >
          <HFTextInput
            control={ control }
            disabled={ disabled }
            name={ '3.firstName' }
            placeholder={ 'Enter First Name' }
          />
          <HFTextInput
            control={ control }
            disabled={ disabled }
            name={ '3.lastName' }
            placeholder={ 'Enter Last Name' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          childrenWrapperProps={ {
            style: {
              gap: 15,
            }
          } }
          isWrap={ false }
          label={ 'Phone' }
        >
          <HFPhoneField
            control={ control }
            countryListWidth={ 320 }
            disabled={ disabled }
            name={ '3.phoneNumber' }
          />
          <HFSelect
            control={ control }
            disabled={ disabled }
            name={ '3.phoneType' }
            options={ PHONE_OPTIONS }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          childrenWrapperProps={ {
            style: {
              gap: 10,
            }
          } }
          isWrap={ false }
          label={ 'Email' }
        >
          <HFTextInput
            inputProps={ {
              style: {
                width: 363
              }
            } }
            control={ control }
            disabled={ disabled }
            name={ '3.email' }
            placeholder={ 'Enter Email Address' }
          />
          <HFSelect
            control={ control }
            disabled={ disabled }
            name={ '3.emailType' }
            options={ EMAIL_OPTIONS }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </>
  );
});