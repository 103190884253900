import { LINKED_ITEMS_DATA_NAME, LINKED_TYPES } from '@constants/todosData';

export const NOTES_LINKED_ITEMS_IDS = {
  accountId: 'accountId',
  opportunityId: 'opportunityId',
  planId: 'financialPlanId',
  policyId: 'policyId',
  salesPipeLineId: 'salesPipelineId',
  taskId: 'taskId',
} as const;

//@ts-ignore
export const NOTES_LINKED_ITEMS_TYPES_SCHEME: Record<LinkedItemsSchemeType, LinkedItemSchemeType> = {
  [LINKED_ITEMS_DATA_NAME.OPPORTUNITY_DATA]: {
    type: LINKED_TYPES.opportunity,
    id: NOTES_LINKED_ITEMS_IDS.opportunityId,
    dataName: LINKED_ITEMS_DATA_NAME.OPPORTUNITY_DATA
  },
  [LINKED_ITEMS_DATA_NAME.ACCOUNT_DATA]: {
    type: LINKED_TYPES.account,
    id: NOTES_LINKED_ITEMS_IDS.accountId,
    dataName: LINKED_ITEMS_DATA_NAME.ACCOUNT_DATA
  },
  [LINKED_ITEMS_DATA_NAME.PLAN_DATA]: {
    type: LINKED_TYPES.plan,
    id: NOTES_LINKED_ITEMS_IDS.planId,
    dataName: LINKED_ITEMS_DATA_NAME.PLAN_DATA
  },
  [LINKED_ITEMS_DATA_NAME.POLICY_DATA]: {
    type: LINKED_TYPES.policy,
    id: NOTES_LINKED_ITEMS_IDS.policyId,
    dataName: LINKED_ITEMS_DATA_NAME.POLICY_DATA
  },
  [LINKED_ITEMS_DATA_NAME.SALES_PIPELINE_DATA]: {
    type: LINKED_TYPES.salesCycle,
    id: NOTES_LINKED_ITEMS_IDS.salesPipeLineId,
    dataName: LINKED_ITEMS_DATA_NAME.SALES_PIPELINE_DATA
  },
  [LINKED_ITEMS_DATA_NAME.TASK_DATA]: {
    type: LINKED_TYPES.task,
    id: NOTES_LINKED_ITEMS_IDS.taskId,
    dataName: LINKED_ITEMS_DATA_NAME.TASK_DATA
  }
};
