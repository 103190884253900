import React, { useMemo, useCallback, ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import CheckboxDropDown from '@components/CheckboxDropDown';
import SearchField from '@components/SearchField';
import SideFilters from '@modules/SideFilters';

import { addFilterOptions } from '@/shared/utils/filterUtils';

import { CLASSIFICATION, STATUS, TYPE, USERS } from './data';
import { CONTACTS_FILTER_NAMES } from '@pages/NewContacts/subpages/Contacts/subpages/AllCantacts/data';

import { DropDownFilterDataType } from '@/shared/types/commonTypes';

export const Filters = observer(() => {
  const contactsStore = useStore().ContactsStore; //TODO get init value and update via methods from store
  const {
    isFetching,
    selectedFiltersCount,
    filtersData,
    filters,
  } = contactsStore;

  const usersDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      USERS,
      filtersData[CONTACTS_FILTER_NAMES.users]
    )
  ), [filtersData]);

  const classificationDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      CLASSIFICATION,
      filtersData[CONTACTS_FILTER_NAMES.classification]
    )
  ), [filtersData]);

  const typeDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      TYPE,
      filtersData[CONTACTS_FILTER_NAMES.type]
    )
  ), [filtersData]);

  const statusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      STATUS,
      filtersData[CONTACTS_FILTER_NAMES.status]
    )
  ), [filtersData]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    contactsStore.setFilters({
      [fieldName]: newValue,
    });
  }, [contactsStore]);

  const onClearClick = useCallback(() => { contactsStore.resetFilters(); }, [contactsStore]);

  const onNameChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    contactsStore.setFilters({
      [CONTACTS_FILTER_NAMES.name]: value || null,
    });
  }, [contactsStore]);

  return (
    <SideFilters
      count={ selectedFiltersCount }
      isNonePointerEvents={ isFetching }
      onClearClick={ onClearClick }
    >
      <SideFilters.ItemWrapperWithDelimiter>
        <SearchField
          onChange={ onNameChange }
          placeholder={ 'Search Contacts' }
          value={ filters?.[CONTACTS_FILTER_NAMES.name] || '' }
        />
      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[CONTACTS_FILTER_NAMES.users] || [] }
          values={ usersDropDownData.values }
        />
        <CheckboxDropDown
          label={ classificationDropDownData.label }
          name={ classificationDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[CONTACTS_FILTER_NAMES.classification] || [] }
          values={ classificationDropDownData.values }
        />
        <CheckboxDropDown
          label={ typeDropDownData.label }
          name={ typeDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[CONTACTS_FILTER_NAMES.type] || [] }
          values={ typeDropDownData.values }
        />
        <CheckboxDropDown
          label={ statusDropDownData.label }
          name={ statusDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[CONTACTS_FILTER_NAMES.status] || [] }
          values={ statusDropDownData.values }
        />
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
});
