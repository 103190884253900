import styled, { css } from 'styled-components';

import mainTheme from '../../themes/index'; // TODO color constants

export const Wrapper = styled.div`
    display: flex;
`;
export const Item = styled.div`
    ${({ disabled }) => disabled && css `pointer-events: none;`};

    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    margin-right: 5px;
    border-radius: 50%;
    line-height: 1;
    border: 1px solid ${ props => (
    props.isActive
      ? props.activeColor
      : mainTheme.palette.custom.borders
  )};
    background-color: ${ props => (
    props.isActive
      ? props.activeColor
      : mainTheme.palette.primary.contrastText
  )};
    color: ${ props => (
    props.isActive
      ? mainTheme.palette.primary.contrastText
      : mainTheme.palette.custom.borders
  )};
    font-size: 12px;
    text-align: center;


    &:last-child {
        margin-right: 0px;
    }


    &:hover {
        cursor: pointer;
    }

    ${props => (
    props.isActive
      ? ''
      : css`
                &:hover {
                    border: 1px solid ${mainTheme.palette.custom.darkOrange};
                    color: ${mainTheme.palette.custom.darkOrange};
                }
            `
  )}
`;
