import React, { ChangeEvent, memo, useCallback, useLayoutEffect, useMemo } from 'react';

import { useToggle } from '@customHooks';

import CustomSelect from '@components/CustomSelect';
import { ALL_USERS_SELECT_OPTION, NOT_ASSIGN_SELECT_OPTION } from '@constants/common';

import { Wrapper } from './styles';

import { UserSelectFilterProps } from './types';

export const UserSelectFilter = memo(({
  allUserOption = ALL_USERS_SELECT_OPTION,
  closeTrigger,
  notAssignOption = NOT_ASSIGN_SELECT_OPTION,
  onChange,
  usersOptions = [],
  value = [],
  withAllUserOption = true,
  withNotAssignOption = false,
  $maxWidth='160px'
}: UserSelectFilterProps) => {
  const [ isOpen, toggleIsOpen ] = useToggle(false);

  const options = useMemo(() => {
    let acc = [];

    if(withAllUserOption) {
      acc.push(allUserOption);
    }

    if(withNotAssignOption) {
      acc.push(notAssignOption);
    }

    acc = [...acc, ...usersOptions];

    return acc;
  }, [allUserOption, notAssignOption, usersOptions, withAllUserOption, withNotAssignOption]);


  const onUsersChange = useCallback((event: ChangeEvent<{name?: string | undefined, value: unknown}>) => {
    const { target: { value } } = event;
    const valuesArray = value as Array<string>;

    // todo check with withAllUserOption and withNotAssignOption
    const isAllOptionSelectedByUser = valuesArray[valuesArray.length - 1] === ALL_USERS_SELECT_OPTION.value;
    const newValue = isAllOptionSelectedByUser
      ? []
      : valuesArray.filter(val => val !== ALL_USERS_SELECT_OPTION.value);

    onChange(newValue);
  }, [onChange]);

  const onClose = useCallback(() => toggleIsOpen(), [toggleIsOpen]);
  const onOpen = useCallback(() => toggleIsOpen(), [toggleIsOpen]);

  const userValues = useMemo(() => {
    // todo check with withAllUserOption and withNotAssignOption
    // at second part of condition subtract one item, because we have Not Assign option, and it is a not a user
    // it is insert force in it
    if(value?.length === 0 || value?.length - 1  === usersOptions.length){
      return [ALL_USERS_SELECT_OPTION.value];
    }
    return value;
  },[value, usersOptions]);

  useLayoutEffect(() => {
    if(closeTrigger) {
      toggleIsOpen(false);
    }
  },[closeTrigger, toggleIsOpen]);

  return (
    <Wrapper $maxWidth={ $maxWidth }>
      <CustomSelect
        $fontSize={ '12px' }
        MenuProps={ {
          open:isOpen
        } }
        multiple={ true }
        onChange={ onUsersChange }
        onClose={ onClose }
        onOpen={ onOpen }
        options={ options }
        value={ userValues }
      />
    </Wrapper>
  );
});

UserSelectFilter.displayName = 'UserSelectFilter';
