import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useAddUserPopupLocalStore } from '../../store';

import FormBlock from '@/components/FormBlock';
import ListItemWithColorIndicator from '@/components/ListItemWithColorIndicator';
import HFSelect from '@/modules/HookFormComponents/HFSelect';
import HFTextInput from '@/modules/HookFormComponents/HFTextInput';
/* eslint-disable */
import
  ContactVisibilityItem
from '@/pages/NewUserSettings/components/ProfileInformation/ContactVisibilityPermissions/components/ContactVisibilityItem';
/* eslint-enable */

import {
  FORM_BLOCK_MARGIN_BOTTOM,
  LABELS_WIDTH,
  UserProfileFormInputNames,
  USER_STATUS_OPTIONS
} from '@/pages/NewUserSettings/data';
import { Roles, UserStatuses, USER_ROLES_FOR_SEARCH } from '@/shared/constants/common';
import { searchClientsAdvisor } from '@/shared/services/api/common/common';
import { usersResponseConverter, getAdditionalParamsToNameParam } from '@/shared/utils/fetchAutocompleteUtils';
import { userRolesOptions } from '@/pages/NewUserSettings/utils';
import {
  USER_STATUSES_COLOR_MAP
} from '@pages/NewUserSettings/components/Tabs/Users/components/UsersRow/data';

import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';
import { ValueLabelObj } from '@/shared/types/commonTypes';

import { HelperTextWrapper } from '@/pages/NewUserSettings/components/styles';
import { LeftColumnWrapper, RightColumnWrapper } from './styles';


export const AddUser = observer(() => {

  const addUserPopupLocalStore = useAddUserPopupLocalStore();
  const { advisorsList, usersList } = addUserPopupLocalStore;

  const { control } = useFormContext();

  const [ userRole, status ] = useWatch({
    control,
    name: [
      UserProfileFormInputNames.userRole,
      UserProfileFormInputNames.status
    ],
  });

  const getUserRoleHelperText = useMemo(() => {
    switch(userRole) {
    case Roles.Limited:
      return 'User can only view the last four digits of contact social security numbers.';
    case Roles.Standart:
      return 'User can view and edit contact social security numbers.';
    default:
      return '';
    }
  }, [ userRole ]);

  const getUserStatusHelperText = useMemo(() => {
    switch(status) {
    case UserStatuses.Inactive:
      return 'The user can not login';
    default:
      return '';
    }
  }, [ status ]);

  return (
    <>
      <FormBlock marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>
        <FormBlock.Header title={ 'User Information' }/>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabLeftLabelWidth }
              isRequired={ true }
              label={ 'First Name' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter First Name'
                } }
                control={ control }
                name={ UserProfileFormInputNames.firstName }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>

          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabRightLabelWidth }
              isRequired={ true }
              label={ 'Last Name' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter Last Name'
                } }
                control={ control }
                name={ UserProfileFormInputNames.lastName }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>


        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabLeftLabelWidth }
              isRequired={ true }
              label={ 'Email (Username)' }
            >
              <HFTextInput
                inputProps={ {
                  placeholder: 'Enter Email Address'
                } }
                control={ control }
                name={ UserProfileFormInputNames.email }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>

          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabRightLabelWidth }
              isRequired={ true }
              label={ 'User Status' }
            >
              <HFSelect
                control={ control }
                name={ UserProfileFormInputNames.status }
                options={ USER_STATUS_OPTIONS }
                renderItem={ (option) => {
                  const { value, label } = option as ValueLabelObj;
                  return (
                    <ListItemWithColorIndicator
                      indicatorColor={ USER_STATUSES_COLOR_MAP[value] }
                      label={ label }
                    />
                  );
                } }
              />
              <HelperTextWrapper>
                { getUserStatusHelperText }
              </HelperTextWrapper>
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>


        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabLeftLabelWidth }
              isRequired={ true }
              label={ 'User Role' }
            >
              <HFSelect
                control={ control }
                name={ UserProfileFormInputNames.userRole }
                options={ userRolesOptions }
              />
              <HelperTextWrapper>
                { getUserRoleHelperText }
              </HelperTextWrapper>
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>


      <FormBlock isBorderBottom={ false } marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>
        <FormBlock.Header title={ 'Contact Visibility Permissions' }/>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <ContactVisibilityItem<UserProfileType['advisors'], []>
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabLeftLabelWidth }
              FetchProps={ {
                getParams: getAdditionalParamsToNameParam({
                  userRole: USER_ROLES_FOR_SEARCH.advisors,
                  prevSelectedId: ''
                }),
                requestSender: searchClientsAdvisor,
                responseConverter: usersResponseConverter,
              } }
              control={ control }
              data={ advisorsList }
              label={ 'Advisors' }
              name={ UserProfileFormInputNames.advisors }
              placeholder={ 'Start typing to search advisors' }
              setData={ addUserPopupLocalStore.setAdvisorsList }
              showError={ true }
            />
          </LeftColumnWrapper>

          <RightColumnWrapper>
            <ContactVisibilityItem<UserProfileType['clients'], []>
              $labelWidth={ LABELS_WIDTH.addUserPopupAddUserTabRightLabelWidth }
              FetchProps={ {
                getParams: getAdditionalParamsToNameParam({
                  userRole: USER_ROLES_FOR_SEARCH.clients,
                  prevSelectedId: ''
                }),
                requestSender: searchClientsAdvisor,
                responseConverter: usersResponseConverter,
              } }
              control={ control }
              data={ usersList }
              label={ 'Users' }
              name={ UserProfileFormInputNames.clients }
              placeholder={ 'Start typing to search users' }
              setData={ addUserPopupLocalStore.setUsersList }
              showError={ true }
            />
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
});
