import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@/shared/services/store/store';
import { useDidUpdate } from '@/shared/customHooks';
import { getValidationScheme } from '@pages/NewUserSettings/components/Tabs/validationScheme';
import { openDetectLinkTransitionPopup } from '@/shared/utils/modalHelpers';

import RouteGuard from '@modules/RouteGuard';
import FormBlock from '@/components/FormBlock';
import {
  ComplianceInformationEmailSignature,
  ContactVisibilityPermissions,
  GeneralInformation,
  Headshots,
  UserRoleAndStatus
} from '@/pages/NewUserSettings/components/ProfileInformation';
import { NotificationBar } from '@components/NotificationBar';

import { DEFAULT_STATE_VALUE, FORM_BLOCK_MARGIN_BOTTOM, UserProfileFormInputNames } from '@/pages/NewUserSettings/data';

import { CloseIcon, ProfilePanelHeader, WrapperProfilePanel, ProfilePanelBody } from './styles';

import { ProfilePanelProps } from './types';
import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';
import { ClientWithCalendarData } from '@/shared/types/commonTypes';

export const ProfilePanel = observer(({
  onCloseClick,
  isAbleSetPermissions,
}: ProfilePanelProps) => {
  const userSettingsStore = useStore().UserSettingsStore;
  const routerStore = useStore().RouterStore;
  const modalStore = useStore().ModalStore;

  const { profile } = userSettingsStore;

  const memoizedDefaultValues = useMemo(() => {
    if(!profile) {
      return {};
    }
    return {
      addressLine: profile.addressLine,
      addressLine2: profile.addressLine2,
      businessName: profile.businessName,
      city: profile.city,
      compliance: profile.compliance,
      email: profile.email,
      emailVerified: profile.emailVerified,
      emailSignature: profile.emailSignature,
      faxNumber: profile.faxNumber,
      firstName: profile.firstName,
      headshot: profile.headshot,
      id: profile.id,
      lastName: profile.lastName,
      logo: profile.logo,
      phoneNumber: profile.phoneNumber,
      userRole: profile.userRole || '',
      signature: profile.signature,
      state: profile.state || DEFAULT_STATE_VALUE,
      status: profile.status || '',
      title: profile.title,
      website: profile.website,
      zip: profile.zip,
      advisors: profile.advisors,
      clients: profile.clients,
    };
  }, [ profile ]);

  const methods: UseFormReturn<UserProfileType> = useForm<UserProfileType>({
    mode: 'onSubmit',
    defaultValues: memoizedDefaultValues,
    resolver: yupResolver(getValidationScheme(UserProfileFormInputNames)),
  });

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
    setError
  } = methods;

  const [ isShowSaveBar, setShowSaveBar] = useState(false);

  const [ usersData, setUsersData ] = useState(profile?.clients ?? [] as Array<ClientWithCalendarData>);
  const [ advisorsData, setAdvisorsData ] = useState(profile?.advisors ?? [] as Array<ClientWithCalendarData>);

  const allFields = useWatch({ control });

  const title = useMemo(() => {
    return `${profile?.firstName} ${profile?.lastName} Profile`;
  }, [ profile?.firstName, profile?.lastName ]);

  const onSaveClick = (data: UserProfileType) => {
    setShowSaveBar(false);
    userSettingsStore.saveProfile(data, setError);
  };

  const onCancelClick = () => {
    setShowSaveBar(false);
    if(profile) {
      reset(profile);
      setAdvisorsData(profile?.advisors);
      setUsersData(profile?.clients);
    }
  };

  const onCloseClickWrapper = useCallback(() => {
    onCloseClick(false);
  }, [ onCloseClick ]);

  useDidUpdate(() => {
    if(isDirty) {
      setShowSaveBar(true);
    }
  }, [ allFields ]);

  useEffect(() => {
    if(profile) {
      reset(profile);
    }
  }, [ profile, reset ]);

  return (
    <FormProvider { ...methods }>
      <WrapperProfilePanel>
        <RouteGuard
          triggerModalRender={ openDetectLinkTransitionPopup }
          when={ isDirty }
        />
        <ProfilePanelHeader>
          <CloseIcon onClick={ onCloseClickWrapper } />
          <FormBlock>
            <FormBlock.Header
              title={ title }
            />
          </FormBlock>
        </ProfilePanelHeader>

        <ProfilePanelBody>
          <Headshots
            inputNames={ UserProfileFormInputNames }
          />

          <GeneralInformation
            inputNames={ UserProfileFormInputNames }
            profileData={ profile }
            title={ 'User Information' }
          />

          <UserRoleAndStatus
            control={ control }
            inputNames={ UserProfileFormInputNames }
          />

          { isAbleSetPermissions &&
            <ContactVisibilityPermissions
              advisorsData={ advisorsData || [] }
              control={ control }
              errors={ errors }
              inputNames={ UserProfileFormInputNames }
              isBorderBottom={ true }
              setAdvisorsData={ setAdvisorsData }
              setUsersData={ setUsersData }
              usersData={ usersData || [] }
            />
          }

          <ComplianceInformationEmailSignature
            inputNames={ UserProfileFormInputNames }
          />
        </ProfilePanelBody>

        <NotificationBar
          isOpen={ isShowSaveBar }
          onCancelButtonClick={ onCancelClick }
          onConfirmButtonClick={ handleSubmit(onSaveClick) }
        />
      </WrapperProfilePanel>
    </FormProvider>
  );
});
