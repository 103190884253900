import React, { useCallback, useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import CustomCheckbox from '@components/CustomCheckbox';
import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import {
  LeftColumnWrapper,
  RightColumnWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import { getUseFieldArrayPath } from '@/shared/utils/getUseFieldArrayPath';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import {
  OCCUPATIONS_FIELDS_NAMES
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/Occupations/data';
import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import {
  CurrentWrapper,
  CustomRowWrapper,
  FieldsWrapper,
  Header,
  Title,
  UseAsTitleWrapper,
  Wrapper,
} from './styles';

import { OccupationItemProps } from './types';

export const OccupationItem = ({
  field,
  index,
  onCurrentClick,
  onDeleteClick,
  onUseAsTitleClick,
  parentName,
}: OccupationItemProps) => {
  const { control, setValue } = useFormContext();

  const getFieldName = useCallback((chunk: string) => {
    return getUseFieldArrayPath(parentName, index, chunk);
  }, [parentName, index]);

  const valueOfCurrent= useWatch({
    control,
    name: getFieldName(OCCUPATIONS_FIELDS_NAMES.current)
  });

  const onDeleteWrapper = useCallback(() => onDeleteClick(index), [index, onDeleteClick]);
  const onUseAsTitleClickWrapper = useCallback(() => onUseAsTitleClick(index), [index, onUseAsTitleClick]);
  const onCurrentClickWrapper = useCallback(() => onCurrentClick(index), [index, onCurrentClick]);

  useEffect(() => {
    if(yesNoToBoolean(valueOfCurrent)){
      setValue(getFieldName(OCCUPATIONS_FIELDS_NAMES.end), '');
    }
  }, [valueOfCurrent, getFieldName, setValue]);

  return (
    <Wrapper>
      {
        field.id &&
        <HFHiddenInput
          control={ control }
          defaultValue={ field.id }
          name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.id) }
        />
      }
      <Header>
        <Title> {`Occupation ${index + 1}`}</Title>
        <UseAsTitleWrapper>
          <Controller
            name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.useAsTitle) }
            render={ ({ field }) => (
              <CustomCheckbox
                checked={ yesNoToBoolean(field.value) }
                label={ 'Use as Title' }
                onChange={ onUseAsTitleClickWrapper }
              />
            ) }
          />
        </UseAsTitleWrapper>
        <CustomIconButton
          onClick={ onDeleteWrapper }
          type={ ICON_BUTTON_TYPES.close }
        />
      </Header>
      <FieldsWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              isRequired={ true }
              label={ 'Title' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter Title',
                } }
                control={ control }
                name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.title) }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ 'Company Name' }
            >
              <HFTextInput
                inputProps={ {
                  maxLength: 50,
                  placeholder: 'Enter Company Name',
                } }
                control={ control }
                name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.companyName) }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'From' }
            >
              <HFDatePicker
                DatePickerProps={ {
                  disableFuture: true
                } }
                control={ control }
                name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.start) }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ 'To' }
            >
              <HFDatePicker
                control={ control }
                disabled={ yesNoToBoolean(valueOfCurrent) }
                name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.end) }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <CustomRowWrapper>
            <FormBlock.FormField
              $labelHeight={ 0 }
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ ' ' }
            >
              <CurrentWrapper>
                <Controller
                  name={ getFieldName(OCCUPATIONS_FIELDS_NAMES.current) }
                  render={ ({ field }) => (
                    <CustomCheckbox
                      checked={ yesNoToBoolean(field.value) }
                      label={ 'Current' }
                      onChange={ onCurrentClickWrapper }
                    />
                  ) }
                />
              </CurrentWrapper>
            </FormBlock.FormField>
          </CustomRowWrapper>
        </FormBlock.RowWrapper>
      </FieldsWrapper>
    </Wrapper>
  );
};
