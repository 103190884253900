import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';

import { useStore } from '@store';

import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import CustomToggle, { useCustomToggleState } from '@components/CustomToggle';
import CheckboxDropDown from '@components/CheckboxDropDown';
import SideFilters from '@modules/SideFilters';

import { getPriorityColor } from '@/shared/utils/getPriorityColor';
import { addFilterOptions } from '@/shared/utils/filterUtils';

import { TODOS_FILTER_NAMES, INIT_STAGE_FILTER_STATE } from '@constants/todosData';
import { TO_DO_TABS } from '@constants/routes/toDos';
import { CLASSIFICATION, PRIORITY, USERS } from './data';

import { DropDownFilterDataType } from '@/shared/types/commonTypes';
import { InnerWrapper, ToggleWrapper } from './styles';

export const Filters = observer(() => {

  const {
    currentTab,
    filters,
    filtersData,
    previousCompleteToggleState,
    resetFilters,
    selectedFiltersCount,
    setFilters,
    setPreviousCompleteToggleState,
  } = useStore().ToDosStore;

  const { checked, onChange } = useCustomToggleState({
    initValue: previousCompleteToggleState,
    onChangeCb: ({ isChecked }) => {
      const filtersValue = isChecked ? filtersData.stage : INIT_STAGE_FILTER_STATE;

      setPreviousCompleteToggleState(isChecked);
      setFilters({
        [TODOS_FILTER_NAMES.stage]: filtersValue,
      });
    }
  });

  const usersDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(USERS, filtersData[TODOS_FILTER_NAMES.users])
  ), [filtersData]);
  const classificationDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(CLASSIFICATION, filtersData[TODOS_FILTER_NAMES.classification])
  ), [filtersData]);
  const priorityDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(PRIORITY, filtersData[TODOS_FILTER_NAMES.priority])
  ), [filtersData]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    setFilters({
      [fieldName]: newValue,
    });
    // eslint-disable-next-line
  }, []);

  const onClearClick = useCallback(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideFilters
      count={ selectedFiltersCount }
      isNonePointerEvents={ false }
      onClearClick={ onClearClick }
    >
      <ToggleWrapper>
        <CustomToggle
          $labelMargin={ 'auto' }
          checked={ checked }
          label={ 'Show Previously Completed' }
          onChange={ onChange }
          value={ 'Completed' }
        />
      </ToggleWrapper>
      <InnerWrapper>
        <CheckboxDropDown
          label={ classificationDropDownData.label }
          name={ classificationDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.[TODOS_FILTER_NAMES.classification] || [] }
          values={ classificationDropDownData.values }
        />
        { currentTab === TO_DO_TABS.clientSpecificTasks &&
          <CheckboxDropDown
            label={ usersDropDownData.label }
            name={ usersDropDownData.name }
            setState={ onChangeDrop }
            state={ filters?.[TODOS_FILTER_NAMES.users] || [] }
            values={ usersDropDownData.values }
          />
        }
        <CheckboxDropDown
          label={ priorityDropDownData.label }
          name={ priorityDropDownData.name }
          renderItemLabel={ (label, value) => (
            <ListItemWithColorIndicator
              indicatorColor={ getPriorityColor(value) }
              label={ label }
            />
          ) }
          setState={ onChangeDrop }
          state={ filters?.[TODOS_FILTER_NAMES.priority] || [] }
          values={ priorityDropDownData.values }
        />
      </InnerWrapper>
    </SideFilters>
  );
});
