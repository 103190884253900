import React from 'react';

import { ContactsSearch } from './components';
import CustomIconButton, { ICON_BUTTON_TYPES, SIZES } from '@components/CustomIconButton';

import { ActionsWrapper, ChildrenWrapper, ToolbarSearchWrapper, Wrapper } from './styles';

import { AppHeaderProps } from './types';

export const AppHeader = ({
  children,
  $isSticky
}: AppHeaderProps) => {

  return (
    <Wrapper $isSticky={ $isSticky }>
      <ChildrenWrapper>
        { children }
      </ChildrenWrapper>
      <ToolbarSearchWrapper>
        <ContactsSearch />
      </ToolbarSearchWrapper>
      <ActionsWrapper>
        <CustomIconButton
          $size={ SIZES.medium }
          onClick={ () => {} }
          type={ ICON_BUTTON_TYPES.clock }
        />
        <CustomIconButton
          onClick={ () => {} }
          type={ ICON_BUTTON_TYPES.plus }
        />
      </ActionsWrapper>
    </Wrapper>
  );
};
