import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';

import { AddEditFolderProps } from './types';
import { validationMessages } from '@constants/validationMessages';

import { SAVE_FOLDER_DATA_FIELD_NAMES } from './data';
import { TYPE_FIELDS } from '@constants/lists';

export const AddEditFolder = ({
  closeModal,
  modalProps,
}: AddEditFolderProps) => {
  const { saveCallback, editData, parentId, } = modalProps;
  const title = `${ editData?.id ? 'Rename' : 'New'} Folder`;

  const { control, handleSubmit,reset } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      [SAVE_FOLDER_DATA_FIELD_NAMES.name]: ''
    }
  });

  const onSaveClick = useCallback(() => {
    handleSubmit((data) => {
      closeModal();
      saveCallback({
        [SAVE_FOLDER_DATA_FIELD_NAMES.name]: data?.[SAVE_FOLDER_DATA_FIELD_NAMES.name],
        [SAVE_FOLDER_DATA_FIELD_NAMES.typeField]: TYPE_FIELDS.Folder,
        [SAVE_FOLDER_DATA_FIELD_NAMES.parentId]: parentId,
        [SAVE_FOLDER_DATA_FIELD_NAMES.id]: editData?.id || null,
      });
    })();
  }, [closeModal, editData?.id, handleSubmit, parentId, saveCallback]);

  useEffect(() => {
    if(editData?.name){
      reset({
        [SAVE_FOLDER_DATA_FIELD_NAMES.name]: editData.name,
      });
    }
  }, [editData?.name, reset]);

  return (
    <Modal
      onClose={ closeModal }
      title={ title }
    >
      <Modal.Content
        padding={ '30px 30px 15px' }
        width={ '800px' }
      >
        <FormBlock.FormField
          $labelWidth={ 100 }
          isRequired={ true }
          label={ 'Folder Name' }
        >
          <HFTextInput
            ControllerProps={ {
              rules: {
                required: validationMessages.required
              }
            } }
            inputProps={ {
              maxLength: 150
            } }
            control={ control }
            name={ SAVE_FOLDER_DATA_FIELD_NAMES.name }
            placeholder={ 'Enter Folder Name ' }
          />
        </FormBlock.FormField>
      </Modal.Content>
      <Modal.Footer>
        <PopupActionsButtons
          onCancelClick={ closeModal }
          onSaveClick={ onSaveClick }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
};
