import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import {
  LINKED_ITEMS_DATA_NAME,
  LINKED_ITEMS_IDS,
  LINKED_ITEMS_TYPES_SCHEME,
  TODOS_FIELDS_NAMES,
  YES_NO_TO_BOOLEAN_REQUIRRING_FIELD
} from '@constants/todosData';

import { TodoPopupSettings, TaskPopupSettingsResponseData, TodoItem } from '@/shared/types/todos';
import { NoteTagItem } from '@/shared/types/tags';
import { ValueOf } from '../types/commonTypes';

export const todoPopupSettingsNormalizer = (data: TaskPopupSettingsResponseData): TodoPopupSettings => ({
  priority: optionsFromValue(data.priority),
  stage: optionsFromValue(data.stage),
  category: optionsFromValue(data.category),
  recurringFrequency: optionsFromValue(data.recurringFrequency),
});

export const todoItemNormalizer = (data: TodoItem): TodoItem => (
  Object.entries(data).reduce((acc, [name, value]) => {

    if(YES_NO_TO_BOOLEAN_REQUIRRING_FIELD.includes((name))) {
      acc[name] = yesNoToBoolean(value);
      return acc;
    }

    if(Object.values(LINKED_ITEMS_DATA_NAME).includes(name as ValueOf<typeof LINKED_ITEMS_DATA_NAME>) && value) {
      //@ts-ignore
      const data = name === LINKED_ITEMS_DATA_NAME.CONTACT_DATA ? value : value.contactData;
      const id = acc[LINKED_ITEMS_IDS.accountId] || acc[LINKED_ITEMS_IDS.linkedContactId] ||
        acc[LINKED_ITEMS_IDS.opportunityId] || acc[LINKED_ITEMS_IDS.salesPipeLineId];

      acc[TODOS_FIELDS_NAMES.linkedItemId] = id;
      acc[TODOS_FIELDS_NAMES.linkedItemData] = {
        //@ts-ignore
        ...value,
        linkedContactType: LINKED_ITEMS_TYPES_SCHEME[name as ValueOf<typeof LINKED_ITEMS_DATA_NAME>].type,
        ...data,
        // TODO: unify Linked Item data
        contactData: data,
        id,
      };
      return acc;
    }

    acc[name] = value;
    return acc;
  }, {} as Record<string, unknown>) as TodoItem
);

export const tagNormalizer = (tags: Array<NoteTagItem>): Array<NoteTagItem> => {
  if(!tags) {
    return [];
  }

  return tags.map(tag => ({
    id: tag?.id,
    value: tag.value
  }));
};
