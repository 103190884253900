import { parse, format } from 'date-fns';

import { COLORS } from '@constants/colors';
import { HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

export const getFormats =  () => ({
  timeGutterFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'h:mm aaa', culture) : '',
  dayFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'EEEE, MMM dd', culture) : '',
  weekdayFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'EEEE', culture) : '',
  dayHeaderFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'EEEE, LLLL dd, uuuu', culture) : '',
  dayRangeHeaderFormat: ({ start, end }, culture, calendarLocalizer) =>
    calendarLocalizer
      ? `${calendarLocalizer.format(start, 'MMMM dd', culture)} - ${calendarLocalizer.format(end, 'MMMM dd', culture)}`
      : '',
});

export const getCustomSlotProp = (timeSlot, workingStartTime, workingEndTime) => {
  const startWorkingDate = parse(workingStartTime, HOURS_MINUTES_SECONDS, new Date());
  const endWorkingDate = parse(workingEndTime, HOURS_MINUTES_SECONDS, new Date());
  const formattedTimeSlot = format(timeSlot, HOURS_MINUTES_SECONDS);
  const parsedTimeSlot = parse(formattedTimeSlot, HOURS_MINUTES_SECONDS, new Date());

  if(parsedTimeSlot < startWorkingDate || parsedTimeSlot > endWorkingDate) {
    return  {
      className: 'colored-slot',
      style: {
        color: COLORS.grey,
      },
    };  
  } else {
    return {};
  }
};
