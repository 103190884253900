import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { Trash, UserPlus } from '@/assets';

import BlockButton from '@components/BlockButton';
import PreTableWrapper  from '@components/BasicLayoutComponents/PreTableWrapper';
import { UserSelectFilter } from '@modules/UserSelectFilter';

import { CONTACTS_FILTER_NAMES } from '@pages/NewContacts/subpages/Contacts/subpages/AllCantacts/data';

import { ButtonsGroupWrapper, BtnWrapper } from './styles';

import { PreTablePanelProps } from './types';

export const PreTablePanel = observer(({
  onAddContactClick,
  onDeleteContactsClick,
}: PreTablePanelProps) => {
  const contactsStore = useStore().ContactsStore;
  const {
    filters,
    filtersData,
    isFetching,
    table
  } = contactsStore;

  const selectedLength = table.selectedIDs.length;

  const onDeleteContactsClickWrapper = useCallback(() => {
    onDeleteContactsClick(table.selectedIDs);
  }, [table.selectedIDs, onDeleteContactsClick]);

  const onUserFilterChange = useCallback((value: Array<string>) => {
    contactsStore.setFilters({
      [CONTACTS_FILTER_NAMES.users]: value
    });
  }, [ contactsStore ]);

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters,
      } }>
      <UserSelectFilter
        closeTrigger={ isFetching }
        onChange={ onUserFilterChange }
        usersOptions={ filtersData[CONTACTS_FILTER_NAMES.users] }
        value={ filters[CONTACTS_FILTER_NAMES.users] || [] }
        withAllUserOption={ true }
      />

      <ButtonsGroupWrapper>
        {
          selectedLength > 0 && (
            <BtnWrapper>
              <BlockButton
                onClick={ onDeleteContactsClickWrapper }
                startIcon={ <Trash /> }
              >
                {`Delete ${selectedLength} ${selectedLength > 1 ? 'Contacts' : 'Contact'}`}
              </BlockButton>
            </BtnWrapper>
          )
        }
        <BtnWrapper>
          <BlockButton
            onClick={ onAddContactClick }
            startIcon={ <UserPlus /> }
          >
            New Contact
          </BlockButton>
        </BtnWrapper>
      </ButtonsGroupWrapper>
    </PreTableWrapper>
  );
});
