import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { v4 } from 'uuid';

import { useToggle } from '@customHooks';

import { MinusWithCircle, PlusWithCircle } from '@/assets';

import ControlledHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import TagButton, { TAG_BUTTON_TYPES, TAG_BUTTON_VARIANTS } from '@components/TagButton';
import BlockButton from '@components/BlockButton';

import {
  ButtonColumn,
  CurrentTags, CurrentTagsPlusItem,
  CurrentTagsTagItem,
  InnerWrapper,
  PredefinedTags,
  Wrapper
} from './styles';

const TAG_INPUT_STYLES = {
  width: '90px'
};

export const Tags = ({
  control,
  initTags,
  predefinedTags,
  name,
  setValue
}) => {
  const [tags, setTags] = useState(() => (
    initTags.map(tag => {
      return {
        ...tag,
        useFormTagId: v4()
      };
    })
  ));

  const [ isPredefinedOpened, togglePredefinedOpened] = useToggle(false);
  const [ editIndex, setEditIndex ] = useState(null);
  const toggleButtonLabel = isPredefinedOpened ? 'Hide All' : 'View All';
  const ToggleButtonIcon = isPredefinedOpened ? MinusWithCircle : PlusWithCircle;

  const predefinedTagsWithoutSelected = useMemo(() => {
    return predefinedTags.filter(preTag => {
      return tags.findIndex(field => field.id === preTag.id) === -1;
    });
  }, [predefinedTags, tags]);

  const onTagSave = useCallback((tagData, saveEnd) => {
    const existedTag = predefinedTagsWithoutSelected.find(i => i.value.toLowerCase() === tagData.value.toLowerCase());
    const index =  tags.findIndex(tag => tag.useFormTagId === tagData.useFormTagId);
    const dataToSave = existedTag || tagData;
    const newTags = tags.slice();
    newTags[index] = dataToSave;
    setTags(newTags);

    saveEnd();
  }, [tags]);

  const onTagDelete = useCallback((item) => {
    setTags(currTags => currTags.filter(tag => tag?.useFormTagId !== item.useFormTagId));
  }, []);

  const onPredefinedClick = useCallback((e, { tagData }) => {
    setTags(currentTags => ([
      ...currentTags,
      {
        ...tagData,
        useFormTagId: v4()
      }
    ]));
  }, []);

  const onPlusClick = useCallback(() => {
    setTags(currentTags => ([
      ...currentTags,
      {
        id: null,
        value: 'New Tag',
        useFormTagId: v4()
      }
    ]));

  }, []);

  useEffect(() => {
    const onlyUniq = [...new Map(tags.map(item => [item['value'], item])).values()];
    setValue(name, onlyUniq);
  }, [tags]);

  return (
    <Wrapper>
      <InnerWrapper>
        <ControlledHiddenInput
          control={ control }
          defaultValue={ tags }
          name={ name }
        />
        <CurrentTags>
          {
            tags.map((tag, index) => (
              <CurrentTagsTagItem
                $index={ index }
                key={ tag?.useFormTagId }
              >
                <TagButton
                  inputStyle={ TAG_INPUT_STYLES }
                  isNotEditable={ Boolean(tag?.id) }
                  onDeleteCb={ onTagDelete }
                  onEditEnd={ () => setEditIndex(null) }
                  onEditStart={ () => setEditIndex(index) }
                  onSaveCb={ onTagSave }
                  tagData={ tag }
                  type={ TAG_BUTTON_TYPES.edit }
                />
              </CurrentTagsTagItem>
            ))
          }
          <CurrentTagsPlusItem $editIndex={ editIndex }>
            <TagButton
              content={ '+' }
              onClickCb={ onPlusClick }
              type={ TAG_BUTTON_TYPES.button }
            />
          </CurrentTagsPlusItem>
        </CurrentTags>
        <ButtonColumn $tagsLength={ tags.length }>
          <BlockButton
            endIcon={ <ToggleButtonIcon /> }
            onClick={ togglePredefinedOpened }
          >
            {toggleButtonLabel}
          </BlockButton>
        </ButtonColumn>
      </InnerWrapper>
      <PredefinedTags>
        {
          isPredefinedOpened &&
          <>
            {
              predefinedTagsWithoutSelected.map((preTag) => (
                <TagButton
                  $variant={ TAG_BUTTON_VARIANTS.system }
                  content={ preTag.value }
                  key={ preTag.id }
                  onClickCb={ onPredefinedClick }
                  tagData={ preTag }
                  type={ TAG_BUTTON_TYPES.button }
                />
              ))
            }
          </>
        }
      </PredefinedTags>
    </Wrapper>
  );
};
