import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { endOfDay, isBefore } from 'date-fns';

import { useStore } from '@/shared/services/store/store';

import AvatarLine from '@components/AvatarLine';
import { CategoryLabel } from '@components/CategoryLabel';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import Table from '@components/Table';
import UserItem from '@components/NewUserItem';

import { getAvatarData } from '@/shared/utils/getAvatarData';

import { ContactActivitiesRowProps } from './types';
import { DotsMenuItemTypes } from '@components/DotsMenu/types';
import { TypeSelectedRow } from '@/components/Table/components/Row/types';
import { ActivityStatusOptions } from '@/shared/types/activityPopup';
import TextWithTooltip from '@components/TextWithTooltip';

const CLIENTS_LIMIT = 6;
const DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE = 'activity-name';

export const ContactActivitiesRow = observer(({
  data,
  onDeleteActivity,
  onEditActivity,
  onCheckboxClickCb,
  isCheckboxActive,
  onRowBookmarkCb,
}: ContactActivitiesRowProps) => {

  const { activityPopupSettings: { statuses } } = useStore().ContactDetailsActivitiesStore;

  const onBookmarkClick = useCallback((state: boolean) => {
    onRowBookmarkCb(data.id, state);
  }, [ onRowBookmarkCb, data ]);

  const onCheckboxClick = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onCheckboxClickCb(data)(event,checked);
  }, [ onCheckboxClickCb, data ]);

  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.edit, DOTS_MENU_ITEM_TYPES.delete ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    })
  ), []);

  const avatarsData = useMemo(() => {
    const outOfLimit = data.clients.length - CLIENTS_LIMIT;
    return {
      dataToRender: data.clients.slice(0, CLIENTS_LIMIT).map(item => getAvatarData(item)),
      outOfLimit
    };
  },[data.clients]);

  const selectedRow = useMemo(() => {
    const isDateInThePast = isBefore(endOfDay(new Date(data.endDate)), new Date());
    return (
      isDateInThePast ||
      statuses?.find((status) => status.value === data.statusId)?.label === ActivityStatusOptions.Completed
    );
  }, [ data.statusId ]);

  const onMenuSelect = useCallback((action: DotsMenuItemTypes) => {
    if(action === DOTS_MENU_ITEM_TYPES.edit) {
      onEditActivity(data.id);
    }
    if(action === DOTS_MENU_ITEM_TYPES.delete) {
      onDeleteActivity(data.id, data.recurring);
    }
  }, [ onDeleteActivity, data, onEditActivity ]);

  const onEditWrapper = useCallback(() => {
    onEditActivity(data.id);
  }, [data.id, onEditActivity]);
  
  return (
    <Table.Row
      $typeSelectedRow={ TypeSelectedRow.Stripes }
      hover
      role='checkbox'
      selected={ selectedRow }
      tabIndex={ -1 }
      onDoubleClick={ onEditWrapper }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: data.flagged,
          onStateChange: onBookmarkClick,
        } }
        checkboxProps={ {
          checked: isCheckboxActive,
          onChange: onCheckboxClick
        } }
      />
      <Table.Cell
        $paddingLeft={ '0' }
        dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
        onClick={ onEditWrapper }
      >
        <TextWithTooltip
          dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
          text={ data.name }
        />
      </Table.Cell>
      <Table.Cell>
        <UserItem
          innerPadding={ '0px' }
          user={ data.contact }
          withCoreClassification={ true }
        />
      </Table.Cell>
      <Table.Cell>
        { data.startDate }
      </Table.Cell>
      <Table.Cell>
        { data.startTime }
      </Table.Cell>
      <Table.Cell>
        <CategoryLabel
          label={ data.type }
          value={ data?.type }
        />
      </Table.Cell>
      <Table.Cell>
        {
          data.clients.length === 1 &&
          <UserItem
            innerPadding={ '0px' }
            labelWidth={ 220 }
            user={ data.clients[0] }
          />
        }
        { data.clients.length > 1 &&
          <AvatarLine
            count={ avatarsData.outOfLimit }
            isHideCounter={ avatarsData.outOfLimit < 1 }
            items={ avatarsData.dataToRender }
          />
        }
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
});
