import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@store';

import { getValidationSchema } from './validationSchema';

import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';
import PopupSingleActionButton, { POPUP_BUTTON_TYPES } from '@components/PopupSingleActionButton';

import { Content } from './components';

import { getDefaultState } from './utils';

import { LinkedContactIndividual } from '@/shared/types/linkedContact';
import { RelatedContactAddEditPopupProps } from './types';
import {
  RelatedContactFields
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/types';
import { RELATION_TYPES } from '@constants/common';

export const RelatedContactAddEditPopup = observer(({
  closeModal,
  modalProps,
}: RelatedContactAddEditPopupProps) => {
  const { currentContact } = useStore().ContactDetailsStore;
  const {
    openChangeContactSpouseConfirmationPopup,
    removeRelatedContactWithoutUpdatePage,
    save,
    table
  } = useStore().RelatedContactsStore;

  const { dataToEdit, deleteHandler } = modalProps;
  const isEditMode = Boolean(dataToEdit);
  const title = `${dataToEdit ? 'Edit' : 'Link'} Related Contact`;

  const [
    existingContact,
    setExistingContact
  ] = useState<LinkedContactIndividual | null>(dataToEdit?.clientContact || null);

  const methods = useForm<RelatedContactFields>({
    defaultValues: getDefaultState(dataToEdit),
    resolver: yupResolver(getValidationSchema(isEditMode)),
  });

  const { handleSubmit } = methods;

  const onSave = useCallback(() => {
    handleSubmit(async (data:RelatedContactFields) => {
      if(existingContact) {
        data.firstName = existingContact.firstName;
        data.lastName = existingContact.lastName;
        data.spouseContacts = existingContact.spouseContacts;
      }
      const spouseId = dataToEdit?.clientContact?.spouseContacts?.id;

      const openChangeSpousePopup = () => {
        closeModal();
        openChangeContactSpouseConfirmationPopup({
          data,
          currentContact,
          onSave: save
        });
        return;
      };

      const isContactWithSpouse = Boolean(
        data.relationType === RELATION_TYPES.SPOUSE &&
        currentContact?.id !== spouseId &&
        !isEmpty(dataToEdit?.clientContact.spouseContacts || existingContact?.spouseContacts)
      );

      const isSpouseWithHousehold = Boolean(
        data.relationType !== RELATION_TYPES.SPOUSE
        && data.spouseContacts?.isHousehold &&
        data.spouseContacts?.id === currentContact?.id
      );

      if(isContactWithSpouse || isSpouseWithHousehold) {
        openChangeSpousePopup();
      } else {
        closeModal();
        save(data);
      }
    })();
  }, [closeModal, handleSubmit, removeRelatedContactWithoutUpdatePage, save, dataToEdit, existingContact]);

  const onDelete = useCallback(() => {
    closeModal();
    if(dataToEdit && deleteHandler){
      deleteHandler(dataToEdit.id as string);
    }
  }, [closeModal, deleteHandler, dataToEdit]);

  return (
    <Modal
      onClose={ closeModal }
      title={ title }
    >
      <Modal.Content>
        <FormProvider { ...methods }>
          <Content
            currentContact={ currentContact }
            dataToEdit={ dataToEdit || null }
            existingContact={ existingContact }
            relatedContacts={ table.items || [] }
            setExistingContact={ setExistingContact }
          />
        </FormProvider>
      </Modal.Content>
      <Modal.Footer paddingLeft={ '20px !important' }>
        {
          isEditMode && (
            <PopupSingleActionButton
              onClick={ onDelete }
              type={ POPUP_BUTTON_TYPES.delete }
            >
              Unlink Related Contact
            </PopupSingleActionButton>
          )}
        <PopupActionsButtons
          onCancelClick={ closeModal }
          onSaveClick={ onSave }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
});
