import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { CONTACT_DETAILS_SIDE_BAR_PANEL_TAB } from './data';
import { useContactDetailsSideBarPanelLocalStore } from './store';

import { ContactDetails, Header, Notes, Tabs, ToDosTable } from './components';
import Preloader from '@components/Preloader';

import { ContactDetailsSideBarPanelProps } from './types';

import { Panel } from './styles';

export const ContactDetailsSideBarPanel = observer(({
  contactId,
  isOpen,
  onClose,
  onRefreshToDoTable
}: ContactDetailsSideBarPanelProps) => {

  const store = useContactDetailsSideBarPanelLocalStore();
  const { currentTab, init, isLoading, reset } = store;
  
  useEffect(() => {
    init(contactId, onRefreshToDoTable);
    return reset;
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <Panel
      anchor={ 'right' }
      open={ isOpen }
    >
      {
        isLoading && <Preloader />
      }
      <Header onClose={ onClose }/>
      <Tabs/>

      { currentTab === CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.contactDetails &&
        <ContactDetails/>
      }

      { currentTab === CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.toDos &&
        <ToDosTable/>
      }

      { currentTab === CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.notes &&
        <Notes/>
      }
    </Panel>
  );
});
