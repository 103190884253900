import React, { useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@services/store/store';
import { useDidUpdate } from '@/shared/customHooks';

import ChangePassword from './components/ChangePassword';
import FormBlock from '@components/FormBlock';
import { NotificationBar } from '@components/NotificationBar';
import { ContentWrapper, MainWrapper } from '@pages/NewUserSettings/components/Layout';

import { validationMessages } from '@constants/validationMessages';
import { PasswordFormInputNames, PASSWORD_FORM_DEFAULT_VALUES } from './data';
import { validationSchema } from './validation';

export const Password = observer(() => {

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: PASSWORD_FORM_DEFAULT_VALUES,
    resolver: yupResolver(validationSchema)
  });

  const { control, formState, setError, handleSubmit, reset } = methods;

  const userSettingsStore = useStore().UserSettingsStore;

  const [ isShowSaveBar, setShowSaveBar] = useState(false);

  const allFields = useWatch({ control });
  
  const onSaveClick = useCallback(async (data: any) => {
    const { oldPassword, newPassword } = data;
    const resp = await userSettingsStore.savePassword({ newPassword, oldPassword });
    if (resp) {
      setShowSaveBar(false);
    } else {
      setError(PasswordFormInputNames.oldPassword, {
        type: 'custom',
        message: validationMessages.invalidPassword
      });
    }
  }, [ userSettingsStore, setError ]);

  const onCancelClick = useCallback(() => {
    setShowSaveBar(false);
    reset(PASSWORD_FORM_DEFAULT_VALUES);
  }, []);
  
  useDidUpdate(() => {
    if(formState.isDirty) {
      setShowSaveBar(true);
    }
  }, [ allFields ]);

  return (
    <FormProvider { ...methods }>
      <ContentWrapper>
        <MainWrapper>
          <FormBlock
            isBorderBottom={ false }
          >
            <FormBlock.Header title={ 'Change Password' }/>
            <ChangePassword/>
          </FormBlock>
          <NotificationBar
            isOpen={ isShowSaveBar }
            onCancelButtonClick={ onCancelClick }
            onConfirmButtonClick={ handleSubmit(onSaveClick) }
          />
        </MainWrapper>
      </ContentWrapper>
    </FormProvider>
  );
});