import styled, { css } from 'styled-components';

import MuiBox from '@material-ui/core/Box';

import { BOX_SHADOW } from '@constants/style';
import { COLORS } from '@constants/colors';

import { ContentWrapperProps } from './types';
import { BoxProps } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
`;

export const ContentWrapper  = styled.div<ContentWrapperProps>`
  display: flex;
  flex-grow: 1;
  margin: 0 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  
  ${({ $isWrap= true }) => $isWrap && css`
    flex-wrap: wrap;
  `}
  
  ${({ $flexDirection }) => $flexDirection && css`
    flex-direction: ${$flexDirection};
  `}
`;

const getWrapperCommonStyles = (props: BoxProps) => (
  css`
    display: flex;
    flex-direction: column;
    border-radius: ${ props.borderRadius || '10px' };
    background-color: ${ COLORS.white };
    box-shadow: ${ props.boxShadow || BOX_SHADOW };
`);

export const MainWrapper = styled(MuiBox)`
  ${(props) => getWrapperCommonStyles(props)};
    flex-grow: 1;
    min-width:  1000px;
    overflow: hidden;
`;

export const FluidWrapper = styled(MuiBox)`
  ${(props) => getWrapperCommonStyles(props)};
  padding: 20px;
  align-self: flex-start;
`;

export const SideWrapper = styled.div`
    margin-left: 20px;
`;

export const TabWrapper = styled.div`
  margin-right: auto;
  margin-left: -20px;

  & .MuiTab-wrapper {
    font-size: 16px;
  }
`;
