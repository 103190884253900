import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Title = styled.div`
  height: 25px;
  font-size: 16px;
  color: ${COLORS.header};
`;

export const Amount = styled.span`
  max-width: 45px;
  font-size: 12px;
  color: ${COLORS.helpText};
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TabsWrapper = styled.div`
  width: 330px;
  margin-left: auto;
  border-bottom: 1px solid ${COLORS.greyLight};
`;

export const DataWrapper = styled.div`
  display: flex;
  gap: 5px;

  & svg path {
    fill: revert-layer !important;
  }
`;