import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  LEFT_COLUMN_WIDTH
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { ImportantInfoProps } from './types';

export const ImportantInfo = ({ name }: ImportantInfoProps) => {
  const { control } = useFormContext();

  return (
    <FormBlock
      isBorderBottom={ true }
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Information ' }/>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          label={ 'Important Information' }
        >
          <HFTextInput
            inputProps={ {
              placeholder: 'Enter Text Please',
              maxLength: 250
            } }
            control={ control }
            name={ name }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};
