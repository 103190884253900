import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { useToDosRowCheckbox } from '@/shared/customHooks/useToDosRowCheckbox';
import { useToDosRowGlobalCheckbox } from '@/shared/customHooks/useToDosRowGlobalCheckbox';
import { useToggle } from '@/shared/customHooks';

import { MODAL_TYPE } from '@constants/modalTypes';
import { COLUMNS, LINKED_ITEMS_TYPES_SCHEME } from '@constants/todosData';

import { TodoDeleteConfirmationPopup } from '@modules/TodoPopup/components';
import { TodosRow } from '@modules/TodosRow';
import ContactDetailsSideBarPanel from '@modules/ContactDetailsSideBarPanel';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table from '@components/Table';

import { IdType } from '@/shared/types/commonTypes';
import { TodoGridFormField, TodoItem } from '@/shared/types/todos';
import { TodoTableProps } from './types';

export const TodoTable = observer(({
  onAddTaskClick,
}: TodoTableProps) => {
  const todoStore = useStore().ToDosStore;
  const modalStore = useStore().ModalStore;

  const [ isSideBarPanelOpen, setIsSideBarPanelOpen ] = useToggle(false);
  const [ activeRowData, setActiveRowData ] = useState<TodoItem | null>(null);

  const {
    bookmark,
    getToDos,
    isPageActive,
    isTodoLoads,
    onRemove,
    table,
    todoPopupSettings: { priority, stage },
    updateFromGrid,
  } = todoStore;

  const {
    isGlobalCheckboxChecked,
    isGlobalCheckboxIndeterminate,
    onGlobalCheckboxClick
  } = useToDosRowGlobalCheckbox({
    pageItems: table.items,
    selectedIds: table.selectedIDs,
    onGlobalCheckboxClickCallback: table.setSelectedIds
  });

  const {
    getCheckboxState,
    onRowCheckboxClick
  } = useToDosRowCheckbox({
    onGlobalCheckboxClickCallback: table.setSelectedIds,
    selectedIds: table.selectedIDs,
  });

  const onRowBookmarkClick = useCallback((id: IdType, state: boolean) => {
    bookmark([{
      id,
      flagged: Number(state)
    }]);
  }, [bookmark]);

  const triggerDelete = useCallback((id: IdType, requirring: boolean) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_DELETE_CONFIRMATION,
      modalProps: {
        id,
        isRequirring: requirring,
        onConfirm: onRemove
      },
      component: TodoDeleteConfirmationPopup
    });
  }, [modalStore, onRemove]);

  const triggerEdit = useCallback((id?: IdType, isCloneTask?: boolean, previousCopyData?: any) => (
    onAddTaskClick(id, isCloneTask, previousCopyData)
  ), [onAddTaskClick]);

  const updateItemsFromGrid = useCallback((allFieldsObject: TodoGridFormField) => {
    updateFromGrid(allFieldsObject);
  }, [updateFromGrid]);

  const onAddTodoWrapper = useCallback(() => {
    onAddTaskClick();
  }, [ onAddTaskClick ]);

  return (
    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: isGlobalCheckboxChecked,
            onChange: onGlobalCheckboxClick,
            indeterminate: isGlobalCheckboxIndeterminate,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isTodoLoads && table.items.length > 0 &&
            table.items.map((item: TodoItem) => (
              <TodosRow
                data={ item }
                isCheckboxActive={ getCheckboxState(item) }
                key={ item.id }
                onCheckboxClickCb={ onRowCheckboxClick }
                onDeleteTodo={ triggerDelete }
                onEditTodo={ triggerEdit }
                onRowBookmarkCb={ onRowBookmarkClick }
                priorityOptions={ priority }
                setActiveRowData={ setActiveRowData }
                setIsSideBarPanelOpen={ setIsSideBarPanelOpen }
                stageOptions={ stage }
                updateFromGrid={ updateItemsFromGrid }
              />
            ))
          }
        </Table.Body>
        { isSideBarPanelOpen && activeRowData?.linkedItemData &&
          activeRowData.linkedItemData.linkedContactType === LINKED_ITEMS_TYPES_SCHEME.contactData.type &&
          <ContactDetailsSideBarPanel
            contactId={ activeRowData.linkedItemData.id }
            isOpen={ isSideBarPanelOpen }
            onClose={ setIsSideBarPanelOpen }
            onRefreshToDoTable={ getToDos }
          />
        }
        {
          isPageActive && !isTodoLoads && table.items.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              addActionButtonClickHandler={ onAddTodoWrapper }
              addActionButtonLabel={ 'Add Task' }
              withAction={ true }
              withInfo={ false }
            />
          </ContentCenter>
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>
  );
});
