import React, { useCallback } from 'react';

import Table from '@components/Table';
import DotsMenu from '@components/DotsMenu';
import TextWithTooltip from '@components/TextWithTooltip';

import { UserCustomFieldsTableRowProps, UserCustomFieldsItemDotsMenuTypes } from './types';

import { USER_CUSTOM_FIELDS_DOTS_MENU_OPTIONS, USER_CUSTOM_FIELDS_DOTS_MENU_TYPES } from './data';
import { USER_CUSTOM_FIELDS_TYPES_LABELS } from '@constants/userSettingsUserCustomFields';

export const UserCustomFieldsTableRow = ({
  onRemove,
  onEdit,
  item
}: UserCustomFieldsTableRowProps) => {
  const onRowDoubleClick = useCallback(() => onEdit(item), [item.id, onEdit]);

  const onMenuSelect = useCallback((action: UserCustomFieldsItemDotsMenuTypes) => {
    if(action === USER_CUSTOM_FIELDS_DOTS_MENU_TYPES.edit) {
      onEdit(item);
    }

    if(action === USER_CUSTOM_FIELDS_DOTS_MENU_TYPES.remove) {
      onRemove(item);
    }

  }, [item.id, onEdit, onRemove]);

  return (
    <Table.Row onDoubleClick={ onRowDoubleClick }>
      <Table.Cell>
        <TextWithTooltip text={ item.name }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ USER_CUSTOM_FIELDS_TYPES_LABELS[item.type] }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ item.entity }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ item.options.length }/>

        <DotsMenu<UserCustomFieldsItemDotsMenuTypes>
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ USER_CUSTOM_FIELDS_DOTS_MENU_OPTIONS }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
};
