import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { getContactWithAccountPlansOpportunities } from '@services/api/common/common';

import {
  getContactAccountPlanOpportunitiesNameParam,
  linkedItemResponseConverter,
  responseLinkedItemDataConverter,
} from '@/shared/utils/fetchAutocompleteUtils';

import { DESCRIPTION_INPUT_PROPS, DESCRIPTION_TEXT_FIELD__PROPS, TASK_NAME_INPUT_PROPS } from './data';
import { LABEL_WIDTH } from '@modules/TodoPopup/data';
import { TODOS_FIELDS_NAMES } from '@constants/todosData';

import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFLinkedItem from '@modules/HookFormComponents/HFLinkedItem';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';


export const PrimaryDetails = observer(() => {
  const {
    isGlobalDisabled,
    isLinkedItem,
    linkedContactData,
    onLinkedContactChange,
  } = useTodoPopupLocalStore();

  const responseConverter = useCallback((response: AxiosResponse) => {
    const responseData = linkedItemResponseConverter(response);
    const contactData = responseLinkedItemDataConverter(responseData);
    return contactData;
  }, []);

  const { control } = useFormContext();

  return (
    <FormBlock marginBottom={ 15 }>
      <FormBlock.Header title={ 'Primary Details' }/>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH.common }
        isRequired={ true }
        label={ 'Task Name' }
      >
        <HFTextInput
          inputProps={ TASK_NAME_INPUT_PROPS }
          control={ control }
          disabled={ isGlobalDisabled }
          name={ TODOS_FIELDS_NAMES.name }
        />
      </FormBlock.FormField>

      { isLinkedItem && 
        <>
          <HFHiddenInput
            control={ control }
            defaultValue={ true }
            name={ TODOS_FIELDS_NAMES.isLinkedContact }
          />
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.common }
            isRequired
            label={ 'Linked Item' }
          >
            <HFLinkedItem
              FetchProps={ {
                getParams: getContactAccountPlanOpportunitiesNameParam,
                requestSender: getContactWithAccountPlansOpportunities,
                responseConverter,
              } }
              TextFieldProps={ {
                disabled: isGlobalDisabled,
                placeholder: 'Start Typing to Search Contact or Opportunity...'
              } }
              UserItemProps={ {
                disabled: isGlobalDisabled,
              } }
              control={ control }
              disabled={ isGlobalDisabled }
              initLinkedContact={ linkedContactData }
              name={ TODOS_FIELDS_NAMES.linkedItemId }
              onLinkedContactChange={ onLinkedContactChange }
            />
          </FormBlock.FormField>
        </>
      }

      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH.common }
        label={ 'Description' }
      >
        <HFTextInput
          inputProps={ DESCRIPTION_INPUT_PROPS }
          TextFieldProps={ DESCRIPTION_TEXT_FIELD__PROPS }
          control={ control }
          disabled={ isGlobalDisabled }
          name={ TODOS_FIELDS_NAMES.description }
        />
      </FormBlock.FormField>
    </FormBlock>
  );
});
