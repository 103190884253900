import { CONTACTS_FILTER_NAMES } from '@pages/NewContacts/subpages/Contacts/subpages/AllCantacts/data';

export const USERS = {
  name: CONTACTS_FILTER_NAMES.users,
  label: 'Users',
  values: []
};

export const CLASSIFICATION =   {
  name: CONTACTS_FILTER_NAMES.classification,
  label: 'Core Classification',
  values: []
};

export const TYPE =   {
  name: CONTACTS_FILTER_NAMES.type,
  label: 'Contact Type',
  values: []
};

export const STATUS = {
  name: CONTACTS_FILTER_NAMES.status,
  label: 'Contact Status',
  values: []
};
