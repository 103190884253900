import { LINKED_ITEMS_IDS, LINKED_ITEMS_TYPES_SCHEME } from '@constants/todosData';

export const getDataWithActualLinkedItemId = (linkedItemData: any, linkedItemType?: any, linkedItemId?: any) => {
  Object.values(LINKED_ITEMS_IDS).forEach((id) => {
    linkedItemData[id] = null;
  });
  if(linkedItemType && linkedItemId) {
    Object.values(LINKED_ITEMS_TYPES_SCHEME).forEach((type) => {
      if(type.type === linkedItemType) {
        linkedItemData[type.id] = linkedItemId;
      }
    });  
  }
  return linkedItemData;
};
