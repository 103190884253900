import React, { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';

import { AddField, FieldsFactory } from './components';

import {
  LEFT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';
import { FIELD_IDS, GROUP_FIELDS_IDS } from '@constants/contactsData';
import { DEFAULT_CONFIG, KEY_NAME, DETAILS_DATA_MAP } from './data';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { getPrimaryName } from '@/shared/utils/getPrimaryName';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { ContactDetailsProps, ForceField, ForceFieldsType } from './types';

export const ContactDetails = ({
  config = DEFAULT_CONFIG,
  name,
  title = 'Contact Details',
  labelWidth = LEFT_COLUMN_WIDTH
}: ContactDetailsProps) => {
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: KEY_NAME,
    name,
  });

  const allFields = useWatch({
    control,
    name,
  });

  const onAdd = useCallback((index: number) => {
    append(DETAILS_DATA_MAP[index as FIELD_IDS].defaultValues);
  }, [append]);

  const onDeleteClick = useCallback((value: number) => {
    remove(value);
  }, [remove]);

  const onPrimaryClick = useCallback((parentName: string, index: number) => {
    const field = allFields[index] as ForceField;
    const nameOfField = getPrimaryName(parentName, index);

    const contactDetailsGroup = GROUP_FIELDS_IDS.filter(group => {
      if(group.find(id => id === field.fieldId)) {
        return group;
      }
    })[0];

    const allGroupedFields = allFields.filter((field: ForceField, index: number) => {
      const isExistField = contactDetailsGroup.includes(field.fieldId);
      if(isExistField) {
        field.nameOfField = getPrimaryName(parentName, index);
      }
      return isExistField;
    });

    allGroupedFields.forEach((field: ForceField) => {
      if(yesNoToBoolean(field.primary)) {
        setValue(field.nameOfField, booleanToYesNo(false));
      }
    });
    setValue(nameOfField, booleanToYesNo(true), { shouldDirty: true });
  }, [allFields, setValue]);

  return (
    <FormBlock
      isBorderBottom={ true }
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ title }>
        <AddField
          config={ config }
          onOptionClick={ onAdd }
        />
      </FormBlock.Header>
      <FieldsFactory 
        fields={ fields as ForceFieldsType }
        labelWidth={ labelWidth }
        onDeleteClick={ onDeleteClick }
        onPrimaryClick={ onPrimaryClick }
        parentName={ name }
      />
    </FormBlock>
  );
};
