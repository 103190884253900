export const simpleEmailPattern = /^(.+)@(.+)(\.\w+)$/;
// eslint-disable-next-line no-useless-escape
export const simplePhoneValidation = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/;
export const simpleDateValidation = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
export const onlyLatinLettersAndNumbers = /^[a-zA-Z0-9]*$/;
export const dateOutputValidation = /^$|^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/; // yyyy/mm/dd or empty string
export const twentyFourTimeFormat = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5][0-9])?$/;
export const faxValidation = /^[a-zA-Z0-9\s]+$|^$/;
// eslint-disable-next-line max-len
export const websiteValidation = /^((https?):\/\/(www\.)?)?[a-zA-Z0-9.!@#$%&'*+\/=?^_`{|}~-]+\.[a-z]{2,}$/;

export const numberWithTwoDecimal = /^-?\d+(\.\d{1,2})?$/;
export const positiveNumberWithOptionalTwoDecimal = /^\d+(\.\d{1,2})?$/;

export const fromOneToOneHundred = /^[0-9]$|^[1-9][0-9]$|^(100)$/;

export const onlyDigits = /^[0-9]*$/;

export const password = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w]).{8,}$/;

// eslint-disable-next-line max-len
export const facebookProfilePattern = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;
export const twitterProfilePattern = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/;
// eslint-disable-next-line max-len
export const linkedInProfilePattern = /(^((https?:\/\/)?((www|\w\w)\.)?)linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

export const onlyNumberAndCharacterPattern = /^[a-zA-Z0-9]*$/;
export const onlyNumberAndCharacterWithSpacePattern = /^[a-zA-Z0-9 ]*$/;

//TODO delete after refactoring Create Account and PhoneMask, exchange PasswordInput,
export const RegEx = {
  //eslint-disable-next-line
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%.^&*-]).{8,}$/,
  phoneReplace: /[\+\(\)\- ]/g,
  title: /[a-zA-Z0-9'\s]+/,
  letterDigit: /[a-zA-Z0-9]+/,
  fax: /^[a-zA-Z0-9\s]+$|^$/
};
