import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import { sortCompareFn } from '@modules/AccountsAndPlansPopup/utils/sortCompareFn';
import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import BlockButton from '@components/BlockButton';
import ContentCenter from '@components/ContentCenter';
import FormBlock from '@components/FormBlock';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';
import { TopArrow } from '@/assets';

import {
  LeftWrapper,
  RightWrapper
} from '@modules/AccountsAndPlansPopup/components/Content/components';

import { COMMON_TABS_VALUES, LABEL_SIZES } from '@modules/AccountsAndPlansPopup/data/data';
import {
  ACCOUNT_POLICY_VALUES_NAMES,
  BALANCE_HISTORY_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  BALANCE_HISTORY_TYPES
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/data';

import { BalanceHistoryItem, FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';

import { TextWrapper, TextRowWrapper, Overlay } from './styles';

const { leftLabelWidth, rightLabelWidth } = LABEL_SIZES;

const TextFieldProps = {
  type: 'number'
};

export const AccountPolicyValues = observer(() => {
  const { control } = useFormContext<FormFieldsValues>();
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const { isNotesPanelInAddOrEditMode } = accountsAndPlansLocalStore.notesUtilsStore;

  const onUpgradeClick = useCallback(() => {
    accountsAndPlansLocalStore.setCurrentTab(COMMON_TABS_VALUES.BALANCE_HISTORY);
  },[accountsAndPlansLocalStore]);

  const balanceHistory = useWatch({
    control,
    name: BALANCE_HISTORY_NAMES.balanceHistory
  });

  const balanceHistoryAscSort = useMemo(() => {
    if(!balanceHistory){
      return [];
    } else {
      const compareFn = sortCompareFn<BalanceHistoryItem>({
        direction: 'asc',
      });
      return balanceHistory.sort(compareFn);
    }
  },[balanceHistory]);

  const lastUpdatedValue = useMemo(() => {
    const placeholder =  formatNumberToAmount(null);
    if(balanceHistoryAscSort.length === 0) {
      return placeholder;
    }
    let lastAppropriateValue: BalanceHistoryItem | null = null;

    for (let i = balanceHistoryAscSort.length - 1; i >= 0; i--){
      if(balanceHistoryAscSort[i].type !== BALANCE_HISTORY_TYPES.contribution &&
        balanceHistoryAscSort[i].type !== BALANCE_HISTORY_TYPES.withdrawal &&
        balanceHistoryAscSort[i].value.length > 0
      ){
        lastAppropriateValue = balanceHistoryAscSort[i];
        break;
      }
    }

    if(!lastAppropriateValue){
      return placeholder;
    }


    return formatNumberToAmount(lastAppropriateValue.value);
  },[balanceHistoryAscSort]);

  const renderValue = useCallback(() => {
    return (
      <TextRowWrapper>
        <TextWrapper>
          <Tooltip
            displayOnlyOnOverflow={ true }
            title={ lastUpdatedValue }
          >
            <TextEllipsis $maxWidth={ 200 }>
              { lastUpdatedValue }
            </TextEllipsis>
          </Tooltip>
        </TextWrapper>
      </TextRowWrapper>
    );
  }, [lastUpdatedValue]);

  return (
    <>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
        style={ {
          position: 'relative'
        } }
      >
        {
          isNotesPanelInAddOrEditMode && <Overlay/>
        }
        <FormBlock.Header title={ 'Account/Policy Values' }>
          <ContentCenter acceptStylesToChild={ true }>
            <BlockButton
              disabled={ isNotesPanelInAddOrEditMode }
              onClick={ onUpgradeClick }
              startIcon={ <TopArrow /> }
            >
              Update
            </BlockButton>
          </ContentCenter>
        </FormBlock.Header>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Current Balance' }
            >
              { renderValue() }
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Cash Value $' }
            >
              <HFTextInput
                control={ control }
                name={ ACCOUNT_POLICY_VALUES_NAMES.cashValue }
                placeholder={ 'Enter Cash Value' }
                TextFieldProps={ TextFieldProps }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Income Value $' }
            >
              <HFTextInput
                control={ control }
                name={ ACCOUNT_POLICY_VALUES_NAMES.incomeValue }
                placeholder={ 'Enter Income Value' }
                TextFieldProps={ TextFieldProps }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Surrender Value $' }
            >
              <HFTextInput
                control={ control }
                name={ ACCOUNT_POLICY_VALUES_NAMES.surrenderValue }
                placeholder={ 'Enter Surrender Value' }
                TextFieldProps={ TextFieldProps }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Death Benefit $' }
            >
              <HFTextInput
                control={ control }
                name={ ACCOUNT_POLICY_VALUES_NAMES.deathBenefit }
                placeholder={ 'Enter Death Benefit' }
                TextFieldProps={ TextFieldProps }
              />
            </FormBlock.FormField>
          </LeftWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
});
