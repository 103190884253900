import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import assertUnreachable from '@/shared/utils/assertUnreachable';
import { useStore } from '@/shared/services/store/store';

import ConfirmationPopup, { POPUP_TYPES } from '@/modules/ConfirmationPopup';
import {
  PopupChildWIthChoosing,
  PopupChildWIthReplacing,
  PopupChildWIthReplacingAndSave,
  PopupChildWIthUnassignedAndSave
} from './components';

import { UserDeletePopupInputNames } from './data';

import {
  DeleteUserOption,
  DeleteUserPopupProps,
  FieldNamesDeletePopup,
  PopupSteps
} from './types';

export const DeleteUserPopup = ({
  closeModal,
  modalProps,
}: DeleteUserPopupProps) => {

  const userSettingsStore = useStore().UserSettingsStore;

  const [ popupStep, setPopupStep ] = useState(PopupSteps.Choosing);
  const { id, firstName, lastName } = modalProps.dataItem;

  const memoizedDefaultValues = useMemo(() => {
    return {
      deleteOption: DeleteUserOption.Replace,
      firstName,
      lastName,
      id,
      replacingUser: null,
    };
  }, [ id, firstName, lastName ]);

  const methods = useForm<FieldNamesDeletePopup>({
    mode: 'onSubmit',
    defaultValues: memoizedDefaultValues,
  });

  const { control, handleSubmit } = methods;

  const [ deleteOption, replacingUser ]= useWatch({
    control: control,
    name: [
      UserDeletePopupInputNames.deleteOption,
      UserDeletePopupInputNames.replacingUser,
    ]
  });

  const getMessage = useMemo(() => {
    switch(popupStep) {
    case PopupSteps.Choosing: 
      return `What do you want to do with user ${firstName} ${lastName}?`;
    case PopupSteps.Replacing: 
      return `You want to delete "${firstName} ${lastName}". Before deleting, please select the user for replacing?`;
    case PopupSteps.ReplacingAndSave:
    case PopupSteps.UnassignedAndSave:
      return '';
    default: {
      assertUnreachable(popupStep, `Unexpected popup step: ${popupStep}`);
    }
    }
  }, [ popupStep, firstName, lastName ]);

  const OnApplyClick = useCallback((data: FieldNamesDeletePopup) => {
    switch(popupStep) {
    case PopupSteps.Choosing:
      if (deleteOption === DeleteUserOption.Replace) {
        return setPopupStep(PopupSteps.Replacing);
      } else {
        return setPopupStep(PopupSteps.UnassignedAndSave);
      }
    case PopupSteps.Replacing:
      return replacingUser && setPopupStep(PopupSteps.ReplacingAndSave);
    case PopupSteps.UnassignedAndSave:
      userSettingsStore.deleteUser({ id: data.id });
      closeModal();
      return;
    case PopupSteps.ReplacingAndSave:
      userSettingsStore.deleteUser({
        id: data.id,
        assignedId: data.replacingUser!.id,
      });
      closeModal();
      return;
    default: {
      assertUnreachable(popupStep, `Unexpected popup step: ${popupStep}`);
    }
    }
  }, [ popupStep, deleteOption, replacingUser, userSettingsStore ]);

  const isLastStep = useCallback(() => {
    return [ PopupSteps.UnassignedAndSave, PopupSteps.ReplacingAndSave ].includes(popupStep);
  }, [ popupStep ]);

  const getPopupChild = useMemo(() => {
    switch(popupStep) {
    case PopupSteps.Choosing:
      return <PopupChildWIthChoosing/>;
    case PopupSteps.Replacing:
      return <PopupChildWIthReplacing/>;
    case PopupSteps.UnassignedAndSave:
      return <PopupChildWIthUnassignedAndSave/>;
    case PopupSteps.ReplacingAndSave:
      return <PopupChildWIthReplacingAndSave/>;    
    default: {
      assertUnreachable(popupStep, `Unexpected popup step: ${popupStep}`);
    }
    }
  }, [ popupStep ]);

  return (
    <FormProvider { ...methods }>
      <ConfirmationPopup
        confirmLabel={ isLastStep() ? 'Okay' : 'Apply' }
        isOpen={ true }
        message={ getMessage }
        onCancelClick={ isLastStep() ? closeModal : undefined }
        onClose={ closeModal }
        onConfirmClick={ handleSubmit(OnApplyClick) }
        type={ POPUP_TYPES.WARNING }
      >
        { getPopupChild }
      </ConfirmationPopup>

    </FormProvider>
  );
};
