import { AxiosResponse } from 'axios';
import {
  BackendGridResponse,
  Client,
  ValueLabelObj,
  ValueOf,
  CommonGridRequest,
  Flagged, IdType, YesNoType
} from '@/shared/types/commonTypes';
import {
  LISTS_TABLE_FILTER_NAMES,
  LISTS_TYPES,
  LIST_CONTACTS_TABLE_FILTER_NAMES,
  LIST_CRITERIA_ROOT_NAME,
  LIST_EXPORT_FILE_TYPE,
  LIST_EXPORT_TYPE,
  LIST_VALUE_NESTED_KEYS,
  NEW_FOLDER_FRONT_ID_KEY,
  TYPE_FIELDS,
} from '@constants/lists';
import { BusinessContactItem, ContactItem, HouseHoldContactItem, IndividualContactItem } from '@/shared/types/contact';
import { CONTACT_STATUSES } from '@constants/common';
import { LinkedContact } from '@/shared/types/linkedContact';



export type ListTypes = LISTS_TYPES.Dynamic | LISTS_TYPES.Manual

export type FiltersData = {
  classification: Array<string>
  listOwner: Array<Omit<Client, 'headshot'>>
  // eslint-disable-next-line no-restricted-globals
  status: Array<string>
  users: Array<Omit<Client, 'headshot'>>
}

export type ConvertedListsFiltersData = {
  classification: Array<ValueLabelObj>
  officePrimaryAdvisor: Array<ValueLabelObj>
  // eslint-disable-next-line no-restricted-globals
  status: Array<ValueLabelObj>
  listOwner: Array<ValueLabelObj>
}

export type ListsFiltersType = {
  [LISTS_TABLE_FILTER_NAMES.classification]: Array<string>
  [LISTS_TABLE_FILTER_NAMES.officePrimaryAdvisor]: Array<string>
  [LISTS_TABLE_FILTER_NAMES.listOwner]: Array<string>
  [LISTS_TABLE_FILTER_NAMES.name]: string | null
  [LISTS_TABLE_FILTER_NAMES.status]: Array<string>
}

export type ListContactsFiltersData = {
  classification: Array<string>
  createdBy: Array<Omit<Client, 'headshot'>>
  type: Array<string>
  // eslint-disable-next-line no-restricted-globals
  status: Array<string>
}

export type ConvertedListContactsFiltersData = {
  classification: Array<ValueLabelObj>
  officePrimaryAdvisor: Array<ValueLabelObj>
  type: Array<ValueLabelObj>
  // eslint-disable-next-line no-restricted-globals
  status: Array<ValueLabelObj>
}

export type ListContactsFiltersType = {
  [LIST_CONTACTS_TABLE_FILTER_NAMES.classification]?: Array<string>
  [LIST_CONTACTS_TABLE_FILTER_NAMES.officePrimaryAdvisor]?: Array<string>
  [LISTS_TABLE_FILTER_NAMES.status]?: Array<string>
  [LIST_CONTACTS_TABLE_FILTER_NAMES.name]?: string | null
  [LIST_CONTACTS_TABLE_FILTER_NAMES.type]?: Array<string>
}

type ContactDataItem = LinkedContact

export type ContactData = {
  [K: string] : ContactDataItem,
} & {
  count: number
}

export type ListOwnerData = {
  id: number
  firstName: string
  lastName: string
  headshot: any
}

type ItemCommonProps = {
  id: number
  // eslint-disable-next-line no-restricted-globals
  name: string
  parentId: ParentId
}

export type FolderType = {
  typeList: LISTS_TYPES.Folder
} & ItemCommonProps

type DynamicListType = {
  listOwnerData: ListOwnerData
  typeList: LISTS_TYPES.Dynamic
  contactData: ContactData
  flagged: Flagged
} & ItemCommonProps & SplitHouseHold

type ManualListType = {
  listOwnerData: ListOwnerData
  typeList: LISTS_TYPES.Manual
  contactData: ContactData
  flagged: Flagged
} & ItemCommonProps & SplitHouseHold

export type ListsGridItemListType =  DynamicListType | ManualListType

export type ListsGridItemType = FolderType | DynamicListType | ManualListType

type SplitHouseHold = {
  splitHousehold: number
}

export type Parent = SplitHouseHold & {
  id: number
  clientId: number
  officePrimaryAdvisor: number
  parentId: ParentId // Attention! It is grandparent id
  typeField: TYPE_FIELDS
  typeList: LISTS_TYPES
  // eslint-disable-next-line no-restricted-globals
  name: string
  description: any
  createdDate: string
  filter: null
  flagged: Flagged
  deleted: number
} | null

type ListsData = {
  [K: string] : ListsGridItemType,
} & {
  filterData: FiltersData
  // eslint-disable-next-line no-restricted-globals
  parent: Parent
}

export type ListsGridResponse = AxiosResponse<BackendGridResponse<ListsData>>
export type ParentId = number | null

type ListCommonFields = {
  // eslint-disable-next-line no-restricted-globals
  name: string
  parentId: ParentId
}

export type SaveFolderData = {
  id: number | null
  typeField: TYPE_FIELDS.Folder
} & ListCommonFields


export type CommonSaveListData = SplitHouseHold & {
  description?: string
  id: null | number
  listOwner: number
  typeField: TYPE_FIELDS.List
}

type ManualSaveListData = {
  typeList: LISTS_TYPES.Manual
  filter: null
} & CommonSaveListData & ListCommonFields

type DynamicSaveListData = {
  typeList: LISTS_TYPES.Dynamic
  filter: Array<any>
} & CommonSaveListData & ListCommonFields

export type SaveListData = DynamicSaveListData | ManualSaveListData

export type LisSaveErrorData = {
  data: {
    error: {
      // eslint-disable-next-line no-restricted-globals
      name: string
    }
  }
}

export type ListDataDetailsType = {
  typeField: TYPE_FIELDS.List
  typeList: ListTypes
} & ListCommonFields & SplitHouseHold & CommonSaveListData

export type ListContactItem = ContactItem;

export type ListContactsData = {
  [K: string] : ListContactItem,
} & {
  filterData: ListContactsFiltersData
  // eslint-disable-next-line no-restricted-globals
  listData: ListDataDetailsType
}

export type ListContactsGridResponse = AxiosResponse<BackendGridResponse<ListContactsData>>

export type AddContactsToListData = {
  id: number
  contacts: Array<number | string>
}

type IndividualOption = Pick<
  IndividualContactItem,
  'id' | 'photo' | 'classification' | 'type' | 'firstName' | 'lastName'
>

type BusinessOption = Pick<
  BusinessContactItem,
  'id' | 'photo' | 'classification' | 'type' | 'occupationCompany'
>

type HouseHoldOptions = Pick<
  HouseHoldContactItem,
  'id' | 'classification' | 'type' | 'householderContacts'
>

export type ContactOptionToListAdd = IndividualOption | BusinessOption | HouseHoldOptions;

export type DeleteContactsFromListData = {
  id: number
  contactsIds: Array<number | string>
}

export const LISTS_CONTACTS_GROUP_KEYS =  {
  Active: 'Active',
  Deceased: 'Deceased',
  allowCall: 'allowCall',
  allowEmail: 'allowEmail',
  allowMail: 'allowMail',
  allowText: 'allowText',
} as const;

export type ContactsByType = {
  [LISTS_CONTACTS_GROUP_KEYS.Active]: ContactData,
  [LISTS_CONTACTS_GROUP_KEYS.Deceased]: ContactData,
  [LISTS_CONTACTS_GROUP_KEYS.allowCall]: ContactData,
  [LISTS_CONTACTS_GROUP_KEYS.allowEmail]: ContactData,
  [LISTS_CONTACTS_GROUP_KEYS.allowMail]: ContactData,
  [LISTS_CONTACTS_GROUP_KEYS.allowText]: ContactData,
}

export type ListsContactsGroupTypes = ValueOf<typeof LISTS_CONTACTS_GROUP_KEYS>;

type CommonListSetupType = MailChimpList & {
  clientId: number
  contactData: ContactsByType
  createdDate: string
  deleted: number,
  flagged: Flagged,
  id: number
  listData: ListDataDetailsType
  listOwnerData: ListOwnerData
  officePrimaryAdvisor: number
}

export type MailChimpList = {
  mailChimpLastSyncDate: string | null
  mailChimpListId: number | null
  mailchimpListName: string
  syncWithMailChimp: YesNoType,
  syncWithMailChimpStatus: string,
}

type Lists = DynamicSaveListData | ManualSaveListData

export type ListSetupResponse = AxiosResponse<{
  data: CommonListSetupType & Lists
}>

type ListContactsGroupTypes = {
  [LISTS_CONTACTS_GROUP_KEYS.allowCall]?: number
  [LISTS_CONTACTS_GROUP_KEYS.allowEmail]?: number
  [LISTS_CONTACTS_GROUP_KEYS.allowMail]?: number
  [LISTS_CONTACTS_GROUP_KEYS.allowText]?: number
  // eslint-disable-next-line no-restricted-globals
  status?: ValueOf<typeof CONTACT_STATUSES>
}

export type ListContactsByTypeParams = CommonGridRequest

export type DeleteListContactsByTypeParams = {
  [P: string]: any
  id: string,
}

export type ExportData = SplitHouseHold & {
  id: number | string
  fileType: LIST_EXPORT_FILE_TYPE
  exportType: LIST_EXPORT_TYPE
}

export type DeleteListsParams = {
  ids: Array<string>
}

export type GetCriteriaParams = {
  id: string | number
}

export type CriterionField = {
  // eslint-disable-next-line no-restricted-globals
  name: string // fields category
  value: Array<any>
  option: string // field type
  optionValueFilter: string // operand
}

export type Operand = {
  typeWhere: string
}

export type CriteriaBackendFields = Array<CriterionField | Operand>

export type CriteriaBackendFormFields = Array<CriteriaBackendFields | Operand>

export type GetCriteriaResponseType = AxiosResponse<{
  data: {
    criteria: CriteriaBackendFormFields,
    listData: ListDataDetailsType & { contactData: ContactData}
  }
}>

type CriterionNestedFields = {
  [LIST_VALUE_NESTED_KEYS.first]: string
  [LIST_VALUE_NESTED_KEYS.last]?: string
}

export type Criterion = {
  // eslint-disable-next-line no-restricted-globals
  name: string // fields category
  value: Array<any> | CriterionNestedFields
  option: string // field type
  optionValueFilter: string // operand
}

export type CriterionType = {
  operand?: Operand['typeWhere']
  criterion: Criterion
}

export type CriterionFieldsType = Array<CriterionType>

export type CriteriaBlock = {
  operand?: Operand['typeWhere']
  criteria: CriterionFieldsType
}

export type CriteriaBlocks = Array<CriteriaBlock>

export type CriteriaFormWrapper = {
  [LIST_CRITERIA_ROOT_NAME]: CriteriaBlocks
}

export type DeleteFolderParams = {
  id: number
  parentId: ParentId
}

export type DeleteFolderProps = {
  id: number
  parentId?: ParentId
}

export type CheckFolderParams = {
  id: number
}

export type CloneListParams = {
  id: number
}

export type TreeItem = {
  typeList: TYPE_FIELDS.Folder
} & SaveFolderData

export type TreeItemWithChildren = {
  childrens?: Array<TreeItem>
} & TreeItem


export type CheckFolderResp = AxiosResponse<{
  data: {
    empty: boolean,
    tree: {
      [key: string]: TreeItemWithChildren
    }
  }
}>

export type SaveToParentParams = {
  id: IdType
  parentId: ParentId
}

export type SyncMailChimpParams = {
  id: IdType
  mailChimpListId?: IdType | null
}

export type DisconnectMailChimpParams = {
  id: IdType
}

export type ListDragObject = {
  listId: IdType
}

export type TreeResponse = {
  data: Array<TreeItemWithChildren>
}

export type TreeRoot = {
  // eslint-disable-next-line no-restricted-globals
  name: string
  id: null
  parentId: ParentId
}

export type NewFolderType = {
  [NEW_FOLDER_FRONT_ID_KEY]: string
} & SaveFolderData

export type NewFolderTypeWithChildrens = {
  childrens?: Array<NewFolderType>
} & NewFolderType

export type NewFolderPopupFolderIndex = string | number | null

export type GetNamesAccountType = {
  names: Array<string>
  defaultNamePrefixCount: number
  prevName?: string
  isNewNameEqualToPrevWithoutCounter?:boolean
}

export type MoveToAnotherFolderProps = {
  closeModal: () => void
  newFolders: Array<NewFolderType>
  saveTarget: TreeRoot | NewFolderType | TreeItemWithChildren
  listItem: ListsGridItemListType
}

export type MultiFolderSaveParams = Array<Pick<SaveFolderData, 'name' | 'parentId' | 'typeField'>>

export type MultiFolderResponseItem = Pick<SaveFolderData,'parentId' | 'typeField' | 'name' | 'id'>

export type MultiFolderResponse = AxiosResponse<{
  data: Array<MultiFolderResponseItem>
}>
