import styled, { css } from 'styled-components';
import { Button as ButtonComponent } from '@material-ui/core';

import { COLORS } from '@constants/colors';

import { ArrowButtonProps } from './types';

export const CalendarActivityWrapper = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

export const Counter = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background: ${COLORS.bgActions};
  color: ${COLORS.primaryText};
`;

export const Button = styled(ButtonComponent)<ArrowButtonProps>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 50%;
  box-sizing: border-box;
  background: ${COLORS.dividingLines};
  ${({ isShow }) => !isShow && css`visibility: hidden`};

  & svg {
    & path {
      fill: ${COLORS.primaryText};
    }
  }
`;

export const TextChunk = styled.span`
  color: ${COLORS.helpText};
`;

export const MarkedTextChunk = styled.span`
  color: ${COLORS.grey};
  font-weight: 300;
`;

export const NextActivity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 50px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 18px;
  text-align: center;
`;

export const ActivityName = styled.div`
  max-width: 300px;
  margin: 0 20px;
  font-size: 26px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  color: ${COLORS.primaryText};
`;

export const ActivityDate = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: ${COLORS.helpText};
`;
