import styled, { css } from 'styled-components';

import { ERROR_SPACE_VALUE } from '@constants/style';
import { COLORS } from '@constants/colors';

import { LinkedContactOuterWrapperProps, TestColumnProps } from './types';

export const LinkedContactOuterWrapper = styled.div<LinkedContactOuterWrapperProps>`
  width: 100% !important;
  height: 30px;
  position: relative;
    ${({ $showError }) => $showError && css`
        margin-bottom: ${ERROR_SPACE_VALUE}px;
    ` };
`;

export const LinkedContactWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 10px;
  border: 1px solid ${COLORS.greyLight};
  border-right: none;
  border-left: none;
  font-size: 12px;
  background: ${COLORS.background};
  color: ${COLORS.primaryText};

  & > div {
    padding-right: 10px;
    box-sizing: border-box;
  }
`;

export const GroupChildren = styled.div`
  & .MuiAutocomplete-option {
    padding-left: 10px;
  }

  & > li > div {
    padding-right: 10px;
    font-size: 14px;
    color: ${COLORS.primaryText};
    box-sizing: border-box;
  }
`;

export const OpportunityStatusWrapper = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: ${COLORS.primaryText};
`;

export const HeaderColumn = styled.div<TestColumnProps>`
  width: ${({ $width }) => $width || '100px' };
  text-align: ${({ $align }) => $align ? $align : 'left' };
`;