import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form';

import { useDidUpdate } from '@customHooks';
import { useStore } from '@store';

import { ContentWrapper, MainWrapper } from '@pages/NewUserSettings/components/Layout';
import {
  CalendarPreferences,
  GeneralInformation,
  Security
} from './components';
import { NotificationBar } from '@components/NotificationBar';
import RouteGuard from '@modules/RouteGuard';

import { getAccountSettingsDefaultValues } from './utils';
import { openDetectLinkTransitionPopup } from '@/shared/utils/modalHelpers';

import { AccountSettingsType } from '@services/store/userSettingsStore/types';

export const AccountSettings = observer(() => {
  const userSettingsStore = useStore().UserSettingsStore;
  const settingsStore = useStore().SettingsStore;
  const sessionStore = useStore().SessionStore;

  const { isFetching } = userSettingsStore;
  const { settings } = settingsStore;

  const [ isShowSaveBar, setShowSaveBar] = useState(false);

  const memoizedDefaultValues = useMemo(() => getAccountSettingsDefaultValues(settings), [settings]);

  const methods: UseFormReturn<AccountSettingsType> = useForm<AccountSettingsType>({
    mode: 'onSubmit',
    defaultValues: memoizedDefaultValues,
  });

  const { control, formState: { isDirty }, handleSubmit, reset } = methods;

  const allFields = useWatch({ control });

  const onSaveClick = (data: AccountSettingsType) => {
    setShowSaveBar(false);
    userSettingsStore.saveSettings(data);
    sessionStore.setDuration(data.automaticLogout);
  };

  const onCancelClick = () => {
    setShowSaveBar(false);
    reset(memoizedDefaultValues);
  };

  useDidUpdate(() => {
    if(isDirty) {
      setShowSaveBar(true);
    }
  }, [ allFields ]);

  useEffect(() => {
    userSettingsStore.getSettings();
  }, [userSettingsStore]);

  useEffect(() => {
    reset(memoizedDefaultValues);
  }, [settings, isFetching]);

  return (
    <FormProvider { ...methods }>
      <ContentWrapper>
        <RouteGuard
          triggerModalRender={ openDetectLinkTransitionPopup }
          when={ isDirty }
        />
        <MainWrapper>
          <GeneralInformation/>
          <Security/>
          <CalendarPreferences/>
          <NotificationBar
            isOpen={ isShowSaveBar }
            onCancelButtonClick={ onCancelClick }
            onConfirmButtonClick={ handleSubmit(onSaveClick) }
          />
        </MainWrapper>
      </ContentWrapper>
    </FormProvider>
  );
});
