import { getFiltersParamName } from '@/shared/utils/getFiltersParamName';
import { isPrimitive } from '@/shared/utils/isPrimitive';

import { IdType, MultipleSortingState, SortDirection } from '@/shared/types/commonTypes';

export const addFilterOptions = <T extends { values: Array<any> }>(target: T, source: Array<any>) => {
  const targetCopy = JSON.parse(JSON.stringify(target));

  if(!source) {
    return targetCopy;
  }

  targetCopy.values = [...targetCopy.values, ...source];
  return targetCopy;
};

type Filters = {
  [k: string]: Array<any> | boolean | string | number | null
}

export const getFiltersCount = (filters: Filters) => (
  Object.values(filters).reduce((acc: number, value) => {
    if(Array.isArray(value) && value.length > 0) {
      acc++;
    }

    if(!Array.isArray(value) && value) {
      acc++;
    }

    return acc;
  }, 0)
);

type FiltersParams = {
  [key: `filters[where][${any}][${number}]`]: any;
}

export const getFilterParams = (filters: Filters | null) => {
  if(!filters) {
    return {};
  }

  return Object.entries(filters).reduce((acc:FiltersParams, [name, value]) => {
    if(Array.isArray(value)) {
      value.forEach((item, index) => {
        acc[getFiltersParamName(name, index)] = item;
      });
    }

    if(isPrimitive(value)){
      if(typeof value === 'string' && value.length === 0){
        return  acc;
      } else {
        acc[getFiltersParamName(name)] = value;
      }
    }

    return acc;
  }, {});
};

const FLAGGED_FILTER_NAME = 'flagged';

export const getGlobalFlagged = (state: boolean | null) => {
  return state ? getFilterParams({ [FLAGGED_FILTER_NAME]: Number(state) }) : null;
};

type SortKey = Record<`filters[order][${any}]`, any>;

export const getSortParams = (
  order: string | number | null,
  direction?: SortDirection | null
):SortKey | {} => {
  if(!order || !direction) {
    return {};
  }

  return {
    [`filters[order][${order}]`]: direction,
  };
};

export const getMultipleSortParams = (obj: MultipleSortingState) => {
  const arrayOfKeyValueArray = Object.entries(obj);
  if(arrayOfKeyValueArray.length === 0){
    return {};
  }

  return arrayOfKeyValueArray.reduce((acc: SortKey, sortData) => (
    {
      ...acc,
      ...getSortParams(sortData[0], sortData[1])
    }
  ), {});

};

type MultipleSortParams = null | Array<{
  [k: string]: SortDirection
}>

type getUserOptionType = {
  userObj: {
    id: IdType,
    firstName: string,
    lastName: string
    // TODO remove after backend unify response
    full_name?: string
  }
  additionalLabelText?: string,
  currentUserId?: IdType,
}

export const getUserOption = ({
  userObj,
  currentUserId,
  additionalLabelText = ' (Me)'
}: getUserOptionType) => {
  const value = `${userObj?.id}`;
  const label = `${userObj?.full_name || `${userObj?.firstName} ${userObj?.lastName}`}`;
  if(currentUserId) {
    return {
      value,
      label: label + `${userObj?.id === currentUserId ? additionalLabelText : ''}`
    };
  } else {
    return {
      value,
      label,
    };
  }
};

