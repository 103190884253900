import React from 'react';

import BaseBlockWrapper from '@components/BasicLayoutComponents/BaseBlockWrapper';
import Counter from '@components/Counter';

import {
  ChildrenWrapper,
  ClearBtn,
  Header,
  InnerWrapper,
  ItemWrapperWithDelimiter,
  Title,
  Wrapper,
} from './styles';

export const SideFilters = ({
  children,
  clearBtnTitle = 'Clear',
  count,
  filtersTitle = 'Filters',
  isNonePointerEvents,
  onClearClick,
}) => (
  <BaseBlockWrapper height={ '100%' }>
    <Wrapper
      style={ {
        pointerEvents: isNonePointerEvents ? 'none' : 'auto'
      } }
    >
      <Header>
        <Title>{ filtersTitle }</Title>
        {
          count > 0 &&
          <Counter>{ count }</Counter>
        }

        <ClearBtn
          color="primary"
          onClick={ onClearClick }
          size="medium"
        >
          { clearBtnTitle }
        </ClearBtn>
      </Header>
      <ChildrenWrapper>
        { children }
      </ChildrenWrapper>
    </Wrapper>
  </BaseBlockWrapper>
);

SideFilters.InnerWrapper = InnerWrapper;
SideFilters.ItemWrapperWithDelimiter = ItemWrapperWithDelimiter;
