import React from 'react';

import { LeftColumnWrapper, RightColumnWrapper } from '@/pages/NewUserSettings/components/Layout';
import ContactVisibilityItem from './components/ContactVisibilityItem';
import FormBlock from '@components/FormBlock';

import { searchClientsAdvisor } from '@services/api/common/common';
import { getAdditionalParamsToNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';

import { LABELS_WIDTH, FORM_BLOCK_MARGIN_BOTTOM } from '@pages/NewUserSettings/data';

import { USER_ROLES_FOR_SEARCH } from '@constants/common';

import { ContactVisibilityPermissionsProps } from './types';
import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';

const USER_LIST_STYLE = {
  paddingTop: '15px',
};

export const ContactVisibilityPermissions = ({
  advisorsData = [],
  control,
  errors,
  isBorderBottom = false,
  setAdvisorsData,
  setUsersData,
  usersData = [],
  inputNames,
}: ContactVisibilityPermissionsProps) => {

  return (
    <FormBlock isBorderBottom={ isBorderBottom }  marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>
      <FormBlock.Header title={ 'Contact Visibility Permissions' } />
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <ContactVisibilityItem<UserProfileType['advisors'], []> 
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            FetchProps={ {
              getParams: getAdditionalParamsToNameParam({
                userRole: USER_ROLES_FOR_SEARCH.advisors,
                prevSelectedId: ''
              }),
              requestSender: searchClientsAdvisor,
              responseConverter: usersResponseConverter,
            } }
            control={ control }
            data={ advisorsData }
            errorMessage={ errors['advisors']?.message || null }
            label={ 'Advisors' }
            name={ inputNames.advisors }
            placeholder={ 'Start typing to search advisors' }
            setData={ setAdvisorsData }
            showError={ true }
            userItemLabelWidth={ 50 }
          />
        </LeftColumnWrapper>
        
        <RightColumnWrapper>
          <ContactVisibilityItem<UserProfileType['clients'], []> 
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            FetchProps={ {
              getParams: getAdditionalParamsToNameParam({
                userRole: USER_ROLES_FOR_SEARCH.clients,
                prevSelectedId: ''
              }),
              requestSender: searchClientsAdvisor,
              responseConverter: usersResponseConverter,
            } }
            control={ control }
            data={ usersData }
            label={ 'Users' }
            name={ inputNames.clients }
            placeholder={ 'Start typing to search users' }
            setData={ setUsersData }
            showError={ false }
            userListStyle={ USER_LIST_STYLE }
            userItemLabelWidth={ 160 }
          />
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};
