import { format } from 'date-fns';

import { COMMON_UNIT_TIME_OPTIONS } from '@pages/NewUserSettings/data';
import {
  AccountSettingsType,
  ConfigData,
  ConfigDataResponse,
  Organization,
  OrganizationOptions,
  TimeInSeconds,
  TimeOptions,
  UserProfileType,
  UserProfileTypeResponse,
} from '@services/store/userSettingsStore/types';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { convertDateToUTCFormat } from '@/shared/utils/convertStartEndDateToUTCFormat';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';
import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';

export const configDataNormalizer = (configData: ConfigDataResponse): ConfigData => {
  return ({
    activityDefaultTime: getUnitTimeOptions(configData.activityDefaultTime),
    automaticLogout: getUnitTimeOptions(configData.automaticLogout),
    defaultCalendarView: optionsFromValue(configData.defaultCalendarView),
    defaultLoginTab: optionsFromValue(configData.defaultLoginTab),
    fullNameOrder: optionsFromValue(configData.fullNameOrder),
    organizations: getOrganizationIdSelectOptions(configData.organizationId),
    timezone: optionsFromValue(configData.timezone),
  });
};

const getUnitTimeOptions = (data: Array<TimeInSeconds>): TimeOptions => {
  return COMMON_UNIT_TIME_OPTIONS.filter((unitTime) => data.find((item) => item === unitTime.value));
};

const getOrganizationIdSelectOptions = (data: Array<Organization>): OrganizationOptions => {
  return data.map((item) => ({ label: item.name, value: item.id }));
};

export const convertToFormData = (payload: UserProfileType) => {
  const formData = new FormData();

  const { headshot, logo, signature, userRole, advisors, clients, phoneNumber, ...otherData } = payload;

  const photos = [{
    name: 'headshot',
    value: headshot,
  },
  {
    name: 'logo',
    value: logo,
  },
  {
    name: 'signature',
    value: signature,
  }];

  photos.forEach((photo) => {
    formData.append(photo.name, photo.value || '');
  });

  formData.append('request', JSON.stringify({
    ...otherData,
    advisors: advisors?.map(item => item.id),
    clients: clients?.map(item => item.id),
    userRole: [userRole],
    // TODO by default the phone number must be set NULL if only the country code is entered
    phoneNumber: getIsOnlyCountryCode(phoneNumber || '') ? null : phoneNumber
  }));

  return formData;
};

export const profileDataNormalizer = (data: UserProfileTypeResponse): UserProfileType => ({
  ...data,
  userRole: data.userRole[0],
});

export const settingNormalizer = (data: AccountSettingsType) => {
  const formattedToday = format(new Date, YEAR_MONTH_DAY);
  const { time: calendarStartTime } = convertDateToUTCFormat(formattedToday, data.calendarStartTime);
  const { time: calendarEndTime } = convertDateToUTCFormat(formattedToday, data.calendarEndTime);

  return {
    ...data,
    calendarStartTime,
    calendarEndTime
  };
};