import React from 'react';
import { observer } from 'mobx-react';

import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { TasksNormalizerItem } from '@services/store/trashStore/types';
import { TrashCellsProps } from '../types';
import LinkedContactCell from '@/modules/LinkedContactCell';


export const TasksCells = observer(({
  item,
}: TrashCellsProps<TasksNormalizerItem['item']>) => (
  <>
    <Table.Cell>
      <TextWithTooltip text={ item.name || '' } />
    </Table.Cell>

    <Table.Cell>
      <TextWithTooltip text={ item.type || '' } />
    </Table.Cell>

    {/* TODO delete condition after fix backend side */}
    { item.linkedItemData && item.linkedItemData.linkedContactType && item.linkedItemData.id ?
      <LinkedContactCell
        UserItemProps={ {
          isLinkedContactType: item.linkedItemData && Boolean(item.linkedItemData.linkedContactType)
        } }
        contact={ item.linkedItemData }
        $isClickable={ false }
      /> :
      <Table.Cell>-</Table.Cell>
    }
  </>
));
